import React, { createContext, useContext } from 'react';

import PlayerSlice, { initialState } from './playerSlice';
import ContextProvider from '@components/ContextProvider';

import Movement from './CheckMovementOfVisitor';

export const PlayerStateContext = createContext(initialState);
export const PlayerDispatchContext = createContext();

export const usePlayerStateContext = () => useContext(PlayerStateContext);
export const usePlayerDispatchContext = () => useContext(PlayerDispatchContext);

const CheckMovementOfVisitor = () => {
    return (
        <ContextProvider StateContext={PlayerStateContext} DispatchContext={PlayerDispatchContext} slice={PlayerSlice}>
            <Movement />
        </ContextProvider>
    );
};

export default CheckMovementOfVisitor;
