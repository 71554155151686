import React, { useEffect, useRef } from 'react';

import PlayControllerBar from './PlayControllerBar';
import PlayTimeDisplay from './PlayTimeDisplay';
import PlayButtons from './PlayButtons';

import { usePlayerStateContext, usePlayerDispatchContext } from '../../index';
import { setPause, setPlayTime } from '../../playerSlice';

import { ONE_SEC } from '../../utils';

const PlayController = () => {
    const { play, speed, playTime, displayEndTime } = usePlayerStateContext();
    const dispatch = usePlayerDispatchContext();

    const playRef = useRef(null);

    const start = () => {
        playRef.current = setInterval(() => {
            dispatch(setPlayTime(playTime + ONE_SEC));
        }, 1000 / speed);
    };

    const stop = () => {
        if (playRef.current) {
            clearInterval(playRef.current);
            playRef.current = null;
        }
    };

    useEffect(() => {
        stop();
        if (play) {
            start();
        }
        return () => {
            stop();
        };
    }, [play, speed, playTime]);

    useEffect(() => {
        if (playTime >= displayEndTime) {
            dispatch(setPause());
        }
    }, [playTime, displayEndTime]);

    return (
        <div className="d-flex flex-column gap-2 mt-2">
            <PlayControllerBar />
            <PlayTimeDisplay />
            <PlayButtons />
        </div>
    );
};

export default PlayController;
