import React, { createContext, useReducer, Dispatch } from 'react';
import reducer, { initialState } from './filterSearchGroupReducer';
import cx from 'classnames';

import type { ComponentProps, FilterSearchGroupState } from './types';

export const FilterSearchGroupStateContext = createContext<FilterSearchGroupState>(initialState);
export const FilterSearchGroupDispatchContext = createContext<Dispatch<any> | undefined>(undefined);

const FilterSearchGroup = ({ children, className }: ComponentProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <FilterSearchGroupDispatchContext.Provider value={dispatch}>
            <FilterSearchGroupStateContext.Provider value={state}>
                <div className={cx('filter_ver5', className)}>{children}</div>
            </FilterSearchGroupStateContext.Provider>
        </FilterSearchGroupDispatchContext.Provider>
    );
};

export default FilterSearchGroup;
