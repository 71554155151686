import { useCallback, useState } from 'react';

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    const toggle = useCallback(() => {
        setIsOpen(s => !s);
    }, []);

    return [isOpen, open, toggle] as const;
};

export default useModal;
