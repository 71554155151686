import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Marker, Popup } from 'react-leaflet';
import { divIcon } from 'leaflet';
import styled from 'styled-components';
import { useTranslation } from '@util/hooks';
import { Button } from '@components';
import { makeTimeFormat } from '../../../util';
import { decisionStayColor } from '@util/common/util';
import { ColoredDot, NOT_LONG_STAY } from './OccupantMap';

const CustomMarker = ({ targetInfo, zoom = 18, popupContent, isActive = false, pulseClassName = '' }) => {
    const t = useTranslation('Occupant Location');
    const markerRef = useRef(null);
    const popupRef = useRef(null);

    const { location, target, floorInOutState, state } = targetInfo;
    const { battery } = state;
    const { markerColor, targetName, length, stayDuration, internal } = target;

    const { markerConfig, markerConfigValue } = useSelector(state => state.AppConfig);
    const { defaultSize, minSize, maxSize } = markerConfigValue;

    const icon = useMemo(() => {
        let size = defaultSize * Math.pow(1 / 2, 17 - zoom);
        size = !target.length ? Math.min(Math.max(size, minSize), maxSize) : maxSize + 2;
        const markerName = length ? `${targetName} ${t('and')} ${length - 1}${t(' other person')}` : targetName;
        const bgColor = length ? '#D9D9D9' : markerColor;

        const inLineStyle = `style="background-color: ${bgColor}; color: black; border: 2px solid #fff; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"`;
        const imgStr = `<div class="marker-img replace-img circle ${pulseClassName}">
                            <div class="font-weight-bolder font-size-md text-center" ${inLineStyle}>
                            </div>
                        </div>
                        <div class="marker-label text-outline ">${markerName}</div>`;

        return divIcon({
            className: 'category-marker',
            html: `${imgStr}`,
            iconSize: [size, size],
            iconAnchor: [size / 2, size / 4],
        });
    }, [markerConfig, markerConfigValue, pulseClassName, target]);

    useEffect(() => {
        if (!isActive && markerRef.current) {
            markerRef.current.leafletElement.closePopup();
        }
    }, [isActive]);

    const { bgColor: stayDurationBgColor } = decisionStayColor(stayDuration);

    const cpName = internal ? target.properties.identifier : target.properties['cp-name'];

    return (
        <Marker
            position={location.latLng}
            icon={icon}
            ref={r => {
                if (r && isActive) {
                    markerRef.current = r;
                    r.leafletElement.openPopup();
                }
            }}
        >
            <Popup ref={popupRef} maxWidth={300}>
                <div className="py-2 px-3">
                    <Button
                        className="btn-icon-only bg-transparent position-absolute top-0 end-0"
                        iconName="close"
                        onClick={() => {
                            popupRef.current.leafletElement.options.leaflet.map.closePopup();
                        }}
                    />
                    {!popupContent ? (
                        <StyledPopup key={target.targetNum}>
                            <div className="d-flex align-items-center">
                                {`${target.targetName} / ${cpName ?? '-'} / ${makeTimeFormat(floorInOutState.inTime)}`}
                            </div>
                            {battery && <div className="material-icons-round md-16 color-warning">battery_3_bar</div>}
                            {stayDurationBgColor !== NOT_LONG_STAY && (
                                <ColoredDot $color={stayDurationBgColor} $diameter={12} />
                            )}
                        </StyledPopup>
                    ) : (
                        popupContent
                    )}
                </div>
            </Popup>
        </Marker>
    );
};

export const StyledPopup = styled.span`
    display: flex;
    align-items: center;
    gap: 0.25rem;

    color: black !important;
    font-weight: bold;
    font-size: medium;

    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export default CustomMarker;
