import React, { FC } from 'react';

import { DefaultProps } from '../../types';
import styled from 'styled-components';

const Title: FC<DefaultProps> = ({ children }) => {
    return (
        <div className="d-flex align-items-center gap-2">
            <Divider />
            <div className="font-weight-bold font-size-lg">{children}</div>
        </div>
    );
};

const Divider = styled.div`
    width: 0.125rem;
    height: 1rem;
    background-color: #cfcfd7;
    border-radius: 0.125rem;
`;

export default Title;
