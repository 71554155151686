import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import Nav from '../AppNav/VerticalNavWrapper';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { setEnableMobileMenu } from '@reducer/ThemeOptions';

import { layout } from '@components/style';
import BottomMenu from './BottomMenu';
import { getLsUserInfo } from '@util/common/util';
import { createSSOForm } from '../AppNav/CustomLink';
import envKeys from '../../../environment';

const { cryptoIv } = envKeys;

const AppSidebar = () => {
    const scrollRef = useRef();
    const [setting, setSetting] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const { enableSidebarShadow, enableMobileMenu, backgroundColor } = useSelector(state => state.ThemeOptions);

    const currentLocation = location.pathname.split('/')[1];

    useEffect(() => {
        if (currentLocation === 'status') {
            setSetting(true);
        }
    }, [currentLocation]);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    const handleSettingClick = e => {
        e.stopPropagation();
        e.preventDefault();
        const serviceCode = cryptoIv.split('_')[2];
        const userInfo = getLsUserInfo();
        if (userInfo) {
            createSSOForm(serviceCode, userInfo.oAuthInfo);
        }
    };

    return (
        <>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
            <ReactCSSTransitionGroup
                component="div"
                className={cx('app-sidebar', 'pnt-sidebar', 'nav-box__img-bg', backgroundColor, {
                    'sidebar-shadow': enableSidebarShadow,
                })}
                transitionName="SidebarAnimation"
                transitionAppear
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}
            >
                <div className={'h-100'}>
                    <PerfectScrollbar ref={scrollRef}>
                        <div
                            className="app-sidebar__inner"
                            style={{ padding: `1rem ${layout.layoutSpacerX} ${layout.layoutSpacerX}` }}
                        >
                            <Nav
                                setting={setting}
                                onUpdateSize={() => {
                                    if (scrollRef.current) {
                                        scrollRef.current._container.scrollTop = 0;
                                    }
                                }}
                            />
                        </div>
                    </PerfectScrollbar>
                </div>
                <BottomMenu handleSetting={handleSettingClick} />
            </ReactCSSTransitionGroup>
        </>
    );
};

export default AppSidebar;
