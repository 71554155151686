import React, { useContext } from 'react';
import Button from '@components/Button';
import useTranslation from '@util/hooks/useTranslation';
import { setFilterOpen } from '../filterSearchGroupReducer';
import { FilterSearchGroupDispatchContext } from '../index';

import type { ComponentProps } from '../types';

const FilterButtonArea = ({ children }: Partial<ComponentProps>) => {
    const t = useTranslation('Search');
    const dispatch = useContext(FilterSearchGroupDispatchContext)!;
    const handleSearchBoxToggle = () => dispatch(setFilterOpen());
    return (
        <div className="filter-function show__under-mobile">
            <Button className="btn-gray btn-icon" iconName="arrow_drop_down" onClick={handleSearchBoxToggle}>
                {t('More')}
            </Button>
            {children}
        </div>
    );
};

export default FilterButtonArea;
