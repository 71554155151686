import React, { useState, useMemo, useEffect } from 'react';
import useTranslation from '@hooks/useTranslation';
import { useSelector } from 'react-redux';
import TreeSelectWithOption from '../Components/SelectWithOption/TreeSelectWithOption';

const CategorySelect = ({ selected, isEditable, handleChange, disableOption }) => {
    const t = useTranslation('Filter');
    const [selectedOption, setSelectedOption] = useState(selected);

    const { categoryList } = useSelector(state => state.CategoryInfo);
    const treeData = useMemo(() => {
        return categoryList.map(v => ({ ...v, categoryCodes: v.categoryCode }));
    }, [categoryList]);

    const handleSelectedOptionChange = (selected, isEditable) => {
        handleChange(selected, isEditable);
        setSelectedOption(selected);
    };

    useEffect(() => {
        if (categoryList.length && selected && selected.length) {
            setSelectedOption(selected);
        } else {
            setSelectedOption([]);
        }
    }, [selected, categoryList]);

    return (
        <TreeSelectWithOption
            data={treeData}
            title={t('Asset Categorization')}
            valueKey={'categoryCode'}
            parentKey={'parentCode'}
            labelKey={'categoryName'}
            selected={selectedOption}
            onChange={handleSelectedOptionChange}
            isEditable={isEditable}
            disableOption={disableOption}
        />
    );
};

export default CategorySelect;
