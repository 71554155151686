import React from 'react';
import styled, { css } from 'styled-components';
import { Card } from '@components';
import { OccupantWithBeacon } from '../../ListOfOccupant/types';
import { useTranslation } from '@util/hooks';
import { makeTimeFormat } from '../../../util';

interface Props {
    occupantRows: OccupantWithBeacon[];
    onOccupantClick: (targetNum: number) => void;
}

const OccupantsCard = ({ occupantRows, onOccupantClick }: Props) => {
    const t = useTranslation('Occupant Location');

    return (
        <Card className="h-100 overflow-hidden mw-100" bodyClassName="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
                <span className="fw-bold text-secondary">{t('Occupant List')}</span>
            </div>
            {occupantRows.length > 0 ? (
                <>
                    <div style={{ margin: '0 -16px' }} className="overflow-auto">
                        {occupantRows.map(({ category, target, beacon, properties, floor, internal }) => {
                            const cpName = internal ? properties.identifier : properties.cpName;
                            return (
                                <OccupantItem key={target.targetNum}>
                                    <SmallColorBox $color={category.markerColor} />
                                    <OccupantItemInfo>
                                        <OccupantName
                                            onClick={() => onOccupantClick(target.targetNum)}
                                            title={target.targetName}
                                        >
                                            {target.targetName}
                                        </OccupantName>
                                        <CompanyName title={cpName}>{cpName}</CompanyName>
                                        <CompanyName title={makeTimeFormat(floor.floorInDate)}>
                                            {makeTimeFormat(floor.floorInDate)}
                                        </CompanyName>
                                        <div className="d-flex justify-content-center align-items-center">
                                            {!beacon.batteryStatus && (
                                                <div className="material-icons-round md-18 color-warning">
                                                    battery_3_bar
                                                </div>
                                            )}
                                        </div>
                                    </OccupantItemInfo>
                                </OccupantItem>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div className="d-flex justify-content-center mt-5">{t('There are no occupants.')}</div>
            )}
        </Card>
    );
};

const textEllipsis = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const SmallColorBox = styled.div<{ $color: string }>`
    background-color: ${({ $color }) => $color};
    border-radius: 1px;
    width: 0.6rem;
`;

const OccupantName = styled.div`
    width: 5rem;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    ${textEllipsis}
`;

const CompanyName = styled.div`
    width: 4rem;
    font-size: 0.8rem;
    ${textEllipsis}
`;

const OccupantItem = styled.div`
    display: flex;
    border-top: 1px solid #e7e7eb;
    border-bottom: 1px solid #e7e7eb;
    border-right: 1px solid #e7e7eb;
`;

const OccupantItemInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 0.6rem);
    padding-left: 0.5rem;
`;

export default OccupantsCard;
