import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import type { OccupantWithRental } from '../../../RealtimeStatus/ListOfOccupant/types';

interface Props {
    labelColor?: OccupantWithRental['category']['markerColor'];
    passNumber?: OccupantWithRental['rental']['beaconNum'];
    name?: OccupantWithRental['entrant']['entrantName'];
    handleClick?: (entrantNum: OccupantWithRental['entrant']['entrantNum']) => void;
    entrantNum?: OccupantWithRental['entrant']['entrantNum'];
}

const PassCard: FC<Props> = ({ labelColor, name, handleClick, entrantNum, passNumber }) => {
    const isExist = !!entrantNum;

    const handleCardClick = () => {
        if (typeof handleClick === 'function' && entrantNum) {
            handleClick(entrantNum);
        }
    };

    return (
        <PassCardContainer onClick={handleCardClick} $labelColor={labelColor}>
            <PassCardLabel $labelColor={labelColor} />
            <PassCardValue $isExist={isExist}>
                <div className="font-size-xxlg font-weight-bold" title={passNumber?.toString()}>
                    {passNumber}
                </div>
                <div className="font-size-lg font-weight-600 text-ellipsis" title={name}>
                    {name}
                </div>
            </PassCardValue>
        </PassCardContainer>
    );
};

const PassCardContainer = styled.div<{ $labelColor: string | undefined }>`
    flex: 1 1 11%;
    max-width: 11%;
    min-width: 8rem;
    display: flex;
    height: 4.25rem;
    border: 1px solid #dbdbdb;

    ${({ $labelColor }) =>
        $labelColor &&
        css`
            cursor: pointer;
        `}
`;

const PassCardLabel = styled.div<{ $labelColor: string | undefined }>`
    background-color: ${({ $labelColor }) => ($labelColor ? $labelColor : '#e9e9e9')};
    width: 1rem;
`;

const PassCardValue = styled.div<{ $isExist: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${({ $isExist }) => ($isExist ? '#ffffff' : '#f6f6f6')};
    padding: 0 0.5rem;
    width: calc(100% - 1rem);
`;

export default PassCard;
