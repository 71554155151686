import React, { FC, useRef, useState } from 'react';
import moment from 'moment';
import Select, { components, OptionProps, createFilter } from 'react-select';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import { Button } from '@components';
import { useAsync, useModal, useTranslation } from '@hooks';

import Form from './index';

import type { Nullable, PageInfo } from '@util/type/util';
import type { TaskOption } from '../types';

const TASK_COLOR = '#6a55c0';

interface Props {
    title: string;
    selected: Nullable<TaskOption>;
    handleTaskSelect: (selected: Nullable<TaskOption>) => void;
}

const TaskComboBox: FC<Props> = ({ title, selected, handleTaskSelect }) => {
    const t = useTranslation('Visitor');

    const selectRef = useRef<any>(null);

    const initialOption: TaskOption = { label: '', value: 0, title: '' };

    const [options, setOptions] = useState<TaskOption[]>([initialOption]);

    const handleInputChange = (value: string) => {
        setOptions(prevState => {
            const options = cloneDeep(prevState);
            const additional = options.find(({ value }) => !value);

            if (additional) {
                additional.label = value;
            }

            return options;
        });
    };

    const handleDeleteTask = () => {
        handleTaskSelect(null);
        selectRef.current.clearValue();
    };

    const [isModal, isOpenModal, toggleModal] = useModal();
    const handleSelect = (selected: Nullable<TaskOption>) => {
        handleTaskSelect(selected);

        if (selected === null || !!selected.value) return;
        isOpenModal();
    };

    return (
        <>
            <Form.Topic title={title}>
                <StyledSelect
                    ref={selectRef}
                    isDisabled={!!selected}
                    onInputChange={handleInputChange}
                    onChange={handleSelect}
                    components={{ Option }}
                    menuPlacement="top"
                    placeholder={t('Please Enter')}
                    classNamePrefix="task-combo-box"
                    filterOption={createFilter({
                        matchFrom: 'any',
                        stringify: option => `${option.label}`,
                    })}
                    options={options.filter(({ label }) => label)}
                />
            </Form.Topic>
            <Form.Topic title={t('Selected Tasks')}>
                {selected ? (
                    <SelectedTask>
                        <div className="flex-center gap-2">
                            <span className="build material-icons-round">build</span>
                            <span className="task-name" title={selected.label}>
                                {selected.label}
                            </span>
                        </div>
                        <Button className="btn-icon-only btn-trans" iconName="close" onClick={handleDeleteTask} />
                    </SelectedTask>
                ) : (
                    <div className="flex-center h-100 gap-2 bg-lightgray font-size-sm border-radius-125">
                        <span className="material-icons-round md-16">info</span>
                        <span>{t('There Ara No Registered Works.')}</span>
                    </div>
                )}
            </Form.Topic>
        </>
    );
};

const Option: FC<OptionProps<TaskOption>> = props => {
    const t = useTranslation('Search');

    const isAdditionalTask = !props.data.value;

    return (
        <components.Option {...props}>
            <div className="d-flex justify-content-between font-weight-600" title={props.data.title}>
                <div className="text-ellipsis">{props.children}</div>
                <OptionInlineButton>
                    {isAdditionalTask ? (
                        <div className="flex-center">
                            <span className="material-icons-round color-secondary md-18">add</span>
                            <span className="color-secondary">{t('Add')}</span>
                        </div>
                    ) : (
                        t('Select')
                    )}
                </OptionInlineButton>
            </div>
        </components.Option>
    );
};

const OptionInlineButton = styled.div`
    display: flex;
    min-width: 2.5rem;
    font-size: 0.8rem;
    color: gray !important;
    justify-content: center;
`;

const SelectedTask = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eceaf4;
    padding: 0 0.625rem;

    border-radius: 0.125rem;
    border: 1px solid ${TASK_COLOR};
    height: 100%;

    .build {
        color: ${TASK_COLOR} !important;
        font-size: 1rem;
    }

    .task-name {
        font-weight: 600;
        color: ${TASK_COLOR} !important;
        max-width: 10rem;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const StyledSelect = styled(Select)<any>`
    .task-combo-box__value-container {
        width: 5rem;
    }
`;

export default TaskComboBox;
