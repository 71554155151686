import React, { FC } from 'react';
import styled from 'styled-components';

import FloorInfo from './FloorInfo';

import type { DashboardResponse } from '../../type';

interface Props {
    cleanRoom: DashboardResponse['cleanRoom'];
}

const HEADER_COLORS = ['#2D610E', '#00326B', '#6B006B'];

const StatusByFloor: FC<Props> = ({ cleanRoom }) => {
    return (
        <StatusByFloorContainer>
            {cleanRoom.map((cleanRoomInfo, index) => {
                return (
                    <FloorInfo
                        key={cleanRoomInfo.floorId}
                        headerColor={HEADER_COLORS[index % 3]}
                        columnNum={index === 1 ? 2 : 1}
                        info={cleanRoomInfo}
                    />
                );
            })}
        </StatusByFloorContainer>
    );
};

const StatusByFloorContainer = styled.div`
    display: flex;
    gap: 0.25rem;
    flex: 1;
    max-width: calc(100% - 19rem);
    height: 100%;
`;

export default StatusByFloor;
