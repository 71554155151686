import React, { Children, useContext } from 'react';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';
import { Collapse } from 'reactstrap';

import { FilterSearchGroupStateContext } from '../index';

import type { ComponentPropsWithLabel } from '../types';

// 페이지에 넣을때만 이 WIDTH 가 유효함
const RESPONSIVE_FILTER_WIDTH = 515;

const FilterGroup = ({ label, children, className, labelClassName, labelIconName }: ComponentPropsWithLabel) => {
    const state = useContext(FilterSearchGroupStateContext);
    const { filterOpen } = state;

    return (
        <ResizeDetector
            handleWidth
            render={({ width }: { width: number }) => {
                const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                const childArray = Children.toArray(children);
                const lastChild = children && childArray.length > 1 && childArray.pop();
                return (
                    <div className={cx('filter-box', className)}>
                        {label && (
                            <div className={cx('filter-label', labelClassName)}>
                                {labelIconName && <span className="material-icons-round md-18">{labelIconName}</span>}
                                {label}
                            </div>
                        )}
                        {!isSmall ? (
                            childArray
                        ) : (
                            <Collapse className="w-100" isOpen={filterOpen}>
                                {childArray}
                            </Collapse>
                        )}
                        {lastChild}
                    </div>
                );
            }}
        />
    );
};

export default FilterGroup;
