import React from 'react';
import { Card } from '@components';
import { useTranslation } from '@util/hooks';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { CountInfo } from '../index';
import { RADIAN } from '../../../util/wsUtil';

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (value === 0) {
        return null;
    }

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: '12px', fontWeight: 'bold' }}
        >
            {value}
        </text>
    );
};

interface Props {
    countsInfo: CountInfo;
}

const CountsCard = ({ countsInfo }: Props) => {
    const t = useTranslation('Occupant Location');

    const colors = countsInfo.occupantCounts.map(({ color }) => color) || [];
    const isEmptyList = countsInfo.totalOccupant === 0;

    return (
        <Card bodyClassName="d-flex flex-column gap-3">
            <div className="d-flex justify-content-center">
                <div className="w-80 ratio-1x1">
                    <ResponsiveContainer width="100%" aspect={1}>
                        <PieChart>
                            <Pie
                                isAnimationActive={false}
                                data={isEmptyList ? [{ name: 'empty', value: 1 }] : countsInfo.occupantCounts}
                                dataKey="value"
                                outerRadius="90%"
                                innerRadius="60%"
                                startAngle={450}
                                endAngle={90}
                                labelLine={false}
                                label={isEmptyList ? <></> : renderCustomizedLabel}
                            >
                                {isEmptyList ? (
                                    <Cell fill="#F3F6F9" />
                                ) : (
                                    countsInfo.occupantCounts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))
                                )}
                            </Pie>
                            <text x="50%" y="40%" textAnchor="middle" dominantBaseline="central">
                                {t('Occupant Count')}
                            </text>
                            <text
                                className="fw-bold fs-2"
                                x="50%"
                                y="55%"
                                textAnchor="middle"
                                dominantBaseline="central"
                            >
                                {countsInfo.totalOccupant}
                            </text>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="d-flex flex-column gap-2">
                {countsInfo.occupantCounts.map(({ name, value, color }) => (
                    <div key={name} className="d-flex justify-content-between">
                        <div className="d-flex gap-2">
                            <ColorBox $color={color} />
                            <span>{name}</span>
                        </div>
                        <div>
                            <span className="fw-bold fs-6">{value} </span>
                            <span>{t('people')}</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <span className="material-icons-round mdr-1 color-warning">battery_3_bar</span>
                    {t('Low Battery')}
                </div>
                <div>
                    <span className="fw-bold fs-6">{countsInfo.lowBatteryCount} </span>
                    <span>{t('Case')}</span>
                </div>
            </div>
        </Card>
    );
};

const ColorBox = styled.div<{ $color: string }>`
    background-color: ${({ $color }) => $color};
    border-radius: 2px;
    width: 1.275rem;
    height: 1.275rem;
`;

export default CountsCard;
