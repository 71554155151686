import React, { useMemo, useState } from 'react';

import { Button, DatePicker } from '@components';
import { useAsync, useTranslation } from '@hooks';

import FilterSearchGroup from '../../../Components/FilterSearchGroup';
import FilterGroup from '../../../Components/FilterSearchGroup/Components/FilterGroup';
import { FilterList, InputWrap, SearchWrap, SelectGroup } from '../../../Components/FilterSearchGroup/Components/Part';
import SearchGroup from '../../../Components/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '../../../Components/FilterSearchGroup/Components/FilterButtonArea';
import AutoCompleteInput from '../../../RealtimeStatus/OccupantLocation/Components/AutoCompleteInput';

import moment from 'moment';

import { SEARCH_TIME_GAP, SIX_DAYS } from '../utils';

import { usePlayerDispatchContext, usePlayerStateContext } from '../index';
import { setDisplayTimeRange, setDateChange, setSelectedTarget } from '../playerSlice';
import { getTargetLocationLogApi } from '@api/log';

const Search = ({ handleSearch }) => {
    const t = useTranslation('Search');

    const { startTime, endTime, selectedTarget } = usePlayerStateContext();

    const defaultTime = {
        startTime: moment().subtract(SEARCH_TIME_GAP, 'minutes').startOf('minutes').valueOf(),
        endTime: moment().startOf('minutes').valueOf(),
    };

    const dispatch = usePlayerDispatchContext();

    const [locationLog, setLocationLog] = useState([]);
    const [keyword, setKeyword] = useState('');

    const filteredTargetList = useMemo(() => {
        return locationLog.reduce((acc, cur) => {
            const { targetId, targetName, department, cpName, identifier } = cur;
            const existInLog = acc.findIndex(log => log.targetId === targetId);
            if (existInLog === -1) {
                acc.push({
                    targetId,
                    targetName,
                    label: `${targetName} / ${cpName ?? '-'} / ${identifier ?? '-'} / ${department ?? '-'}`,
                });
            }
            return acc;
        }, []);
    }, [locationLog, selectedTarget]);

    useAsync({
        promise: getTargetLocationLogApi,
        fixedParam: {
            isAll: 'Y',
            startDate: moment(startTime || defaultTime.startTime).unix(),
            endDate: moment(endTime || defaultTime.endTime).unix(),
        },
        deps: [startTime, endTime],
        immediate: true,
        resolve: res => {
            setLocationLog(res?.rows || []);
        },
        reject: err => {
            console.error(err);
            setLocationLog([]);
        },
    });

    const handleDateChange = startDate => {
        dispatch(
            setDateChange({
                startTime: startDate,
                endTime: moment(startDate).add(SEARCH_TIME_GAP, 'minutes').valueOf(),
            }),
        );
    };

    const handleRefreshClick = () => {
        handleDateChange(moment().subtract(SEARCH_TIME_GAP, 'minutes').valueOf());
        dispatch(setSelectedTarget(null));
        dispatch(setDisplayTimeRange(null));
        setKeyword('');
    };

    return (
        <FilterSearchGroup>
            <FilterGroup label={t('Search period')} className="gap-2">
                <FilterList className="">
                    <SelectGroup>
                        <DatePicker
                            value={startTime || defaultTime.startTime}
                            handleChange={handleDateChange}
                            minDate={moment().subtract(SIX_DAYS, 'days').startOf('days').valueOf()}
                            maxDate={moment.now()}
                            valueType="ms"
                            leftIcon
                            showTimeInput
                        />
                    </SelectGroup>
                    <span className="pnt-txt txt-bold">
                        {t('Search for {{time}} minutes from', 'Search', { time: SEARCH_TIME_GAP })}
                    </span>
                    <span className="pnt-txt txt-bold color-danger">{`(${t(
                        'Can Only Retrieve Information Within The Last 7 Days',
                        'Search',
                    )})`}</span>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
            <SearchGroup label={t('Search Word')} className="gap-2">
                <SearchWrap>
                    <InputWrap className="d-block">
                        <AutoCompleteInput
                            keyword={keyword}
                            onKeywordChange={keyword => setKeyword(keyword)}
                            options={filteredTargetList}
                            onItemClick={selected => dispatch(setSelectedTarget(selected))}
                            placeholder={t('Search name, company name, identifier, department name')}
                            emptyResult={t('No matches found.', 'Occupant Location')}
                        />
                    </InputWrap>
                </SearchWrap>
                <div className="d-flex gap-2">
                    <Button className="btn-secondary" onClick={handleSearch}>
                        {t('Search')}
                    </Button>
                    <Button className="btn-lightgray btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
                </div>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
