import React from 'react';
import useTranslation from '@hooks/useTranslation';

const BottomMenu = ({ handleSetting }) => {
    const t = useTranslation('Menu');
    return (
        <div className="pnt-sidebar-bottom app-sidebar__inner">
            <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container">
                    <li className="metismenu-item">
                        <a className="metismenu-link" href="#" onClick={handleSetting}>
                            <div className="d-flex align-items-center h-100">
                                <i className="metismenu-icon icon-setting" />
                                <span
                                    style={{
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        display: 'inline-block',
                                        fontWeight: 700,
                                    }}
                                >
                                    {t('Information Management And Settings')}
                                </span>
                                <div className="material-icons-round md-18">keyboard_arrow_right</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default BottomMenu;
