import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import RDatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import '../../../assets/main/datepicker.scss';
import { dateToFormat } from '@util/common/util';
import moment from 'moment';
import TimeInput from './Components/TimeInput';
import { useTranslation, useAppSelector } from '@hooks';
import cx from 'classnames';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { UnionTimestamp, Timestamp, Nullable } from '@util/type/util';

import ko from 'date-fns/locale/ko';
import en from 'date-fns/locale/en-US';

registerLocale('en', en);
registerLocale('ko', ko);

type NullableDate = Nullable<Date>;
export type NullableTimestamp = Nullable<UnionTimestamp>;

interface DatePickerProps<CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>
    extends Omit<
        ReactDatePickerProps<CustomModifierNames, WithRange>,
        'value' | 'selected' | 'shouldCloseOnSelect' | 'minDate' | 'maxDate' | 'minTime' | 'maxTime'
    > {
    value: NullableTimestamp;
    handleChange(
        date: WithRange extends false | undefined ? NullableTimestamp : NullableTimestamp[],
        event?: React.SyntheticEvent<any> | undefined,
    ): void;
    withoutDay?: boolean | undefined;
    withoutTime?: boolean | undefined;
    valueType?: 'ms' | 's' | undefined;
    minDate?: Timestamp;
    maxDate?: Timestamp;
    minTime?: Timestamp;
    maxTime?: Timestamp;
}

type DatePickerPropsWithoutOnChange = Omit<DatePickerProps, 'onChange'>;

const DatePicker = ({
    value,
    handleChange,
    withoutDay,
    withoutTime,
    valueType,
    readOnly,
    disabled = false,
    minDate,
    maxDate,
    minTime,
    maxTime,
    ...restProps
}: DatePickerPropsWithoutOnChange) => {
    const t = useTranslation('DatePicker');
    const { lang } = useAppSelector(state => state.UserInfo);
    const dateFormat = useMemo(() => {
        if (withoutDay) {
            return {
                datepicker: 'yyyy-MM',
                title: 'YYYY.MM.DD',
                placeholderText: '---- - --',
            };
        }
        if (withoutTime) {
            return {
                datepicker: 'yyyy-MM-dd',
                title: 'YYYY.MM.DD',
                placeholderText: '---- - -- - --',
            };
        }

        return {
            datepicker: 'yyyy-MM-dd HH:mm',
            title: 'YYYY.MM.DD (HH:mm)',
            placeholderText: '---- - -- - -- -- : --',
        };
    }, [withoutTime]);
    const handleChangeDate = (selected: Date | null) => {
        let selectedDate: NullableTimestamp;
        if (selected) {
            switch (valueType) {
                case 'ms':
                    selectedDate = moment(selected).valueOf();
                    break;
                case 's':
                default:
                    selectedDate = moment(selected).unix();
                    break;
            }
        } else {
            selectedDate = selected;
        }
        handleChange(selectedDate);
    };
    const selectedValue = useMemo<NullableDate>(() => {
        if (value) {
            let selectedDate = value;
            switch (valueType) {
                case 'ms':
                    break;
                case 's':
                default:
                    selectedDate = value * 1000;
                    break;
            }

            return getTimezoneDateObj(selectedDate);
        }
        return null;
    }, [value, valueType]);

    return (
        <label
            className={cx(
                'pnt-datepicker-container',
                restProps.showTimeInput && 'time-input',
                restProps.showTimeSelect && 'time-select',
                withoutTime && 'time-none',
            )}
        >
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                        <span className="material-icons-round md-18">calendar_month</span>
                    </div>
                </InputGroupAddon>
                <RDatePicker
                    locale={lang}
                    className={cx(
                        'form-control',
                        readOnly && 'cursor-default form-disable',
                        disabled && 'form-disable',
                    )}
                    autoComplete="off"
                    selected={selectedValue}
                    onChange={handleChangeDate}
                    dateFormat={dateFormat.datepicker}
                    // 로컬 타임존과 회사 타임존이 맞지 않는 경우 참고,
                    // 툴팁은 변환한 시간을 다시 moment에 넣어서 포맷 변화를 하면서 시간 차이가 생김.
                    // 타입존 오프셋의 차이를 계산한 timestamp 값을 넘기도록 수정 필요함
                    title={dateToFormat(selectedValue, dateFormat.title)}
                    withPortal={isMobile}
                    placeholderText={dateFormat.placeholderText}
                    popperClassName={'datepicker-popper-display'}
                    shouldCloseOnSelect={!!withoutTime}
                    closeOnScroll={true}
                    timeInputLabel={t('Time')}
                    customTimeInput={
                        <TimeInput
                            date={selectedValue ? selectedValue.valueOf() : selectedValue}
                            minTime={minTime ?? minDate}
                            maxTime={maxTime ?? maxDate}
                        />
                    }
                    timeFormat={'HH:mm'}
                    timeIntervals={5}
                    readOnly={readOnly}
                    disabled={disabled}
                    minDate={getMinMaxDate(minDate)}
                    maxDate={getMinMaxDate(maxDate)}
                    minTime={getMinMaxDate(minTime)}
                    maxTime={getMinMaxDate(maxTime)}
                    {...restProps}
                />
            </InputGroup>
        </label>
    );
};

const getTimezoneDateObj = (timestamp: Timestamp | null): Date => {
    if (timestamp) {
        return new Date(
            new Date(timestamp).toLocaleString('en-US', {
                timeZone: moment().tz(),
            }),
        );
    }
    return new Date();
};

const getMinMaxDate = (timestamp?: Timestamp | null): Date | undefined => {
    if (timestamp) {
        return getTimezoneDateObj(timestamp);
    }
    return undefined;
};

export default DatePicker;
