import React, { useRef } from 'react';
import Nouislider from 'nouislider-react';
import styled from 'styled-components';

import { usePlayerDispatchContext, usePlayerStateContext } from '../../index';

import { setPlayTime } from '../../playerSlice';

const MIN_PERCENT_DECIMAL = 0;
const MAX_PERCENT_DECIMAL = 1;

const PlayControllerBar = () => {
    const { playTime, displayStartTime, displayEndTime } = usePlayerStateContext();
    const dispatch = usePlayerDispatchContext();

    const slideThrottle = useRef(null);

    const isDisplayTime = !!(displayStartTime && displayEndTime);

    return (
        <StyledNouislider
            className="tracing-play-bar"
            animate={false}
            disabled={!isDisplayTime}
            connect={[true, false]}
            step={1}
            start={[isDisplayTime ? playTime : MIN_PERCENT_DECIMAL]}
            range={{ min: displayStartTime || MIN_PERCENT_DECIMAL, max: displayEndTime || MAX_PERCENT_DECIMAL }}
            onSlide={(render, handle, value) => {
                const selectedTime = value[0];

                if (slideThrottle.current) {
                    clearTimeout(slideThrottle.current);
                    slideThrottle.current = null;
                }
                slideThrottle.current = setTimeout(() => {
                    dispatch(setPlayTime(selectedTime));
                    slideThrottle.current = null;
                }, 200);
            }}
        />
    );
};

const StyledNouislider = styled(Nouislider)`
    & .noUi-base {
        background-color: grey;
        border: none;
    }
    & .noUi-handle {
        cursor: pointer;
        background-color: #51de6c;
        border-radius: 50%;
        width: 14px !important;
        height: 14px !important;
        top: -5px !important;
        right: -7px !important;

        ::before,
        ::after {
            display: none;
        }
    }
    &.noUi-target {
        border: none;
        background-color: #dcd9e1;
        height: 4px;
    }
    & .noUi-connect {
        background-color: #51de6c !important;
    }
`;

export default PlayControllerBar;
