import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
    alertList: [],
    activeAlertLength: 0,
    toastConfigValue: {
        useAlert: true,
        limitAlert: 5,
        autoClose: 5000,
    },
    checkedInterfaceCommandType: [],
    selectedAlertInfo: {},
    notification: {
        SOS_ON: {},
        // 움직임 없음 모달 안뜨게 해달라는 요청
        // LEAVEALONE_ON: {},
    },
    sosAlert: {},
};

const DELETE_EVENT_TYPE = {
    SOS_OFF: 'SOS_ON',
};

export const EVENT_TYPES = Object.keys(initialState.notification);

const deleteCheckedNotification = (state, notificationLog) => {
    const { interfaceCommandType, targetNum } = notificationLog;
    const eventName = DELETE_EVENT_TYPE[interfaceCommandType];

    if (state.notification[eventName]?.[targetNum]) {
        delete state.notification[eventName][targetNum];
    }
};

const { actions, reducer } = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setAlert: {
            reducer: (state, action) => {
                if (state.checkedInterfaceCommandType.includes(action.payload.interfaceCommandType)) {
                    state.alertList.push(action.payload);
                }
            },
            prepare: ({ notificationLog: { targetName, interfaceCommandType, regDate, targetNum } }) => {
                const alertDate = moment(regDate * 1000).format('YYYY-MM-DD HH:mm:ss');
                return {
                    payload: {
                        targetName,
                        interfaceCommandType,
                        alertDate,
                        targetNum,
                    },
                };
            },
        },
        setChangeActiveAlertLength: (state, action) => {
            state.activeAlertLength += action.payload;
            if (action.payload === 1) {
                state.alertList.shift();
            }
        },
        setDeleteAlert: (state, action) => {
            state.alertList.shift();
        },
        setCheckedInterfaceCommandType: (state, action) => {
            state.checkedInterfaceCommandType = action.payload.reduce((acc, cur) => {
                if (cur.isChecked === 'Y') {
                    acc.push(cur.interfaceCommandType);
                }
                return acc;
            }, []);
        },
        setSelectedAlertInfo: (state, action) => {
            state.selectedAlertInfo = action.payload;
        },
        setNotification: (state, action) => {
            const notificationInfos = action.payload;
            const { notificationLog } = notificationInfos;
            const eventType = notificationLog.interfaceCommandType.split('_')[1];

            const eventKeys = Object.keys(initialState.notification);
            if (eventType === 'ON' || eventType === 'IN' || eventKeys.includes(notificationLog.interfaceCommandType)) {
                if (
                    !!state.notification[notificationLog.interfaceCommandType] &&
                    !state.notification[notificationLog.interfaceCommandType][notificationLog.logNum]
                ) {
                    state.notification[notificationLog.interfaceCommandType][notificationLog.logNum] = notificationLog;
                }
            } else if (eventType === 'OFF' || eventType === 'OUT') {
                deleteCheckedNotification(state, notificationLog);
            }
        },
        deleteNotification: (state, action) => {
            const { logNum, type } = action.payload;

            delete state.notification?.[type]?.[logNum];
        },
        setSosAlert: (state, action) => {
            state.sosAlert = action.payload;
        },
    },
});

export const {
    setAlert,
    setChangeActiveAlertLength,
    setDeleteAlert,
    setCheckedInterfaceCommandType,
    setSelectedAlertInfo,
    setNotification,
    deleteNotification,
    setSosAlert,
} = actions;
export default reducer;
