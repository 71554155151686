import { reqGet, reqPatch, reqPost } from './index';

//// 대시보드 API
// 대시보드 API
export const getDashboardApi = param => reqGet('/v3/api/ws01/tel/dashboard', param);

//// 재실자 목록 조회 API
// 재실자 목록 조회 API
export const getOccupantsApi = param => reqGet('/v3/api/ws01/tel/occupants', param);

//// 출입 기록 API
// 출입 기록 조회 API
export const getFacilityEnteringRecordsApi = param => reqGet('/v3/api/ws01/tel/facility/entering-records', param);

// 출입 기록 엑셀 API
export const postFacilityEnteringRecordsExportApi = param =>
    reqPost('/v3/api/ws01/tel/facility/entering-records/export', param);

//// 출입자 API
// 출입자 목록 API
export const getEntrantsApi = param => reqGet('/v3/api/ws01/tel/entrants', param);

// 출입자 생성 API
export const postEntrantsApi = param => reqPost('/v3/api/ws01/tel/entrants', param);

// 출입자 단일 조회 API
export const getEntrantApi = param => reqGet(`/v3/api/ws01/tel/entrants/${param.entrantNum}`, param);

// 출입자 수정 API
export const patchEntrantsAPi = param => reqPatch(`/v3/api/ws01/tel/entrants/${param.entrantNum}`, param);

// 카테고리(소속)목록 API
export const getEntrantsDivisionsApi = param => reqGet('/v3/api/ws01/tel/entrants/divisions', param);

// 출입자 목록 엑셀 API
export const postEntrantsExportApi = param => reqPost('/v3/api/ws01/tel/entrants/export', param);

//// 출입증 대여 API
// 출입증 대여 기록 API
export const getRentalApi = param => reqGet('/v3/api/ws01/tel/rental', param);

// 출입증 대여 API
export const postRentalApi = param => reqPost('/v3/api/ws01/tel/rental', param);

// 출입증 반납 API
export const patchRentalApi = param => reqPatch(`/v3/api/ws01/tel/rental/${param.rentalNum}`, param);

// 출입증 불출 이력 엑셀 API
export const postRentalExportApi = param => reqPost('/v3/api/ws01/tel/rental/export', param);

// 출입증 대여 현황 API
export const getRentalPresentApi = param => reqGet('/v3/api/ws01/tel/rental/present', param);
