import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { useTranslation, useAppSelector } from '@hooks';

import PersonalInfo from './PersonalInfo';

import type { CleanRoom } from '../../type';

interface Props {
    headerColor: string;
    columnNum: 1 | 2;
    info: CleanRoom;
}

// 픽셀 단위로 정수형으로만 가능함
const SCROLL_SPEED = 1;

const FloorInfo: FC<Props> = ({ headerColor, columnNum = 1, info }) => {
    const t = useTranslation('Dashboard');
    const history = useHistory();

    const { fullScreen } = useAppSelector(state => state.DashboardFrame);
    const containerRef = useRef<HTMLDivElement>(null);

    const countList = info.occupants.occupantsCount.map(({ categoryCode, count, markerColor }) => ({
        key: categoryCode,
        color: markerColor,
        count,
    }));

    const onHeaderClick = () => {
        history.push({
            pathname: '/realtime-status/occupant-location',
            state: { floorId: info.floorId },
        });
    };

    const onCountInfoClick = () => {
        history.push('/realtime-status/occupant-list');
    };

    useEffect(() => {
        const container = containerRef.current;
        let scrollAmount = 0;
        // 1은 down -1은 up
        let direction = 1;

        const scroll = () => {
            if (!container) return;

            container.scrollTop += direction * SCROLL_SPEED;

            if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                direction = -1;
            }

            if (container.scrollTop <= 0) {
                direction = 1;
            }

            scrollAmount = requestAnimationFrame(scroll);
        };

        if (!fullScreen) return;
        scrollAmount = requestAnimationFrame(scroll);

        return () => cancelAnimationFrame(scrollAmount);
    }, [fullScreen]);

    return (
        <div className={cx('d-flex flex-column overflow-hidden', `flx-${columnNum}`)}>
            <Header $bgColor={headerColor} onClick={onHeaderClick}>
                <div className="title">{info.floorName}</div>
                <div className="d-flex">
                    <div className="count">{info.occupants.occupants.length}</div>
                    <div className="people">{t('People')}</div>
                </div>
            </Header>
            <div className="d-flex">
                {countList.map(countInfo => {
                    return (
                        <CountInfo key={countInfo.key} $color={countInfo.color} onClick={onCountInfoClick}>
                            <div className="label" />
                            <div className="count">{countInfo.count}</div>
                        </CountInfo>
                    );
                })}
            </div>
            <PersonalInfoContainer $columnNum={columnNum} ref={containerRef}>
                {info.occupants.occupants.map(occupant => {
                    return <PersonalInfo key={occupant.target.targetNum} occupant={occupant} />;
                })}
            </PersonalInfoContainer>
        </div>
    );
};

const Header = styled.div<{ $bgColor: string }>`
    display: flex;
    align-items: center;
    cursor: pointer;

    background-color: ${({ $bgColor }) => $bgColor};
    padding: 0.5rem 0.75rem;
    justify-content: space-between;

    div {
        font-size: 2rem;
    }
    .title {
        color: #fff !important;
        font-weight: 700;
    }
    .count {
        color: #fff !important;
        font-weight: 700;
    }
    .people {
        color: #ffffffcc !important;
    }
`;

const CountInfo = styled.div<{ $color: string }>`
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    border: 0.5px solid #00326b;
    cursor: pointer;
    background-color: ${({ $color }) => `${$color}80`};

    .label {
        background-color: ${({ $color }) => $color};
        width: 1rem;
        height: 1rem;
        border-radius: 0.25rem;
    }
    .count {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: normal;
    }
`;

const PersonalInfoContainer = styled.div<{ $columnNum: 1 | 2 }>`
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    width: 100%;

    > div {
        flex: ${({ $columnNum }) => ($columnNum === 2 ? '0 1 50%' : '0 1 100%')};
        min-width: 0;
    }
`;

export default FloorInfo;
