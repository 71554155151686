import React, { useReducer } from 'react';

const ContextProvider = ({ StateContext, DispatchContext, slice, children, initializer }) => {
    const [state, dispatch] = useReducer(slice.reducer, slice.getInitialState(), initializer);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export default ContextProvider;
