import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    title: string;
    children: ReactNode;
    className?: string;
}

const BORDER_STYLE = '1px solid #0000000d';

const TableLabel: FC<Props> = ({ className, title, children }) => {
    return (
        <LabelContainer className={className}>
            <div className="label-title" title={title}>
                {title}
            </div>
            <div className="label-value">{children}</div>
        </LabelContainer>
    );
};

const LabelContainer = styled.div`
    display: grid;
    min-height: 1.875rem;
    height: 100%;
    gap: 0.5rem;
    border-bottom: ${BORDER_STYLE};
    grid-template-columns: 3fr 7fr;

    .label-title {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        background-color: #f6f5f8;
        font-weight: bold;
        color: #3e3d5d !important;
        border-radius: 0;

        border-right: ${BORDER_STYLE};
        border-left: ${BORDER_STYLE};

        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .label-value {
        display: flex;
        align-items: center;

        word-break: break-all;

        height: 100%;
        font-weight: 600;
        color: #565672 !important;

        border-right: ${BORDER_STYLE};
    }
`;

export default TableLabel;
