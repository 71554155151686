import React, { FC } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { SIX_HOURS, THREE_HOURS } from '../../../../RealtimeStatus/ListOfOccupant/util';

import type { Occupant } from '../../type';

interface Props {
    occupant: Occupant;
}

const makeStayColor = (time: number) => {
    if (time >= SIX_HOURS) return '#e00038';
    if (time >= THREE_HOURS) return '#ffcc00';
    return '#00000073';
};

const PersonalInfo: FC<Props> = ({ occupant }) => {
    const history = useHistory();
    const stayColor = makeStayColor(occupant.floor.stayDuration);

    const onOccupantClick = () => {
        history.push({
            pathname: '/realtime-status/occupant-location',
            state: { targetNum: occupant.target.targetNum },
        });
    };

    const stayDuration = moment.utc(occupant.floor.stayDuration * 1000).format('HH:mm');
    const { targetName } = occupant.target;
    const companyInfo = occupant.internal ? occupant.properties.identifier : occupant.properties.cpName;

    return (
        <div
            className="d-flex align-items-center bg-white position-relative w-100 cursor-pointer"
            onClick={onOccupantClick}
        >
            <ColorLabel $color={occupant.category.markerColor} />
            <ValueContainer $stayColor={stayColor}>
                <div className="personal-container">
                    <div className="stay-duration" title={stayDuration}>
                        {stayDuration}
                    </div>
                    <div className="name" title={targetName}>
                        {targetName}
                    </div>
                </div>
                <div className="company" title={companyInfo?.toString()}>
                    {companyInfo}
                </div>
            </ValueContainer>
            {!occupant.beacon.batteryStatus && (
                <BatteryWarning className="material-icons-round md-14 color-white">battery_3_bar</BatteryWarning>
            )}
        </div>
    );
};

const TextEllipsis = css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ColorLabel = styled.div<{ $color: string }>`
    width: 0.5rem;
    height: 100%;
    background-color: ${({ $color }) => $color};
    margin: 1px 0;
    border-right: 1px solid #e7e7eb;
    border-bottom: 1px solid #e7e7eb;
`;

const ValueContainer = styled.div<{ $stayColor: string }>`
    width: calc(100% - 0.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.475rem;
    overflow: hidden;

    .personal-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 70%;

        .stay-duration {
            background-color: ${({ $stayColor }) => $stayColor};
            color: #fff !important;
            font-size: 0.875rem;
            font-weight: 500;
            font-variant: tabular-nums;
            border-radius: 0.3125rem;
            line-height: normal;
            padding: 0.1rem 0.2rem;
        }
        .name {
            font-size: 1.125rem;
            font-weight: 700;
            line-height: normal;
            color: #262549 !important;
            ${TextEllipsis};
        }
    }
    .company {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.03125rem;
        color: #262549 !important;
        width: 30%;
        text-align: end;
        ${TextEllipsis};
    }
`;

const BatteryWarning = styled.div`
    text-align: center;

    position: fixed;
    width: 1rem;
    height: 1rem;
    border-radius: 6.25rem;
    background: #e00038;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    font-weight: 200;
`;

export default PersonalInfo;
