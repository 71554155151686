import React from 'react';

import { useAppSelector, useTranslation } from '@util/hooks';
import Filter, {
    useFilterDatePickerGroupWithPeriod,
    useFilterSearchWithType,
    useFilterSelect,
} from '../../../Components/Filter';

import { KEYWORD_OPTIONS } from '../../../util/wsUtil';

interface Props {
    periodProps: ReturnType<typeof useFilterDatePickerGroupWithPeriod>;
    categoryProps: ReturnType<typeof useFilterSelect>;
    keywordProps: ReturnType<typeof useFilterSearchWithType>;
    onSearchClick: () => void;
    onResetClick: () => void;
}

const Search = ({ periodProps, categoryProps, keywordProps, onSearchClick, onResetClick }: Props) => {
    const t = useTranslation('Filter');
    const { createdCategoryList } = useAppSelector(state => state.WorkerSafety);

    const categoryOptions = [
        { label: t('All categories'), value: null },
        ...createdCategoryList.map(({ categoryName, categoryCode }) => ({ label: categoryName, value: categoryCode })),
    ];

    const periodOptions = [
        { value: 'select' as const, label: t('Select period') },
        { value: 'last-7' as const, label: t('Last 7 days') },
        { value: 'last-30' as const, label: t('Last 30 days') },
    ];

    const keywordOptions = KEYWORD_OPTIONS.map(({ label, value }) => ({ label: t(label), value }));

    const { date, handleStartDateChange, handleEndDateChange, handlePeriodChange } = periodProps;
    const { value: categoryValue, handleChange: handleCategoryChange } = categoryProps;
    const { keyword, handleTypeChange, handleTextChange } = keywordProps;

    return (
        <div>
            <Filter>
                <div className="d-flex align-items-center">
                    <Filter.Label text={t('Entry Period')} />
                    <Filter.DatePickerGroupWithPeriod
                        startDate={date.start}
                        onStartDateChange={handleStartDateChange}
                        endDate={date.end}
                        onEndDateChange={handleEndDateChange}
                        periodList={periodOptions}
                        periodValue={date.period}
                        onPeriodChange={handlePeriodChange}
                    />
                </div>
                <div className="d-flex gap-3 mt-2">
                    <div className="d-flex align-items-center">
                        <Filter.Label text={t('Belong to')} />
                        <Filter.Select
                            value={categoryValue ?? categoryOptions[0]}
                            options={categoryOptions}
                            onChange={handleCategoryChange}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <Filter.Label text={t('Keyword')} />
                        <Filter.SearchWithType
                            selectValue={keyword.type?.value || undefined}
                            selectOptions={keywordOptions}
                            onSelectChange={handleTypeChange}
                            inputValue={keyword.text}
                            onInputChange={handleTextChange}
                            onSearchClick={onSearchClick}
                        />
                        <Filter.Refresh onClick={onResetClick} className="ml-1" />
                    </div>
                </div>
            </Filter>
        </div>
    );
};

export default Search;
