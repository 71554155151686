import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from '@hooks';
import { Button } from '@components';

interface Props {
    totalCount: number;
    handleOpenPersonalPass: () => void;
}

const Banner: FC<Props> = ({ totalCount, handleOpenPersonalPass }) => {
    const t = useTranslation('Visitor');

    return (
        <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex flex-column gap-1 font-size-lg">
                <div className="d-flex gap-1">
                    <span className="font-weight-bold">{t('Partner (including same-day visit)')}</span>
                    <span className="font-weight-500">{t('Of')}</span>
                </div>
                <div className="d-flex align-items-center gap-1 font-weight-500">
                    <Trans
                        t={t}
                        values={{ count: totalCount }}
                        components={[<div className="bg-black color-white px-2" />]}
                    >
                        {'The Distribution Status Of The Pass Is <0>{{count}} People</0>.'}
                    </Trans>
                </div>
            </div>
            <div className="d-flex align-items-center gap-2 fw-bold">
                <span>{t('Is This Your First Time Visiting?')}</span>
                <Button
                    iconName="add"
                    iconClassName="material-icons-round md-18"
                    className="btn-secondary"
                    onClick={handleOpenPersonalPass}
                >
                    {t('Entrance Registration')}
                </Button>
            </div>
        </div>
    );
};

export default Banner;
