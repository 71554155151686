export const DAY = 'day';

/////////////
/* 비콘상태 */
////////////
export const LOW_BATTERY = 'LB';
export const LOST_SIGNAL = 'LS';

//// //////////
/* 나노 기술원 */
///////////////

export const YES = 'Y';

export const UNSELECTED = { label: 'All', value: null };

export const CHOOSE = { label: 'Choose', value: null };

export const DAYS = 'days';

// 도쿄 일렉트론코리아
export const KEYWORD_OPTIONS = [
    {
        label: 'All',
        value: null,
    },
    {
        label: 'Name',
        value: 'NAME',
    },
    {
        label: 'Company Name',
        value: 'CP_NAME',
    },
    {
        label: 'Identifier',
        value: 'IDENTIFIER',
    },
    {
        label: 'Department',
        value: 'DEPARTMENT',
    },
    {
        label: 'Beacon Number',
        value: 'BEACON',
    },
];

export const RADIAN = Math.PI / 180;
