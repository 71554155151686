import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Card, Table } from '@components';
import { useAsync, useInterval, useTranslation } from '@hooks';
import { getOccupantsApi } from '@api/tel';
import { makeDateWithBracket, makeTimeFromSeconds, nullishToDash } from '../../util';
import { decisionStayColor } from '@util/common/util';

import Search from './Components/Search';

import { INITIAL_SEARCH_PARAMS, SEARCH_PARAMS } from './util';

import type { Occupant, SearchParams } from './types';
import type { CellValue, RealtimeTable } from '@util/type/util';
import type { UnixTimestamp } from '@util/type/util';

const INITIAL_TABLE_DATA: RealtimeTable<Occupant> = {
    code: '0',
    count: 0,
    result: 'success',
    rows: [],
};

const INTERVAL_TIME = 3 * 1000;

const ListOfOccupant = () => {
    const t = useTranslation('Entry Exit Record Status');

    const [tableData, setTableData] = useState<RealtimeTable<Occupant>>(INITIAL_TABLE_DATA);
    const [searchParams, setSearchParams] = useState<SearchParams>(INITIAL_SEARCH_PARAMS);
    const handleSearchParams = (params: SearchParams) => {
        if (!!params.opt.value && !params.keyword.length) {
            alert(t('Enter a keyword'));
            return;
        }
        setSearchParams(params);
    };

    const { promise: getOccupants } = useAsync({
        promise: getOccupantsApi,
        immediate: true,
        fixedParam: {
            [SEARCH_PARAMS.SEARCH_LOCATION]: searchParams[SEARCH_PARAMS.SEARCH_LOCATION].value,
            [SEARCH_PARAMS.COMPANY_CLASSIFICATION]: searchParams[SEARCH_PARAMS.COMPANY_CLASSIFICATION].value,
            [SEARCH_PARAMS.SEARCH_OPTION]: searchParams[SEARCH_PARAMS.SEARCH_OPTION].value,
            [SEARCH_PARAMS.SEARCH_WORD]: searchParams[SEARCH_PARAMS.SEARCH_WORD] || null,
            [SEARCH_PARAMS.DURATION]: searchParams[SEARCH_PARAMS.DURATION].value,
        },
        deps: [searchParams],
        resolve: (res: RealtimeTable<Occupant>) => {
            setTableData(res);
        },
        reject: (err: Error) => {
            setTableData(INITIAL_TABLE_DATA);
            console.error(err);
        },
    });

    useInterval({
        callback: getOccupants,
        delay: INTERVAL_TIME,
    });

    const columns = useMemo(
        () => [
            {
                Header: t('Personal information'),
                columns: [
                    {
                        Header: t('Name'),
                        accessor: 'entrant.entrantName',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
            {
                Header: t('Affiliation information'),
                columns: [
                    {
                        Header: t('Belong to'),
                        accessor: 'category.categoryName',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Company Name'),
                        accessor: 'properties.cpName',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Identifier'),
                        accessor: 'properties.identifier',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Department Name'),
                        accessor: 'properties.department',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Position'),
                        accessor: 'properties.designation',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
            {
                Header: t('Stay Information'),
                columns: [
                    {
                        Header: t('Current Location'),
                        accessor: 'floor.floorName',
                        width: 140,
                        className: 'text-ellipsis',
                    },
                    {
                        Header: t('Entry Date'),
                        accessor: 'floor.floorInDate',
                        Cell: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeDateWithBracket(value);
                        },
                        formatTitle: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeDateWithBracket(value);
                        },
                        width: 140,
                    },
                    {
                        Header: t('Stay Duration'),
                        accessor: 'floor.stayDuration',
                        className: 'p-0',
                        Cell: ({ value }: CellValue<number>) => {
                            const { bgColor, color } = decisionStayColor(value);
                            return (
                                <TimeOfStay $bgColor={bgColor} $color={color}>
                                    {makeTimeFromSeconds(value)}
                                </TimeOfStay>
                            );
                        },
                        formatTitle: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeTimeFromSeconds(value);
                        },
                        width: 140,
                    },
                ],
            },
            {
                Header: t('Pass Information'),
                columns: [
                    {
                        Header: t('Beacon Number'),
                        accessor: 'beacon.beaconNum',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<number>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<number>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Beacon Battery Capacity'),
                        accessor: 'beacon.battery',
                        width: 140,
                        className: 'p-0',
                        Cell: ({ value, row: { original } }: any) => {
                            const { beacon } = original;
                            const batteryStatus = beacon.batteryStatus as boolean;
                            return (
                                <BeaconBatteryCapacity $isNormal={batteryStatus}>
                                    {nullishToDash(value) + '%'}
                                </BeaconBatteryCapacity>
                            );
                        },
                        formatTitle: ({ value }: CellValue<number>) => {
                            return nullishToDash(value) + '%';
                        },
                    },
                ],
            },
        ],
        [t],
    ) as any;

    return (
        <Card className="main-card-height">
            <Search handleSearchParams={handleSearchParams} />
            <TableWrapper className="filter-search-table">
                <Table paging={false} columns={columns} data={tableData} />
            </TableWrapper>
        </Card>
    );
};

const TableWrapper = styled.div`
    margin-top: 20px;

    .th {
        text-align: center !important;
        justify-content: center;
    }

    & .td {
        display: flex !important;
        justify-content: center !important;
    }
`;

const cellStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const TimeOfStay = styled.div<{ $bgColor: string; $color: string }>`
    ${cellStyle};
    color: ${({ $color }) => `${$color} !important`};
    background-color: ${({ $bgColor }) => $bgColor};
`;

const BeaconBatteryCapacity = styled.div<{ $isNormal: boolean }>`
    ${cellStyle};
    background-color: ${({ $isNormal }) => !$isNormal && 'yellow'};
`;

export default ListOfOccupant;
