import React, { useMemo, useState } from 'react';
import moment from 'moment';

import { Card } from '@components';

import { useAsync } from '@hooks';
import { getTargetLocationLogApi } from '@api/log';
import { fetchAlertInfo } from '@api/alert';

import Search from './Components/Search';
import PlayerStatus from './Components/Player';

import { AlertInfo, TargetInfo } from '../types';
import { PageInfo } from '@util/type/util';
import { usePlayerDispatchContext, usePlayerStateContext } from './index';
import { setDisplayTimeRange, setPlayTime } from './playerSlice';
import { SEARCH_TIME_GAP } from './utils';

const LOST_SIGNAL = 'LOST_SIGNAL';

interface TimeMarker {
    // lat
    0: number;
    // lng
    1: number;
    // regDate
    2: number;
    // floorId
    3: string;
    //targetNum
    4: number;
    //targetName
    5: string;
    length: 6;
}

type PlayTimeMarker = TimeMarker[];

const Movement = () => {
    const dispatch = usePlayerDispatchContext();
    const { playTime, startTime, endTime, selectedTarget } = usePlayerStateContext();

    const defaultTime = {
        startTime: moment().subtract(SEARCH_TIME_GAP, 'minutes').startOf('minutes').valueOf(),
        endTime: moment().startOf('minutes').valueOf(),
    };

    const [diagramLocationLog, setDiagramLocationLog] = useState<TargetInfo[]>([]);

    const [lostSignalLog, setLostSignalLog] = useState<AlertInfo[]>([]);

    const targetTimeMarkerLog = useMemo(() => {
        if (!diagramLocationLog.length) return [];

        let targetPlayerTimeMaker: PlayTimeMarker = [];

        diagramLocationLog.forEach(log => {
            const { targetId, lat, lng, regDate, floorId, targetNum, targetName } = log;

            if (!targetPlayerTimeMaker.length) {
                const lostSignal = lostSignalLog
                    .filter(lost => lost.targetId === targetId && regDate < lost.regDate)
                    .sort(({ regDate: a_date }, { regDate: b_date }) => a_date - b_date);

                if (!!lostSignal.length) {
                    const playTimeMarker: PlayTimeMarker = [];

                    for (let time = lostSignal[0].regDate; time >= regDate; time--) {
                        playTimeMarker.push([lat, lng, time, floorId, targetNum, targetName]);
                    }

                    targetPlayerTimeMaker = playTimeMarker;
                } else {
                    targetPlayerTimeMaker = [[lat, lng, regDate, floorId, targetNum, targetName]];
                }
            }

            if (!!targetPlayerTimeMaker.length) {
                const nextDate = targetPlayerTimeMaker.at(-1)![2];

                //마커 깜빡거림 보정
                const lostSignal = lostSignalLog
                    .filter(
                        ({ targetId, regDate }) =>
                            targetId === log.targetId && log.regDate < regDate && regDate < nextDate,
                    )
                    .sort(({ regDate: a_date }, { regDate: b_date }) => a_date - b_date);

                let time = !!lostSignal.length ? lostSignal[0].regDate : nextDate - 1;
                for (; time >= regDate; time--) {
                    targetPlayerTimeMaker.push([lat, lng, time, floorId, targetNum, targetName]);
                }
            }
        });

        return targetPlayerTimeMaker;
    }, [diagramLocationLog, lostSignalLog]);

    const { promise: getDiagramLocationLog } = useAsync({
        promise: getTargetLocationLogApi,
        fixedParam: {
            isAll: 'Y',
            startDate: moment(startTime || defaultTime.startTime).unix(),
            endDate: moment(endTime || defaultTime.endTime).unix(),
            targetId: selectedTarget?.targetId,
        },
        resolve: (res: PageInfo<TargetInfo>) => {
            setDiagramLocationLog(res?.rows || []);
        },
        reject: (err: Error) => {
            console.error(err);
            setDiagramLocationLog([]);
        },
    });

    const { promise: getAlertInfo } = useAsync({
        promise: fetchAlertInfo,
        fixedParam: {
            isAll: 'Y',
            startDate: moment(startTime || defaultTime.startTime).unix(),
            endDate: moment(endTime || defaultTime.endTime).unix(),
        },
        resolve: (res: PageInfo<AlertInfo>) => {
            setLostSignalLog(res.rows.filter(({ interfaceCommandType }) => interfaceCommandType === LOST_SIGNAL));
        },
        reject: (err: Error) => {
            console.error(err);
            setLostSignalLog([]);
        },
    });

    const handleSearch = () => {
        if (!selectedTarget) return;
        if (!playTime) {
            dispatch(setPlayTime(defaultTime.startTime));
        }
        dispatch(
            setDisplayTimeRange({
                startTime: startTime || defaultTime.startTime,
                endTime: endTime || defaultTime.endTime,
            }),
        );
        getDiagramLocationLog();
        getAlertInfo();
    };

    return (
        <Card className="main-card-height" bodyClassName="p-0">
            <Search handleSearch={handleSearch} />
            <div className="filter-search-table">
                <PlayerStatus targetTimeMarkerLog={targetTimeMarkerLog} />
            </div>
        </Card>
    );
};

export default Movement;
