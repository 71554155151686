import React, { useState } from 'react';

import { Col } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { setColorScheme } from '@reducer/ThemeOptions';
import { setLanguage } from '@reducer/UserInfo';

import { useTranslation } from '@hooks';
import { Modal } from '@components';

import LanguageSelect from './Components/LanguageSelect';
import ThemeSwatch from './Components/ThemeSwatch';

const THEME_COLOR_LIST = ['pnt-navy'];

const PersonalizationModal = ({ initModal, toggleModal }) => {
    const t = useTranslation('UserBox');
    const dispatch = useDispatch();

    const { lang } = useSelector(state => state.UserInfo);
    const { colorScheme } = useSelector(state => state.ThemeOptions);

    const [selectedLanguage, setSelectedLanguage] = useState(lang);
    const [selectedColor, setSelectedColor] = useState(colorScheme);
    const handleSelectedLanguage = selected => {
        const { value } = selected;
        setSelectedLanguage(value);
    };

    const handleSelectedColor = selected => {
        setSelectedColor(selected);
    };

    const handleSubmit = () => {
        dispatch(setLanguage(selectedLanguage));
        dispatch(setColorScheme(selectedColor));
    };

    return (
        <Modal
            initModal={initModal}
            toggleModal={toggleModal}
            headerTitle={t('Personalization')}
            headerClassName="bg-depth-4"
            okCallback={handleSubmit}
            removeCancel
        >
            <div className="modal-body py-4">
                <Col className="mb-3">
                    <LanguageSelect value={selectedLanguage} onChange={handleSelectedLanguage} />
                </Col>
                <Col>
                    <ThemeSwatch
                        option={THEME_COLOR_LIST}
                        onClick={handleSelectedColor}
                        selectedColorScheme={selectedColor}
                    />
                </Col>
            </div>
            <div id="modal-select-container" />
        </Modal>
    );
};

export default PersonalizationModal;
