import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@hooks';

interface Props {
    color: string;
    name: string;
    count: number;
}

const CountLabel: FC<Props> = ({ color, name, count }) => {
    const t = useTranslation('Dashboard');
    return (
        <CountLabelContainer $bgColor={color}>
            <div className="label">
                <div className="colored" />
                <div className="name">{name}</div>
            </div>
            <div className="value">
                <div className="count">{count}</div>
                <div className="people">{t('People')}</div>
            </div>
        </CountLabelContainer>
    );
};

const CountLabelContainer = styled.div<{ $bgColor: string }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: normal;

    .label,
    .value {
        display: flex;
        align-items: center;
    }

    .label {
        gap: 0.62rem;

        .colored {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.125rem;
            background-color: ${({ $bgColor }) => $bgColor};
        }

        .name {
            color: #ffffffd9 !important;
            font-size: 1.25rem;
            font-weight: 500;
            letter-spacing: -0.03125rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .value {
        gap: 0.31rem;

        .count {
            color: white !important;
            font-size: 1.75rem;
            font-weight: 700;
        }

        .people {
            color: #ffffffbf !important;
            font-size: 1.25rem;
            font-weight: 500;
            letter-spacing: -0.03125rem;
        }
    }
`;

export default CountLabel;
