import React from 'react';
import cx from 'classnames';

import { LabelProps } from '../type';

const Label = ({
    name,
    value,
    labelIcon,
    labelGroupClassName,
    labelValueClassName,
    labelOptionsClassName,
    infoBoxValue,
    disableEllipsis,
}: LabelProps): JSX.Element => {
    return (
        <div className={cx('pnt-label--group', labelGroupClassName)}>
            {!!labelValueClassName && labelValueClassName.includes('label-icon') && <span className={labelIcon} />}
            <div className={cx('label-main', labelValueClassName)}>
                {name}
                {!!labelValueClassName && labelValueClassName.includes('label-info') && (
                    <>
                        <span className="icon-info-fill" />
                        <div className="info-box">{infoBoxValue}</div>
                    </>
                )}
            </div>
            <LabelOptions
                value={value}
                labelOptionsClassName={labelOptionsClassName}
                disableEllipsis={disableEllipsis}
            />
        </div>
    );
};

const LabelOptions = ({ value, disableEllipsis, labelOptionsClassName }: Partial<LabelProps>): JSX.Element | null => {
    const ellipsis = isEllipsis({ value, disableEllipsis });
    return !!value ? (
        <div className={cx('label-options', labelOptionsClassName, ellipsis && 'overflow-hidden')}>
            {ellipsis ? (
                <span className={'text-ellipsis'} title={String(value)}>
                    {value}
                </span>
            ) : (
                value
            )}
        </div>
    ) : null;
};

// disableEllipsis 조건 설정 없을 경우(기본 동작), value가 문자열이면 기본적으로 말줄임 적용.
// 말줄임 적용하고 싶지 않은 경우, disableEllipsis = true로 사용
const isEllipsis = ({ value, disableEllipsis }: Partial<LabelProps>): boolean => {
    if (disableEllipsis) {
        return false;
    }
    return typeof value === 'string';
};

export default Label;
