import React from 'react';

import moment from 'moment';

import { usePlayerStateContext } from '../../index';

import styled from 'styled-components';

const DEFAULT_DISPLAY = '--:--:--';

const displayTime = unixTimestamp => moment(unixTimestamp * 1000).format('YYYY-MM-DD / HH:mm:ss');

const PlayTimeDisplay = () => {
    const { playTime, displayStartTime, displayEndTime } = usePlayerStateContext();
    return (
        <TimeLabelWrapper>
            {displayStartTime !== displayEndTime ? (
                <>
                    <span>{displayTime(moment(displayStartTime).unix())}</span>
                    <span className="font-size-inter">{displayTime(moment(playTime).unix())}</span>
                    <span>{displayTime(moment(displayEndTime).unix())}</span>
                </>
            ) : (
                <>
                    <span style={{ marginLeft: '0.5rem' }}>{DEFAULT_DISPLAY}</span>
                    <span>{DEFAULT_DISPLAY}</span>
                    <span style={{ marginRight: '0.5rem' }}>{DEFAULT_DISPLAY}</span>
                </>
            )}
        </TimeLabelWrapper>
    );
};

const TimeLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    color: #555;
`;

export default PlayTimeDisplay;
