import React from 'react';
import styled from 'styled-components';

import { TextInput, Card } from '@components';

const COLOR_PASS = '#9747ff';

export const ViewPassCard = styled(Card)`
    min-width: 25rem;
`;

export const PassContainer = styled.div`
    border-top: solid 1px ${COLOR_PASS};
    border-bottom: solid 1px ${COLOR_PASS};
    margin-bottom: 1rem;
`;

export const PassInfo = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0;

    :not(:last-child) {
        border-bottom: solid 1px #eceaf4;
    }
`;

export const PassLabel = styled.div`
    color: ${COLOR_PASS} !important;
    font-size: 1rem;
    font-weight: 600;
`;

export const TableContainer = styled.div`
    border-top: solid 1px #b7b6c2;
    border-bottom: solid 1px #b7b6c2;
`;

export const TextInputContainer = styled.div`
    width: 9rem;

    > div {
        min-width: unset;
        width: 100% !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        [type='number'] {
            -moz-appearance: textfield;
        }
    }
`;
