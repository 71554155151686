import React, { FC } from 'react';

import Title from './Components/Title';

import type { TitleProps } from '../types';

const Territory: FC<TitleProps> = ({ title, children }) => {
    return (
        <div className="d-flex flex-column gap-1">
            {!!title && <Title>{title}</Title>}
            {children}
        </div>
    );
};

export default Territory;
