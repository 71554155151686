import React from 'react';
import { Portal } from 'react-overlays';

const el = document.getElementById('root');

const CalendarContainer = ({ children }) => {
    return <Portal container={el}>{children}</Portal>;
};

export default CalendarContainer;
