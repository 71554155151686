import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';

import { DASHBOARD_SUBMENU } from '../../Layout/AppNav/NavItems';
import OccupantStatus from './OccupantStatus';

import type { MatchProps } from '@util/type/util';

const Dashboard: FC<MatchProps> = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/${DASHBOARD_SUBMENU.OCCUPANT_STATUS}`} component={OccupantStatus} />
        </ErrorHandleSwitch>
    );
};

export default Dashboard;
