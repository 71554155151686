import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useTranslation, useAppSelector, useModal } from '@hooks';
import { Card, Button, ConfirmModal } from '@components';

import Form from './Form';
import { useInputValues } from './hooks';

import { translateOptions, translateValue } from '@util/common/util';

import {
    VISITOR_INPUTS,
    AFFILIATION_INPUTS,
    SELECT_TYPES,
    UNSELECTED,
    INITIAL_SELECTED_VALUES,
    COMBINED_INPUTS,
    cleanFalsyToNull,
    checkRequireInput,
} from './util';

import type { HandleSelect, SelectedValues } from './types';

interface Props {
    handleClosePass: () => void;
    createEntrants: (params: Object) => void;
}

const RegisterPass: FC<Props> = ({ handleClosePass, createEntrants }) => {
    const t = useTranslation('Visitor');
    const cT = useTranslation('Translate Choose');

    const { createdCategoryList } = useAppSelector(state => state.WorkerSafety);

    const { inputValues, handleInput } = useInputValues(COMBINED_INPUTS);

    const [selectedValues, setSelectedValues] = useState<SelectedValues>(INITIAL_SELECTED_VALUES);

    const [isValidationModalOpen, openValidationModal, toggleValidationModal] = useModal();

    const handleSelect: HandleSelect = (selected, actionMeta) => {
        const { name } = actionMeta;
        if (!name || !selected) return;

        setSelectedValues(prevState => ({
            ...prevState,
            [name]: selected,
        }));
    };

    const handleRegistrationClick = () => {
        // input 중 require 인 로직에 값이 있는지 여부 판단
        const isInputValidationPass = checkRequireInput(inputValues);

        const isSelectValidationPass = !!selectedValues[SELECT_TYPES.AFFILIATION]?.value;

        if (!isInputValidationPass || !isSelectValidationPass) {
            toggleValidationModal();
            return;
        }

        createEntrants(
            cleanFalsyToNull({
                ...inputValues,
                categoryCode: selectedValues[SELECT_TYPES.AFFILIATION].value,
            }),
        );
    };

    return (
        <RegisterCard
            header={{
                titleIcon: 'add',
                title: t('Add New Entrants'),
            }}
            bodyClassName="d-flex flex-column gap-4"
            footerClassName="gap-1"
            footerTitle={
                <>
                    <Button className="btn-gray" onClick={handleClosePass}>
                        {t('Cancel', 'Button')}
                    </Button>
                    <Button
                        iconName="add"
                        iconClassName="material-icons-round md-16"
                        className="btn-brand flx-1"
                        onClick={handleRegistrationClick}
                    >
                        {t('New Entrant Registration Complete')}
                    </Button>
                </>
            }
        >
            <Form title={t('Personal Information')}>
                {VISITOR_INPUTS.map(({ key, title, require }) => {
                    return (
                        <Form.Input
                            key={key}
                            formMust={require}
                            title={t(title)}
                            name={key}
                            value={inputValues[key]}
                            handleInput={handleInput}
                        />
                    );
                })}
            </Form>
            <Form title={t('Affiliation')}>
                <Form.Select
                    formMust
                    title={t(SELECT_TYPES.AFFILIATION)}
                    name={SELECT_TYPES.AFFILIATION}
                    value={translateValue(selectedValues[SELECT_TYPES.AFFILIATION], cT)}
                    options={translateOptions(
                        [
                            UNSELECTED,
                            ...createdCategoryList.map(({ categoryName, categoryCode }) => ({
                                label: categoryName,
                                value: categoryCode,
                            })),
                        ],
                        cT,
                    )}
                    handleSelect={handleSelect}
                />
                {AFFILIATION_INPUTS.map(({ key, title, require }) => {
                    return (
                        <Form.Input
                            key={key}
                            formMust={require}
                            title={t(title)}
                            name={key}
                            value={inputValues[key]}
                            handleInput={handleInput}
                        />
                    );
                })}
            </Form>
            <ConfirmModal
                initModal={isValidationModalOpen}
                toggleModal={toggleValidationModal}
                header={{ title: t('Notification', 'Locker') }}
                confirmText={t(
                    'Required input is missing. Please enter and try again. (Required input value: Name/affiliated division/company name)',
                )}
                removeCancel
            />
        </RegisterCard>
    );
};

const RegisterCard = styled(Card)`
    min-width: 25rem;
    .card-header {
        background-color: #f6f5f8 !important;
    }
`;

export default RegisterPass;
