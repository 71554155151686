import React, { useState, useEffect, useRef } from 'react';
import { Map, RotatedImageOverlay, GeofenceLayer } from '../../Common';
import GridLayoutCard from '../Components/GridLayoutCard';
import Control from 'react-leaflet-control';
import { useSelector } from 'react-redux';
import useAsync from '@hooks/useAsync';
import { fetchGeofenceList } from '../../../api/common';
import { useSettings } from '../util/useSettings';
import L from 'leaflet';
import GeofenceInfoPopup from './Component/GeofenceInfoPopup';
import FloorTreeSelect from '../Components/FloorTreeSelect';

const GeofenceStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const mapRef = useRef();
    const widgetRef = useRef();
    const [selectedFloor, setSelectedFloor] = useState(settings.floor);
    const [floorInfo, setFloorInfo] = useState({});
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const { geofenceInOutState } = useSelector(state => state.TagInfo);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });
    const handleFloorChange = selects => {
        if (selects[0]) {
            setSelectedFloor(selects[0].floorId);
            setFloorInfo(selects[0]);
        }
    };

    useEffect(() => {
        if (selectedFloor) {
            getGeofenceList({ floor: selectedFloor });
            const map = mapRef.current.leafletElement;
            if (map) {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        }
    }, [selectedFloor]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            setGeofenceInfo(
                response.rows.map(geofence => {
                    const inOutInfo = geofenceInOutState[geofence.fcNum] || {};
                    return {
                        ...geofence,
                        bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        authorized: inOutInfo.authorized,
                        unAuthorized: inOutInfo.unAuthorized,
                    };
                }),
            );
        }
    }, [getGeofenceInfo, geofenceInOutState]);

    return (
        <GridLayoutCard widgetInfo={widgetInfo} bodyClassName={'p-1'} {...restProps} ref={widgetRef}>
            <Map ref={mapRef}>
                <Control position="topleft">
                    <FloorTreeSelect
                        floorList={floorList}
                        selectedFloorStr={selectedFloor}
                        mode={'radioSelect'}
                        handleChange={handleFloorChange}
                    />
                    {/*<FloorSelect selectedFloorId={selectedFloor} handleChange={handleFloorChange} />*/}
                </Control>
                {floorInfo.imgURL && (
                    <RotatedImageOverlay
                        key={floorInfo.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={floorInfo.bounds}
                        onLoad={e => {
                            const { target: layer } = e;
                            layer._map.fitBounds(layer.getBounds());
                        }}
                    />
                )}
                {geofenceInfo.length > 0 && (
                    <GeofenceLayer
                        geofenceList={geofenceInfo}
                        authState
                        handleClick={geofence => setSelectedGeofence(geofence)}
                    />
                )}

                <GeofenceInfoPopup
                    widgetRef={widgetRef}
                    mapRef={mapRef}
                    modal={Object.keys(selectedGeofence).length > 0}
                    toggleModal={() => setSelectedGeofence({})}
                    selectedEvent={selectedGeofence}
                />
            </Map>
            {children}
        </GridLayoutCard>
    );
};

export default GeofenceStatus;
