import React, { FC } from 'react';
import styled from 'styled-components';

import { Button } from '@components';

interface Props {
    name: string;
    dept: string;
    handleDeleteClick: () => void;
}

const FormBadge: FC<Props> = ({ name, dept, handleDeleteClick }) => {
    return (
        <FormBadgeContainer>
            <span className="material-icons-round">person</span>
            <span className="name">{name}</span>
            <span className="department">{dept}</span>
            <Button iconName="close" className="btn-icon-only btn-trans" onClick={handleDeleteClick} />
        </FormBadgeContainer>
    );
};

const FormBadgeContainer = styled.div`
    background-color: #f0eff5;
    height: 1.75rem;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0.5rem;

    > .name {
        font-size: 1rem;
        font-weight: bold;
    }

    > .material-icons-round {
        font-size: 1rem;
    }
`;

export default FormBadge;
