import React, { FC } from 'react';
import styled from 'styled-components';

import { useAppSelector, useTranslation } from '@hooks';

import BoxedLabel from './BoxedLabel';
import Clock from './Clock';

import LOGO from '@asset/images/worker-safety/logo.svg';
import TEL_WORKER from '@asset/images/worker-safety/tel_worker.png';

import type { DashboardResponse } from '../../type';
import CountLabel from './CountLabel';
import OccupantPie from './OccupantPie';

interface Props {
    entire: DashboardResponse['entire'];
}

const SideStatus: FC<Props> = ({ entire }) => {
    const t = useTranslation('Dashboard');
    const { fullScreen } = useAppSelector(state => state.DashboardFrame);

    return (
        <SideStatusContainer>
            <div className="d-flex w-100 h-80 flex-column justify-content-center gap-4">
                {fullScreen && (
                    <LogoWrapper>
                        <img className="logo" src={LOGO} alt="logo" />
                    </LogoWrapper>
                )}
                <TitleWrapper>{t('Cleanroom total access factor')}</TitleWrapper>
                <div className="d-flex flex-column gap-2">
                    <Clock />
                </div>
                <OccupantPie summaries={entire.summaries} />
                <div className="d-flex flex-column gap-2">
                    {entire.summaries.map(({ categoryCode, categoryName, count, markerColor }) => (
                        <CountLabel key={categoryCode} color={markerColor} name={categoryName} count={count} />
                    ))}
                </div>
            </div>
        </SideStatusContainer>
    );
};

const SideStatusContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 18.75rem;
    height: 100%;
    background-color: #00326b;
    padding: 3rem 1.25rem;

    background-image: url(${TEL_WORKER});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 12.625rem 9.216rem;
`;

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    .logo {
        width: 11rem;
    }
`;

const TitleWrapper = styled.div`
    color: white !important;
    padding: 0 1rem;
    text-align: center;
    font-size: 2.75rem;
    font-weight: 700;
    line-height: normal;
`;

export default SideStatus;
