import type { OptionType } from '@components/type';
import { nullishToDash, makeDateWithBracket } from '../../util';

export const PLACE_HOLDER = 'Please Enter';

export const DATE = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
} as const;

export const EMPLOYEE = 'employee';

// Register
export const VISITOR_INPUTS = [
    {
        key: 'entrantName',
        title: 'Name',
        require: true,
        disabled: false,
    },
] as const;

export const AFFILIATION_INPUTS = [
    {
        key: 'cpName',
        title: 'Company Name',
        require: true,
        disabled: false,
    },
    {
        key: 'identifier',
        title: 'Identifier',
        require: false,
        disabled: false,
    },
    {
        key: 'department',
        title: 'Dept.',
        require: false,
        disabled: false,
    },
    {
        key: 'designation',
        title: 'Position',
        require: false,
        disabled: false,
    },
] as const;

export const COMBINED_INPUTS = [...VISITOR_INPUTS, ...AFFILIATION_INPUTS];

export const SELECT_TYPES = {
    AFFILIATION: 'Company Classification',
    PURPOSE_TO_ENTRY: 'Purpose To Entry',
} as const;

export const UNSELECTED = { label: 'Choose', value: null };

export const INITIAL_SELECTED_VALUES = Object.fromEntries(
    Object.values(SELECT_TYPES).map(value => [value, UNSELECTED]),
);

// View
export const ACCESS_PASS_INPUTS = [
    {
        key: 'beaconNum',
        title: 'Beacon Number',
        require: false,
        disabled: true,
        valueFunc: nullishToDash,
    },
    {
        key: 'rentedDate',
        title: 'Rented Date',
        require: false,
        disabled: true,
        valueFunc: makeDateWithBracket,
    },
] as const;

export const cleanFalsyToNull = (obj: Object) => {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value || null]));
};

export const checkRequireInput = (inputValues: OptionType) => {
    return Object.entries(inputValues).every(([key, value]) => {
        return !COMBINED_INPUTS.find(({ key: originKey }) => originKey === key)?.require || !!value?.length;
    });
};
