import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Card, Table } from '@components';
import { useFilterDatePickerGroupWithPeriod, useFilterSearchWithType, useFilterSelect } from '../../Components/Filter';
import { useAsync, useAsyncExcel, useTranslation } from '@util/hooks';
import { getFacilityEnteringRecordsApi, postFacilityEnteringRecordsExportApi } from '@api/tel';
import { nullishToDash, makeDateWithBracket, makeTimeFromSeconds, falsyToUndefined } from '../../util';

import Search from './Components/Search';

import type { CellValue, UnixTimestamp } from '@util/type/util';
import ViewPass from '../../Components/Pass/ViewPass';

const INITIAL_PAGE_DATA = {
    page: 1,
    pageSize: 30,
    totalCount: 0,
    rows: [],
};

// 입퇴실 기록 현황
const EntryExitRecordStatus = () => {
    const t = useTranslation('Entry Exit Record Status');
    const periodProps = useFilterDatePickerGroupWithPeriod();
    const categoryProps = useFilterSelect();
    const keywordProps = useFilterSearchWithType();

    const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

    const initialParam = {
        page: INITIAL_PAGE_DATA.page,
        startDate: periodProps.initialState.start,
        endDate: periodProps.initialState.end,
    };

    const { state, promise: getList } = useAsync({
        promise: getFacilityEnteringRecordsApi,
        resolve: () => {},
        immediate: true,
        param: initialParam,
        fixedParam: {
            pageSize: INITIAL_PAGE_DATA.pageSize,
        },
        reject: (err: Error) => console.error(err),
    });

    const data = state.response ? { ...state.response, pageSize: INITIAL_PAGE_DATA.pageSize } : INITIAL_PAGE_DATA;

    const { request } = state;

    const triggerDownload = useAsyncExcel({
        promise: postFacilityEnteringRecordsExportApi,
        param: {
            startDate: falsyToUndefined(request?.startDate),
            endDate: falsyToUndefined(request?.endDate),
            categoryCode: falsyToUndefined(request?.categoryCode),
            opt: falsyToUndefined(request?.opt),
            keyword: falsyToUndefined(request?.keyword),
            columnMetas: [
                {
                    name: t('Name'),
                    key: 'entrantName',
                },
                {
                    name: t('Belong to'),
                    key: 'categoryName',
                },
                {
                    name: t('Company Name'),
                    key: 'cpName',
                },
                {
                    name: t('Identifier'),
                    key: 'identifier',
                },
                {
                    name: t('Department Name'),
                    key: 'department',
                },
                {
                    name: t('Position'),
                    key: 'designation',
                },
                {
                    name: t('Beacon Number'),
                    key: 'beaconNum',
                },
                {
                    name: t('Clean Room Classification'),
                    key: 'floorName',
                },
                {
                    name: t('Entry Date'),
                    key: 'floorInDate',
                },
                {
                    name: t('Exit Date'),
                    key: 'floorOutDate',
                },
                {
                    name: t('Stay Duration'),
                    key: 'stayDuration',
                },
            ],
            zipFileName: 'entry-exit-history',
            fileName: 'entry-exit-history',
        },
    });

    const columns = useMemo(
        () => [
            {
                Header: t('Personal information'),
                columns: [
                    {
                        Header: t('Name'),
                        accessor: 'entrant.entrantName',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
            {
                Header: t('Affiliation information'),
                columns: [
                    {
                        Header: t('Belong to'),
                        accessor: 'category.categoryName',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Company Name'),
                        accessor: 'properties.cpName',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Identifier'),
                        accessor: 'properties.identifier',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Department Name'),
                        accessor: 'properties.department',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Position'),
                        accessor: 'properties.designation',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
            {
                Header: t('Pass Information'),
                columns: [
                    {
                        Header: t('Beacon Number'),
                        accessor: 'record.beaconNum',
                        width: 140,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<number>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<number>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
            {
                Header: t('Entry/Exit Log'),
                columns: [
                    {
                        Header: t('Clean Room Classification'),
                        accessor: 'floor.floorName',
                        width: 140,
                        className: 'text-ellipsis',
                    },
                    {
                        Header: t('Entry Date'),
                        accessor: 'floor.floorInDate',
                        Cell: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeDateWithBracket(value);
                        },
                        formatTitle: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeDateWithBracket(value);
                        },
                        width: 140,
                    },
                    {
                        Header: t('Exit Date'),
                        accessor: 'floor.floorOutDate',
                        Cell: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeDateWithBracket(value);
                        },
                        formatTitle: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeDateWithBracket(value);
                        },
                        width: 140,
                    },
                    {
                        Header: t('Stay Duration'),
                        accessor: 'floor.stayDuration',
                        Cell: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeTimeFromSeconds(value);
                        },
                        formatTitle: ({ value }: CellValue<UnixTimestamp>) => {
                            return makeTimeFromSeconds(value);
                        },
                        width: 140,
                    },
                ],
            },
        ],
        [t],
    ) as any;

    const handleSearchClick = () => {
        // 검색옵션이 '선택하세요'와 '전체'가 아닐 땐 검색어가 있어야 함
        if (!!keywordProps.keyword.type?.value && keywordProps.keyword.text.length === 0) {
            alert(t('Enter a keyword'));
            return;
        }

        getList({
            page: INITIAL_PAGE_DATA.page,
            startDate: periodProps.date.start,
            endDate: periodProps.date.end,
            categoryCode: categoryProps.value ? categoryProps.value.value : undefined,
            opt: keywordProps.keyword.type ? keywordProps.keyword.type.value : undefined,
            keyword: keywordProps.keyword.text.length !== 0 ? keywordProps.keyword.text : undefined,
        });
    };

    const handleResetClick = () => {
        periodProps.reset();
        categoryProps.reset();
        keywordProps.reset();
        getList(initialParam);
    };

    const handlePageChange = (pageIndex: number) => {
        getList({ ...state.request, page: pageIndex });
    };

    return (
        <div className="d-flex gap-2 h-100 w-100">
            <Card className="w-100" bodyClassName="overflow-hidden">
                <Search
                    periodProps={periodProps}
                    categoryProps={categoryProps}
                    keywordProps={keywordProps}
                    onSearchClick={handleSearchClick}
                    onResetClick={handleResetClick}
                />
                <TableWrapper className="filter-search-table">
                    <Table
                        columns={columns}
                        data={data}
                        onPageChange={handlePageChange}
                        loading={state.isLoading}
                        onTrClick={(trData: any) => {
                            const { record, ...restTrData } = trData;
                            setSelectedVisitor({
                                ...restTrData,
                                rental: record,
                            });
                        }}
                        actionButton={
                            <Button
                                iconName="download"
                                className="btn-secondary"
                                onClick={() => {
                                    triggerDownload();
                                }}
                            >
                                {t('Download')}
                            </Button>
                        }
                    />
                </TableWrapper>
            </Card>
            {selectedVisitor && (
                <ViewPass visitor={selectedVisitor} handleClosePass={() => setSelectedVisitor(null)} removeChange />
            )}
        </div>
    );
};

const TableWrapper = styled.div`
    margin-top: 20px;

    .th {
        text-align: center !important;
        justify-content: center;
    }

    & .td {
        display: flex !important;
        justify-content: center !important;
    }
`;

export default EntryExitRecordStatus;
