import React, { ForwardedRef, forwardRef } from 'react';
import { TextInputProps } from '../type';
import cx from 'classnames';

export const TextInput = forwardRef(
    (
        {
            type = 'text',
            size,
            name,
            id,
            value,
            handleChange,
            style,
            disabled = false,
            readonly,
            placeholder,
            buttonIcon,
            buttonIconClassName = 'material-icons-round',
            className,
            buttonClassName,
            minlength,
            maxlength,
            required,
            buttonHandleClick,
            handleKeyUp,
            handleKeyDown,
            inputGroupClassName,
            errorMsg,
            autoFocus = false,
        }: TextInputProps,
        ref: ForwardedRef<HTMLInputElement>,
    ): JSX.Element => {
        return (
            <div
                className={cx(
                    'pnt-input--group',
                    inputGroupClassName,
                    disabled && 'form-disable',
                    buttonIcon && 'btn-on-right',
                )}
            >
                <input
                    ref={ref}
                    type={type}
                    size={size}
                    name={name}
                    id={id}
                    value={value}
                    onChange={handleChange}
                    className={cx('pnt-input', className)}
                    placeholder={placeholder}
                    disabled={disabled}
                    minLength={minlength}
                    maxLength={maxlength}
                    required={required}
                    style={style}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    autoFocus={autoFocus}
                    readOnly={readonly}
                />
                {buttonIcon && (
                    <button
                        className={`pnt-btn btn-icon-only btn-trans ${buttonClassName}`}
                        tabIndex={-1}
                        onClick={buttonHandleClick}
                    >
                        <span className={buttonIconClassName}>{buttonIcon}</span>
                    </button>
                )}
                <p className="input-error-txt">{errorMsg}</p>
            </div>
        );
    },
);

export default TextInput;
