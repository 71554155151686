import React from 'react';
import styled from 'styled-components';
import { Modal, Label } from '@components';
import userImg from '@asset/images/unknown_user.png';
import useTranslation from '@hooks/useTranslation';

const ProfileModal = ({ initModal, userInfo, toggleModal }) => {
    const t = useTranslation('UserBox');
    return (
        <Modal
            initModal={initModal}
            toggleModal={toggleModal}
            headerTitle={t('Profile')}
            removeCancel
            headerClassName={'bg-depth-4'}
        >
            <div className="modal-body">
                <ProfileContainer className="user-profile-container">
                    <div className="flx-col">
                        <img src={userImg} alt="profileImg" width="150" height="150" />
                    </div>
                    <div className="flx-col justify-content-around">
                        <Label
                            name={t('Name')}
                            labelValueClassName="color-brand"
                            value={userInfo.userName}
                            disableEllipsis
                        />
                        <Label
                            name={t('ID')}
                            labelValueClassName="color-brand"
                            value={userInfo.userID}
                            disableEllipsis
                        />
                        <Label
                            name={t('Email')}
                            labelValueClassName="color-brand"
                            value={userInfo.userEmail}
                            disableEllipsis
                        />
                    </div>
                </ProfileContainer>
            </div>
        </Modal>
    );
};

const ProfileContainer = styled.div`
    width: 100%;
    column-gap: 1rem;
`;

export default ProfileModal;
