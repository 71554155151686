import { createSlice } from '@reduxjs/toolkit';

import type { OccupantCard } from '../Components/MainPages/DispatchingManagement/Management';
import type { Nullable } from '@util/type/util';

interface Category {
    categoryCode: string;
    categoryName: string;
    categoryOrderSequence: number;
    markerColor: string;
}

type Command = 'LINKED' | 'UNLINKED' | 'CREATED' | 'MODIFIED';

interface RentalInfo {
    suuid: string;
    command: Command;
    data: OccupantCard;
}

interface InitialState {
    createdCategoryList: Category[];
    rental: Nullable<RentalInfo>;
}

const initialState: InitialState = {
    createdCategoryList: [],
    rental: null,
};

const { actions, reducer } = createSlice({
    name: 'workerSafety',
    initialState,
    reducers: {
        setCreatedCategoryList: (state, action) => {
            state.createdCategoryList = action.payload;
        },
        setRental: (state, action) => {
            state.rental = action.payload;
        },
    },
});

export const { setCreatedCategoryList, setRental } = actions;
export default reducer;
