import { useCallback } from 'react';
import { useTranslation as useTranslationOrigin } from 'react-i18next';

// 일반적인 웹사이트에서 공통으로 사용하는 용어 + IoT 공통 용어들의 네임스페이스
// ex) 확인, 취소, 대상 이름, 신호 없음 등
const COMMON_NAMESPACE = 'Common';

const useTranslation = ns => {
    const { t } = useTranslationOrigin();
    return useCallback(
        (text, overWriteNs = ns, variables) => {
            // overWriteNs가 없어서, 편의상 두번째 파라미터로 변수를 넘겨주는 경우
            if (typeof overWriteNs === 'object' && !variables) {
                variables = overWriteNs;
                overWriteNs = ns;
            }

            // 최종 네임스페이스가 없는 경우, Common 처리 안함
            if (!overWriteNs) {
                return t(text, variables);
            }

            const message = `${overWriteNs};${text}`;
            if (message === t(message, variables)) {
                const commonMessage = `${COMMON_NAMESPACE};${text}`;
                return commonMessage === t(commonMessage, variables) ? text : t(commonMessage, variables);
            }
            return t(message, variables);
        },
        [t, ns],
    );
};

export default useTranslation;
