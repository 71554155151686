import React from 'react';
import { useAppSelector, useTranslation } from '@util/hooks';
import Filter, { useFilterSearchWithType, useFilterSelect } from '../../../Components/Filter';
import { KEYWORD_OPTIONS } from '../../../util/wsUtil';
import { Button } from '@components';

interface Props {
    categoryProps: ReturnType<typeof useFilterSelect>;
    keywordProps: ReturnType<typeof useFilterSearchWithType>;
    onSearchClick: () => void;
    onResetClick: () => void;
    handleOpenPersonalPass: () => void;
}

const Search = ({ categoryProps, keywordProps, onSearchClick, onResetClick, handleOpenPersonalPass }: Props) => {
    const t = useTranslation('Filter');
    const { createdCategoryList } = useAppSelector(state => state.WorkerSafety);

    const categoryOptions = [
        { label: t('All categories'), value: null },
        ...createdCategoryList.map(({ categoryName, categoryCode }) => ({ label: categoryName, value: categoryCode })),
    ];

    const keywordOptions = KEYWORD_OPTIONS.map(({ label, value }) => ({ label: t(label), value }));

    const { value: categoryValue, handleChange: handleCategoryChange } = categoryProps;
    const { keyword, handleTypeChange, handleTextChange } = keywordProps;
    return (
        <div className="d-flex justify-content-between">
            <Filter>
                <div className="d-flex gap-5">
                    <div className="d-flex align-items-center">
                        <Filter.Label text={t('Belong to')} />
                        <Filter.Select
                            value={categoryValue ?? categoryOptions[0]}
                            options={categoryOptions}
                            onChange={handleCategoryChange}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <Filter.Label text={t('Keyword')} />
                        <Filter.SearchWithType
                            selectValue={keyword.type?.value || undefined}
                            selectOptions={keywordOptions}
                            onSelectChange={handleTypeChange}
                            inputValue={keyword.text}
                            onInputChange={handleTextChange}
                            onSearchClick={onSearchClick}
                        />
                        <Filter.Refresh onClick={onResetClick} className="ml-1" />
                    </div>
                </div>
            </Filter>
            <Button
                iconName="add"
                iconClassName="material-icons-round md-18"
                className="btn-secondary"
                onClick={handleOpenPersonalPass}
            >
                {t('Entrance Registration', 'Visitor')}
            </Button>
        </div>
    );
};

export default Search;
