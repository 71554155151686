import React, { useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import WidgetSettingModal from '../Components/WidgetSettingModal';

const SensorStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useDispatch();

    const t = useTranslation('SensorStatus');

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Sensor Status Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        />
    );
};

export default SensorStatusSetting;
