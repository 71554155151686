import React, { useRef, useState, useContext, useEffect } from 'react';
import { Button, Checkbox, TextInput } from '@components';
import { loginErrorMsg } from '@components/style';
import { useTranslation, useAsync } from '@hooks';
import LoginTitle from './LoginTitle';
import { fetchOAuthToken } from '@api/login';
import { setOauthInfo } from '@reducer/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setShowErrorMsg } from '../reducer';
import { LoginDispatchContext, LoginStateContext } from '../index';

import envKeys from '../../../../environment';
import { decodeInfo, encodeInfo } from '@util/common/util';
import useMoreUserInfo from '../hooks/useMoreUserInfo';

const { savedId } = envKeys;

const LoginForm = () => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Login');
    const formRef = useRef();
    const { userInfo } = useSelector(state => state.UserInfo);
    const dispatch = useContext(LoginDispatchContext);
    const { showErrorMsg } = useContext(LoginStateContext);
    const [saveId, setSaveId] = useState(false);
    const [showPw, setShowPw] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const { promise: initialLogin } = useAsync({
        promise: fetchOAuthToken,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: err => {
            dispatch(setShowErrorMsg(true));
            setErrorMessage(t('Please check ID and Password'));
        },
    });

    const getMoreUserInfo = useMoreUserInfo();

    // 1. 로그인 버틴 클릭시 user가 입력한 ID와 Password와 함께 initialLogin 호출
    const handleLogin = e => {
        e.preventDefault();

        const form = formRef.current;
        if (!form.userID.value || !form.userPW.value) {
            dispatch(setShowErrorMsg(true));
            setErrorMessage(t('Please enter your ID and Password'));
        } else {
            initialLogin({ userID: form.userID.value, userPW: form.userPW.value });
        }
    };

    const handleEnter = e => {
        if (e.keyCode === 13) {
            handleLogin(e);
        }
    };

    useEffect(() => {
        const lsSavedId = localStorage.getItem(savedId);
        if (lsSavedId) {
            formRef.current.userID.value = decodeInfo(lsSavedId);
            setSaveId(true);
        }
    }, []);

    useEffect(() => {
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.timeZone) {
            if (saveId) {
                localStorage.setItem(savedId, encodeInfo(formRef.current.userID.value));
            } else {
                localStorage.removeItem(savedId);
            }
        }
    }, [userInfo]);

    return (
        <>
            <LoginTitle title={t('Login')} subTitle={t('Please enter your ID and Password')} />
            <div className="cont-box">
                {/* 로그인 실패시 뜨는 에러 */}
                <div className="flx-col gap-4">
                    <div className={'cont--error'} style={!showErrorMsg ? loginErrorMsg : {}}>
                        <p>* {errorMessage}</p>
                    </div>

                    <div className="flx-col flx-full">
                        <form className="flx-col" ref={formRef} onSubmit={handleLogin}>
                            {/* ID 입력창 */}
                            <div className="form-height-big">
                                <TextInput
                                    className={'w-100 form-height-big login-form'}
                                    type={'text'}
                                    name={'userID'}
                                    placeholder={t('ID')}
                                    inputGroupClassName={'w-100 h-100'}
                                    // buttonIcon={cx('icon-lock', saveId && 'on')}
                                    // buttonHandleClick={e => {
                                    //     e.preventDefault();
                                    //     setSaveId(!saveId);
                                    // }}
                                />
                            </div>

                            {/* Password 입력창 */}
                            <div className="form-height-big">
                                <TextInput
                                    className={'w-100 form-height-big login-form'}
                                    type={!showPw ? `password` : `text`}
                                    name={'userPW'}
                                    placeholder={t('Password')}
                                    inputGroupClassName={'w-100 btn-on-right h-100'}
                                    handleKeyUp={handleEnter}
                                    handleKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                        }
                                    }}
                                    buttonClassName={'icon-filter-white opacity-6'}
                                    buttonIconType={'outlined'}
                                    buttonIcon={!showPw ? 'visibility_off' : 'visibility'}
                                    buttonHandleClick={e => {
                                        e.preventDefault();
                                        setShowPw(!showPw);
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                    {/* 아이디 저장 */}
                    <Checkbox
                        name={t('Save ID')}
                        id="saveId"
                        handleChecked={() => setSaveId(!saveId)}
                        checked={saveId}
                        htmlFor="saveId"
                    />

                    <Button className="btn-brand w-100 form-h-big font-size-md" onClick={handleLogin}>
                        {t('Login')}
                    </Button>
                </div>
            </div>
            {/*<div className="bottom-box">*/}
            {/*    <div className="bottom-box__wrap">*/}
            {/*        <div className="find-pw">*/}
            {/*            <button*/}
            {/*                style={{ backgroundColor: 'transparent' }}*/}
            {/*                onClick={e => {*/}
            {/*                    dispatch(initFindStep());*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                {t('Forgot your Password?')}*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

export default LoginForm;
