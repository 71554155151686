import React, { useState } from 'react';

import { useAsync, useInterval } from '@hooks';
import { getDashboardApi } from '@api/tel';

import SideStatus from './Components/SideStatus';
import StatusByFloor from './Components/StatusByFloor';

import type { DashboardResponse } from './type';

const INTERVAL_TIME = 3 * 1000;

const INITIAL_STATE: DashboardResponse = {
    cleanRoom: [],
    entire: {
        inbound: 0,
        outbound: 0,
        summaries: [],
        lowBatteryCount: 0,
    },
};

const OccupantStatus = () => {
    const [dashboard, setDashboard] = useState<DashboardResponse>(INITIAL_STATE);

    const { promise: getDashboard } = useAsync({
        promise: getDashboardApi,
        resolve: (res: DashboardResponse) => {
            setDashboard(res);
        },
        reject: (err: Error) => {
            console.error(err);
        },
        immediate: true,
    });

    useInterval({
        callback: getDashboard,
        delay: INTERVAL_TIME,
    });

    return (
        <div className="d-flex h-100 w-100 gap-1">
            <SideStatus entire={dashboard.entire} />
            <StatusByFloor cleanRoom={dashboard.cleanRoom} />
        </div>
    );
};

export default OccupantStatus;
