import React from 'react';
import cx from 'classnames';

import type { ComponentProps } from '../types';

export const InputGroup = ({ children, className }: ComponentProps) => {
    return <div className={cx('pnt-input--group', className)}>{children}</div>;
};

export const InputWrap = ({ children, className }: ComponentProps) => {
    return <div className={cx('input-wrap', className)}>{children}</div>;
};

export const FilterList = ({ children, className }: ComponentProps) => {
    return <div className={cx('filter-list', className)}>{children}</div>;
};

export const SearchWrap = ({ children, className }: ComponentProps) => {
    return <div className={cx('search-wrap', className)}>{children}</div>;
};

export const SelectGroup = ({ children, className }: ComponentProps) => {
    return <div className={cx('pnt-select--group', className)}>{children}</div>;
};

export const SearchButtonGroup = ({ children, className }: ComponentProps) => {
    return <div className={cx('search-button--group', className)}>{children}</div>;
};

export const SearchWrapGroup = ({ children, className }: ComponentProps) => {
    return <div className={cx('search-wrap search-wrap--group', className)}>{children}</div>;
};
