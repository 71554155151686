import React from 'react';
import language from '@util/staticData/language.json';
import Select from '@components/Select';
import Label from '@components/Label';
import useTranslation from '@hooks/useTranslation';

/**
 * @param {string} value 선택한 언어
 * @param {function} onChange 언어 변경 함수
 *
 * @author created bu 정진범 2022-12-08
 * */
const LanguageSelect = ({ value, onChange }) => {
    const t = useTranslation('UserBox');
    const selected = language.find(item => item.value === value);
    return (
        <Label
            name={t('Language selection')}
            value={
                <Select
                    customControlStyles={{ width: '9rem' }}
                    customMenuStyles={{ width: '9rem' }}
                    customOptionStyles={{ width: '9rem' }}
                    value={selected}
                    options={language}
                    onChange={onChange}
                    isModalSelect
                />
            }
        />
    );
};

export default LanguageSelect;
