import React, { useMemo, useEffect, useRef } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import useTranslation from '../../../../util/hooks/useTranslation';
import moment from 'moment';
import { Timestamp } from '@util/type/util';
import { NullableTimestamp } from '@components/DatePicker';

type HHMM = string;

type UnitOfDay = 'day';
type UnitOfHour = 'hour';
type UnitOfMin = 'minute';

type UnitForStartOf = UnitOfHour | UnitOfMin;

type TimeInputProps = {
    date: NullableTimestamp;
    value?: HHMM;
    onChange?: (time: HHMM) => void;
    minTime?: Timestamp;
    maxTime?: Timestamp;
};

const TimeInput = ({ date, value = '', onChange = () => {}, minTime, maxTime }: TimeInputProps) => {
    const t = useTranslation('DatePicker');
    const hourRef = useRef<HTMLDivElement>(null);
    const minRef = useRef<HTMLDivElement>(null);

    const timeOptions = useMemo(() => {
        const h = [];
        const m = [];
        for (let i = 0; i < 60; i++) {
            if (i < 24) {
                h.push(i);
            }
            m.push(i);
        }
        return { h, m };
    }, []);

    useEffect(() => {
        if (value) {
            const [h, m] = value.split(':');
            const hourList = hourRef.current;
            const minList = minRef.current;
            if (hourList) {
                setScrollTop(hourList, h);
            }
            if (minList) {
                setScrollTop(minList, m);
            }
        }
    }, []);

    const isDisabledHour = (amount: number): boolean => isDisableTime({ date, minTime, maxTime, unit: 'hour', amount });
    const isDisabledMin = (amount: number): boolean =>
        isDisableTime({ date, minTime, maxTime, unit: 'minute', amount });

    useEffect(() => {
        if (date && minTime && date < minTime) {
            onChange(moment(minTime).format('HH:mm'));
        }
        if (date && maxTime && date > maxTime) {
            onChange(moment(maxTime).format('HH:mm'));
        }
    }, [date, minTime, maxTime, onChange]);

    return (
        <>
            <div className={'react-datepicker__header'}>
                <div className={'react-datepicker-time__header'}>{t('Time')}</div>
                <div className={'time-label-box'}>
                    <div>{t('hour')}</div>
                    <div>{t('min')}</div>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <ListGroup vertical={'true'}>
                    <div className={'list-wrap'} ref={hourRef}>
                        {timeOptions.h.map(v => (
                            <ListGroupItem
                                key={v}
                                tag={'button'}
                                value={v}
                                action
                                disabled={isDisabledHour(v)}
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    const currValue = e.currentTarget.value;
                                    const valueArr = value.split(':');
                                    valueArr.splice(0, 1, currValue);
                                    onChange(valueArr.join(':'));
                                }}
                                active={Number(value.split(':')[0]) === v}
                            >
                                {v}
                            </ListGroupItem>
                        ))}
                    </div>
                </ListGroup>
                <ListGroup vertical={'true'}>
                    <div className={'list-wrap'} ref={minRef}>
                        {timeOptions.m.map(v => (
                            <ListGroupItem
                                key={v}
                                tag={'button'}
                                value={v}
                                action
                                disabled={isDisabledMin(v)}
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    const currValue = e.currentTarget.value;
                                    const valueArr = value.split(':');
                                    valueArr.splice(1, 1, currValue);
                                    onChange(valueArr.join(':'));
                                }}
                                active={Number(value.split(':')[1]) === v}
                            >
                                {v}
                            </ListGroupItem>
                        ))}
                    </div>
                </ListGroup>
            </div>
        </>
    );
};

const setScrollTop = (listEl: HTMLElement, value: string) => {
    const selectedEl = listEl.querySelector(`button[value='${Number(value)}']`);
    if (selectedEl) {
        listEl.scrollTop = selectedEl.getBoundingClientRect().top - listEl.getBoundingClientRect().top;
    }
};

const UPPER_DATE_UNIT: { hour: UnitOfDay; minute: UnitOfHour } = {
    hour: 'day',
    minute: 'hour',
};

const isOutOfMin = (date: NullableTimestamp, unit: UnitForStartOf, amount: number, minTime?: Timestamp): boolean => {
    return Boolean(
        date &&
            minTime &&
            moment(date).startOf(UPPER_DATE_UNIT[unit]).add(unit, amount).isBefore(moment(minTime).startOf(unit)),
    );
};

const isOutOfMax = (date: NullableTimestamp, unit: UnitForStartOf, amount: number, maxTime?: Timestamp): boolean => {
    return Boolean(
        date &&
            maxTime &&
            moment(date).startOf(UPPER_DATE_UNIT[unit]).add(unit, amount).isAfter(moment(maxTime).startOf(unit)),
    );
};

const isDisableTime = ({
    date,
    minTime,
    maxTime,
    unit,
    amount,
}: {
    date: NullableTimestamp;
    minTime?: Timestamp;
    maxTime?: Timestamp;
    unit: UnitForStartOf;
    amount: number;
}): boolean => {
    return isOutOfMin(date, unit, amount, minTime) || isOutOfMax(date, unit, amount, maxTime);
};

export default TimeInput;
