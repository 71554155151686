import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const INTERVAL_TIME = 1000;
const INITIAL_TIME_STAMP = '00:00:00';
const INITIAL_DATE = '0000-00-00 (월)';

const Clock = () => {
    const [clock, setClock] = useState({
        time: INITIAL_TIME_STAMP,
        date: INITIAL_DATE,
    });

    useEffect(() => {
        const changeClock = () => {
            const current = moment();
            setClock({
                time: current.format('HH:mm:ss'),
                date: current.format('YYYY-MM-DD (ddd)'),
            });
        };

        changeClock();

        const timeId = setInterval(changeClock, INTERVAL_TIME);

        return () => {
            clearInterval(timeId);
        };
    }, []);

    return (
        <ClockContainer>
            <TimeWrapper>{clock.time}</TimeWrapper>
            <DateWrapper>{clock.date}</DateWrapper>
        </ClockContainer>
    );
};

const ClockContainer = styled.div`
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background: rgba(255, 255, 255, 0.1);

    font-variant: tabular-nums;
    line-height: normal;
`;

const TimeWrapper = styled.div`
    display: flex;
    color: white !important;
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: -0.03125rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
`;

const DateWrapper = styled.div`
    color: #ffffff99 !important;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.03125rem;
`;

export default Clock;
