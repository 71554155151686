import React, { ChangeEvent, FC } from 'react';
import cx from 'classnames';

import { useTranslation } from '@hooks';
import { TextInput, Button, ToggleButton } from '@components';

import { ViewPassCard, PassContainer, PassInfo, PassLabel, TableContainer, TextInputContainer } from './styled';

import Form from './Form';

import TableLabel from './Form/TableLabel';
import { falsyToDash, makeDateWithBracket, nullishToDash } from '../../util';
import { RentalResponse } from '../../DispatchingManagement/Management';

interface Props {
    visitor: RentalResponse;
    handleClosePass: () => void;
    handleOpenChangePass?: () => void;
    removeChange?: boolean;
    handleToggleSwitch?: (visitor: RentalResponse) => void;
    handleAccessCardNumber?: (event: ChangeEvent<HTMLInputElement>) => void;
    accessCardNumber?: number;
}

const ViewPass: FC<Props> = ({
    visitor,
    handleClosePass,
    handleOpenChangePass,
    removeChange,
    handleToggleSwitch,
    handleAccessCardNumber,
    accessCardNumber,
}) => {
    const t = useTranslation('Visitor');

    const { category, entrant, properties, rental } = visitor;

    return (
        <ViewPassCard
            header={{
                titleIcon: 'assignment_ind',
                titleIconClassName: 'material-icons-round md-20 color-black',
                title: t('Accessor Information'),
            }}
            bodyClassName="py-0"
            footerClassName="gap-1"
            footerTitle={
                <>
                    <Button className={cx('btn-gray', removeChange && 'w-100')} onClick={handleClosePass}>
                        {t(removeChange ? 'Close' : 'Cancel', 'Button')}
                    </Button>
                    {!removeChange && (
                        <Button
                            iconName="edit"
                            iconClassName="material-icons-round md-16"
                            className="btn-secondary flx-1"
                            onClick={handleOpenChangePass}
                        >
                            {t('Modifying Access Information')}
                        </Button>
                    )}
                </>
            }
        >
            <PassContainer>
                <PassInfo>
                    <PassLabel>{t('Access Pass Number')}</PassLabel>
                    {removeChange && !!rental?.beaconNum ? (
                        <div className="font-weight-bold font-size-lg">{nullishToDash(rental.beaconNum)}</div>
                    ) : (
                        <TextInputContainer>
                            <TextInput
                                type="number"
                                disabled={!!rental?.beaconNum}
                                handleChange={handleAccessCardNumber}
                                value={accessCardNumber?.toString()}
                            />
                        </TextInputContainer>
                    )}
                </PassInfo>
                <PassInfo>
                    <PassLabel>{t('Access Pass Availability')}</PassLabel>
                    {/*@ts-ignore*/}
                    <ToggleButton
                        disabled={removeChange}
                        checked={!!rental?.beaconNum}
                        handleChecked={() => {
                            typeof handleToggleSwitch === 'function' && handleToggleSwitch(visitor);
                        }}
                    />
                </PassInfo>
            </PassContainer>
            {!!rental?.rentedDate && (
                <Form title={t('Rent Information')}>
                    <TableContainer>
                        <TableLabel title={t('Rented Date')}>{makeDateWithBracket(rental.rentedDate)}</TableLabel>
                        {'returnedDate' in rental && (
                            <TableLabel title={t('Return Date')}>{makeDateWithBracket(rental.returnedDate)}</TableLabel>
                        )}
                    </TableContainer>
                </Form>
            )}

            <div className="d-flex flex-column gap-4 py-3">
                <Form title={t('Personal Information')}>
                    <TableContainer>
                        <TableLabel title={t('Name')}>{nullishToDash(entrant.entrantName)}</TableLabel>
                    </TableContainer>
                </Form>
                <Form title={t('Affiliation')}>
                    <TableContainer>
                        <TableLabel title={t('Company Classification')}>
                            {nullishToDash(category?.categoryName)}
                        </TableLabel>
                        <TableLabel title={t('Company Name')}>{nullishToDash(properties.cpName)}</TableLabel>
                        <TableLabel title={t('Identifier')}>{falsyToDash(properties.identifier)}</TableLabel>
                        <TableLabel title={t('Department')}>{nullishToDash(properties.department)}</TableLabel>
                        <TableLabel title={t('Position')}>{nullishToDash(properties.designation)}</TableLabel>
                    </TableContainer>
                </Form>
            </div>
        </ViewPassCard>
    );
};

export default ViewPass;
