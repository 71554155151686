import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OptionType } from '@components/type';
import { useAppSelector, useAsync } from '@util/hooks';
import SearchCard from './Components/SearchCard';
import CountsCard from './Components/CountsCard';
import OccupantsCard from './Components/OccupantsCard';
import OccupantMap from './Components/OccupantMap';
import { useLocation } from 'react-router-dom';
import { getOccupantsApi } from '@api/tel';

import type { OccupantWithBeacon, OccupantCount } from '../ListOfOccupant/types';

interface GraphData {
    name: string;
    value: number;
    color: string;
}

export interface CountInfo {
    lowBatteryCount: number;
    totalOccupant: number;
    occupantCounts: GraphData[];
}

const OccupantLocation = () => {
    const { state } = useLocation<{ targetNum: number; floorId: string } | undefined>();

    const { floorList } = useAppSelector(state => state.FloorInfo) as any;
    const { tagList } = useAppSelector(state => state.TagInfo) as any;

    const floorOptions = floorList.map(({ floorName, floorId }: { floorName: string; floorId: string }) => ({
        label: floorName,
        value: floorId,
    }));
    const [selectedFloor, setSelectedFloor] = useState<OptionType | null>(null);
    if (!selectedFloor && floorList.length > 0) {
        setSelectedFloor({
            label: floorList[0].floorName,
            value: floorList[0].floorId,
        });
    }
    const [selectedTargetNum, setSelectedTargetNum] = useState<number | null>(null);

    useEffect(() => {
        if (!state) {
            return;
        }

        if (state.floorId) {
            const currentFloor = floorList.find(({ floorId }: { floorId: string }) => floorId === state.floorId);
            const { floorName, floorId } = currentFloor;

            setSelectedFloor({
                label: floorName,
                value: floorId,
            });
        }

        if (state.targetNum) {
            setSelectedTargetNum(state.targetNum);
        }
    }, [state, floorList]);

    const [countsInfo, setCountsInfo] = useState<CountInfo>({
        lowBatteryCount: 0,
        totalOccupant: 0,
        occupantCounts: [],
    });
    const [occupantRows, setOccupantRows] = useState<OccupantWithBeacon[]>();
    const { promise: getOccupants } = useAsync({
        promise: getOccupantsApi,
        param: {
            floorId: selectedFloor?.value,
            targetNum: selectedTargetNum,
        },
        resolve: ({
            rows,
            additionalDatas: { occupantCount },
        }: {
            rows: OccupantWithBeacon[];
            additionalDatas: { occupantCount: OccupantCount[] };
        }) => {
            setOccupantRows(rows);

            const lowBatteryCount = rows.reduce((acc, cur) => {
                return acc + (!cur.beacon.batteryStatus ? 1 : 0);
            }, 0);

            const { totalOccupant, counts } = occupantCount.reduce(
                (acc, cur) => {
                    const { count, categoryName, markerColor } = cur;
                    return {
                        totalOccupant: acc.totalOccupant + cur.count,
                        counts: [
                            ...acc.counts,
                            {
                                name: categoryName,
                                value: count,
                                color: markerColor,
                            },
                        ],
                    };
                },
                { totalOccupant: 0, counts: [] as GraphData[] },
            );

            setCountsInfo({ totalOccupant, occupantCounts: counts, lowBatteryCount });
        },
        reject: (err: Error) => console.error(err),
    });

    // 주기적 호출 effect
    useEffect(() => {
        getOccupants();

        const id = setInterval(() => {
            getOccupants();
        }, 3000);

        return () => {
            clearInterval(id);
        };
    }, [selectedFloor?.value, selectedTargetNum]);

    const handleUncheck = () => {
        if (!selectedTargetNum) {
            return;
        }
        setSelectedTargetNum(null);
    };

    useEffect(() => {
        if (!selectedTargetNum || !tagList || !floorList.length) return;

        const currentFloorId = tagList?.[selectedTargetNum]?.location?.floor;
        if (!currentFloorId) return;
        const currentFloor = floorList.find(({ floorId }: { floorId: string }) => floorId === currentFloorId);
        const { floorName, floorId } = currentFloor;

        if (selectedFloor?.value === floorId) return;

        setSelectedFloor({
            label: floorName,
            value: floorId,
        });
    }, [floorList, selectedTargetNum, tagList]);

    return (
        <div className="d-flex w-100 h-100">
            <CardContainer className="d-flex flex-column mr-2">
                <SearchCard
                    floorOptions={floorOptions}
                    selectedFloor={selectedFloor}
                    onFloorChange={floor => {
                        setSelectedFloor(floor);
                        setSelectedTargetNum(null);
                    }}
                    onSearch={(targetNum: number) => {
                        setSelectedTargetNum(targetNum);
                    }}
                    onRefreshClick={() => {
                        setSelectedTargetNum(null);
                    }}
                />
                {countsInfo && <CountsCard countsInfo={countsInfo} />}
                {occupantRows && (
                    <OccupantsCard
                        occupantRows={occupantRows}
                        onOccupantClick={(targetNum: number) => {
                            setSelectedTargetNum(targetNum);
                        }}
                    />
                )}
            </CardContainer>
            {selectedFloor && (
                <OccupantMap
                    selectedFloor={selectedFloor}
                    selectedTargetNum={selectedTargetNum}
                    onUncheck={handleUncheck}
                />
            )}
        </div>
    );
};

const CardContainer = styled.div`
    min-width: 18rem;
`;

export default OccupantLocation;
