import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';

import { REALTIME_STATUS_SUBMENU } from '../../Layout/AppNav/NavItems';

import ListOfOccupant from './ListOfOccupant';
import OccupantLocation from './OccupantLocation';

import { MatchProps } from '@util/type/util';

const RealtimeStatus = ({ match }: MatchProps) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/${REALTIME_STATUS_SUBMENU.OCCUPANT_LIST}`} component={ListOfOccupant} />
            <Route
                exact
                path={`${match.path}/${REALTIME_STATUS_SUBMENU.OCCUPANT_LOCATION}`}
                component={OccupantLocation}
            />
        </ErrorHandleSwitch>
    );
};

export default RealtimeStatus;
