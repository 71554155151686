import React, { useState, useEffect, useRef, createContext, useReducer } from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { setEnableMobileMenu } from '../../../reducers/ThemeOptions';
import { CustomNav, SettingsNav } from './NavItems';
import { useTranslation } from 'react-i18next';
import { setBookmarkMenu } from '../../../reducers/CustomMenu';
import CustomLink from './CustomLink';
import useAsync from '@hooks/useAsync';
import { getDashboardListApi } from '../../../api/dashboard';
import { getFilterListApi } from '../../../api/filter';
import { AUTHORIZATION } from './NavItems';

const chgMenuLabelByLanguage = (menus, t) => {
    return menus.map(v => {
        const copyV = { ...v };
        if (copyV.content) {
            copyV.content = chgMenuLabelByLanguage(copyV.content, t);
        }
        if (!copyV.id || (copyV.id && !copyV.id.includes('customDashboard_') && !copyV.id.includes('filterMenu_'))) {
            copyV.label = t(`Menu;${copyV.label}`);
        }
        return copyV;
    });
};

export const SidebarStateContext = createContext();

const Nav = ({ location, match, setting, onUpdateSize }) => {
    const [state] = useReducer(null, { onUpdateSize });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { filterList } = useSelector(state => state.FilterInfo);
    const realtimeLocationTracingMatch = useRouteMatch('/worker/status/:targetNum([0-9]+)');
    const realtimeCoalStorageMatch = useRouteMatch('/dashboard/status/:targetNum([0-9]+)');
    const [customMenu, setCustomMenu] = useState({ bookmarkedMenu: [], filterList: [] });
    const { promise: getCustomMenu } = useAsync({
        promise: [getDashboardListApi, getFilterListApi],
        param: [{ isIndividual: 'Y', show: 'Y', isAll: 'Y' }, { isAll: 'Y' }],
        resolve: ([{ data: dashboardListInfo }, { data: filterListInfo }]) => {
            let bookmarkedMenu = dashboardListInfo.rows || [];
            dispatch(setBookmarkMenu(bookmarkedMenu));

            // setFilterList(filterListInfo.rows || []);
            // dispatch(setFilterList(filterListInfo.rows || []));
            setCustomMenu({ bookmarkedMenu: dashboardListInfo.rows || [], filterList: filterListInfo.rows || [] });
        },
    });
    const menuUpdateBundle = useRef();

    const { enableMobileMenu } = useSelector(state => state.ThemeOptions);
    const { smartSafeManagerInfo } = useSelector(state => state.UserInfo);

    const navRef = useRef();
    const [menus, setMenus] = useState(CustomNav);

    const { dashboardListInfo } = useSelector(state => state.Dashboards);
    // const { bookmarkedMenu } = useSelector(state => state.CustomMenu);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    // const isPathActive = path => {
    //     return location.pathname.startsWith(path);
    // };

    const forcedActiveUpdate = () => {
        //window.location.hash === # + location.pathname
        const activePath = window.location.hash || `#${location.pathname}`;
        if (activePath.indexOf('dashboards/edit') >= 0) {
            navRef.current.changeActiveLinkTo(activePath.replace('/edit', ''));
            // navRef.current.changeActiveLinkTo('#/dashboards/list');
        }
        if (
            realtimeLocationTracingMatch &&
            realtimeLocationTracingMatch.params &&
            realtimeLocationTracingMatch.params.targetNum
        ) {
            navRef.current.changeActiveLinkTo(
                activePath.replace(`/${realtimeLocationTracingMatch.params.targetNum}`, ''),
            );
        }
        if (realtimeCoalStorageMatch && realtimeCoalStorageMatch.params && realtimeCoalStorageMatch.params.targetNum) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${realtimeCoalStorageMatch.params.targetNum}`, ''));
        }
        navRef.current.changeActiveLinkTo(activePath);
    };

    useEffect(() => {
        // 대시보드 생성 수정 시, 북마크 메뉴 다시 API 호출로 수정
        getCustomMenu();
        // const bookmarked = dashboardListInfo.rows.filter(dashboard => dashboard.show === 'Y');
        // dispatch(setBookmarkMenu(bookmarked));
    }, [dashboardListInfo, filterList]);

    // useEffect(() => {
    //     setMenus(chgMenuLabelByLanguage(CustomNav, t));
    // }, [lang]);

    useEffect(() => {
        const { bookmarkedMenu, filterList } = customMenu;
        // const dashboardMenu = CustomNav.find(menu => menu.id === 'dashboard');

        // 대시보드 커스텀을 위한 주석처림
        // if (bookmarkedMenu.length) {
        //     const customMenus = bookmarkedMenu.map((dashboard, i) => {
        //         const { menuName, menuNum, home } = dashboard;
        //         const menu = { label: menuName, to: `#/dashboards/${menuNum}` };
        //         menu.id = 'customDashboard_' + (i + 1);
        //         if (i === bookmarkedMenu.length - 1) {
        //             menu.id = 'customDashboard_last';
        //         }
        //         if (home === 'Y') {
        //             menu.id += '_home';
        //         }
        //         return menu;
        //     });
        //
        //     const dashboardSubMenu = dashboardMenu.content;
        //     const dashboardSubMenuLen = dashboardSubMenu.length;
        //     let checkCnt = 0;
        //     while (dashboardSubMenuLen > checkCnt++) {
        //         if (dashboardSubMenu[0].id && dashboardSubMenu[0].id.indexOf('customDashboard_') >= 0) {
        //             dashboardSubMenu.shift();
        //         }
        //     }
        //     dashboardMenu.content = [...customMenus, ...dashboardSubMenu];
        // }

        const filterMenu = CustomNav.find(menu => menu.id === 'filter') || { content: [] };
        if (filterList.length) {
            const applyMenuFilter = filterList.filter(filterInfo => filterInfo.isApplyMenu === 'Y');
            if (applyMenuFilter.length) {
                const filterMenus = applyMenuFilter.map((filterInfo, i) => {
                    const { filterName, filterNum } = filterInfo;
                    return {
                        label: filterName,
                        to: `#/filter/item/list/${filterNum}`,
                        id: 'filterMenu_' + (applyMenuFilter.length === i + 1 ? 'last' : i + 1),
                    };
                });
                filterMenu.content = [
                    ...filterMenus,
                    ...filterMenu.content.filter(v => !v.id || !v.id.includes('filterMenu_')),
                ];
            }
        }

        const isInAuthList = Object.keys(AUTHORIZATION).includes(smartSafeManagerInfo.roleName);

        if (menuUpdateBundle.current) {
            clearTimeout(menuUpdateBundle.current);
            menuUpdateBundle.current = null;
        }
        menuUpdateBundle.current = setTimeout(() => {
            setMenus(
                chgMenuLabelByLanguage(
                    CustomNav.map(menu => {
                        if (isInAuthList && !menu.auth.includes(smartSafeManagerInfo.roleName)) return null;
                        return menu;
                    }).filter(menu => menu !== null),
                    t,
                ),
            );
        }, 250);
    }, [customMenu, t, smartSafeManagerInfo.roleName, filterList, setting]);

    useEffect(() => {
        forcedActiveUpdate();
    }, [location, menus, setting]);

    return (
        <SidebarStateContext.Provider value={state}>
            <MetisMenu
                content={menus}
                onSelected={toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="fa fa-angle-down scale-07"
                classNameItemHasActiveChild="nav-child-active"
                ref={navRef}
                LinkComponent={CustomLink}
            />
        </SidebarStateContext.Provider>
    );
};

export default withRouter(Nav);
