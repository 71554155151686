import React, { useState } from 'react';
import { Button, DatePicker, RadioButton, Select, TextInput } from '@components';
import styled from 'styled-components';
import { useTranslation } from '@util/hooks';
import { OptionType } from '@components/type';
import moment from 'moment';
import cx from 'classnames';

export const useFilterSearchWithType = () => {
    const initialState = {
        type: null, // null or { value: string; label:string; }
        text: '',
    };
    const [keyword, setKeyword] = useState<{ type: { value: string; label: string } | null; text: string }>(
        initialState,
    );

    const handleTypeChange = (selected: any) => {
        setKeyword({ type: selected, text: '' });
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword({ ...keyword, text: e.target.value });
    };

    const reset = () => {
        setKeyword(initialState);
    };

    return { keyword, handleTypeChange, handleTextChange, reset, initialState };
};

const FilterSearchButton = ({ onSearchClick }: { onSearchClick: () => void }) => {
    const t = useTranslation('Filter');
    return (
        <Button className="btn-secondary" onClick={onSearchClick}>
            {t('Search')}
        </Button>
    );
};

const FilterSearchWithType = ({
    selectValue,
    selectOptions,
    onSelectChange,
    inputValue,
    onInputChange,
    onSearchClick,
}: {
    selectValue: any;
    selectOptions: OptionType[];
    onSelectChange: (value: any) => void;
    inputValue: string;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchClick: () => void;
}) => {
    const t = useTranslation('Filter');

    return (
        <GapContainer>
            <div className="d-flex">
                <Select value={selectValue} options={selectOptions} onChange={onSelectChange} className="mr-1" />
                <TextInput
                    value={inputValue}
                    handleChange={onInputChange}
                    disabled={!selectValue}
                    placeholder={t('Please select search conditions first')}
                />
            </div>
            <FilterSearchButton onSearchClick={onSearchClick} />
        </GapContainer>
    );
};

export const useFilterSearch = () => {
    const initialState = '';
    const [value, setValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const reset = () => {
        setValue(initialState);
    };

    return { value, handleChange, reset, initialState };
};

const FilterSearch = ({
    value,
    onChange,
    onSearchClick,
}: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchClick: () => void;
}) => {
    const t = useTranslation('Filter');

    return (
        <GapContainer>
            <TextInput value={value} handleChange={onChange} placeholder={t('Please select search conditions first')} />
            <FilterSearchButton onSearchClick={onSearchClick} />
        </GapContainer>
    );
};

const FilterRefresh = ({ onClick, className }: { onClick: () => void; className?: string }) => {
    return <Button className={cx('btn-lightgray btn-icon-only', className)} iconName="refresh" onClick={onClick} />;
};

export const useFilterSelect = <T,>(param?: { initialValue: T }) => {
    const initialState = (param ? param.initialValue : null) as T extends { label: string; value: string }
        ? { label: string; value: string }
        : null;
    const [value, setValue] = useState<any>(initialState);

    const handleChange = (selected: any) => {
        setValue(selected);
    };

    const reset = () => {
        setValue(initialState);
    };

    return { value, handleChange, reset, initialState };
};

const FilterSelect = ({
    value,
    options,
    onChange,
    disabled = false,
}: {
    value: any;
    options: any[];
    onChange: (selected: any) => void;
    disabled?: boolean;
}) => {
    return <Select value={value} options={options} onChange={onChange} disabled={disabled} />;
};

const FilterLabel = ({ text, className = '' }: { text: string; className?: string }) => {
    return <Label className={className}>{text}</Label>;
};

export const useFilterDatePickerGroupWithPeriod = () => {
    const initialState = {
        period: 'select',
        start: moment().startOf('day').unix(),
        end: moment().endOf('day').unix(),
    } as const;
    const [date, setDate] = useState<{
        period: 'select' | 'last-7' | 'last-30';
        start: number;
        end: number;
    }>(initialState);

    const handleStartDateChange = (selectedDate: number) => {
        if (selectedDate > date.end) {
            setDate({
                ...date,
                start: selectedDate,
                end: moment(selectedDate * 1000)
                    .endOf('day')
                    .unix(),
            });
            return;
        }
        setDate({ ...date, start: selectedDate });
    };

    const handleEndDateChange = (selectedDate: number) => {
        if (selectedDate < date.start) {
            setDate({
                ...date,
                start: moment(selectedDate * 1000)
                    .startOf('day')
                    .unix(),
                end: selectedDate,
            });
            return;
        }
        setDate({ ...date, end: selectedDate });
    };

    const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === 'select') {
            setDate({ period: value, start: moment().startOf('day').unix(), end: moment().endOf('day').unix() });
            return;
        }
        if (value === 'last-7') {
            setDate({
                period: value,
                start: moment().subtract(6, 'days').startOf('day').unix(),
                end: moment().endOf('day').unix(),
            });
            return;
        }
        if (value === 'last-30') {
            setDate({
                period: value,
                start: moment().subtract(29, 'days').startOf('day').unix(),
                end: moment().endOf('day').unix(),
            });
        }
    };

    const reset = () => {
        setDate(initialState);
    };

    return {
        date,
        handleStartDateChange,
        handleEndDateChange,
        handlePeriodChange,
        reset,
        initialState,
    };
};

interface DatePickerGroupWithPeriodPrpos<T extends { value: string; label: string }> {
    startDate: number;
    onStartDateChange: (date: number) => void;
    endDate: number;
    onEndDateChange: (date: number) => void;
    periodValue: T['value'];
    periodList: T[]; // TODO: readonly T[]
    onPeriodChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterDatePickerGroupWithPeriod = <T extends { value: string; label: string }>({
    startDate,
    onStartDateChange,
    endDate,
    onEndDateChange,
    periodValue,
    periodList,
    onPeriodChange,
}: DatePickerGroupWithPeriodPrpos<T>) => {
    return (
        <div className="d-flex">
            <GapContainer>
                <DatePicker
                    value={startDate}
                    handleChange={onStartDateChange}
                    disabled={periodValue !== periodList[0]['value']}
                    withoutTime
                    maxDate={moment().unix() * 1000}
                />
                <span>~</span>
                <DatePicker
                    value={endDate}
                    handleChange={onEndDateChange}
                    disabled={periodValue !== periodList[0]['value']}
                    withoutTime
                    maxDate={moment().unix() * 1000}
                />
            </GapContainer>
            <GapContainer className="ml-4">
                <RadioButton name="period" value={periodValue} radioList={periodList} handleChange={onPeriodChange} />
            </GapContainer>
        </div>
    );
};

export const useFilterDatePicker = () => {
    const initialState = moment().startOf('day').unix();
    const [date, setDate] = useState<number | null>(initialState);

    const handleChange = (date: number) => {
        setDate(date);
    };

    const reset = () => {
        setDate(initialState);
    };

    return { date, handleChange, reset, initialState };
};

const FilterDatePicker = ({ date, onChange }: { date: number | null; onChange: (date: number) => void }) => {
    return (
        <GapContainer>
            <DatePicker value={date} handleChange={onChange} withoutTime />
        </GapContainer>
    );
};

const FilterMain = ({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
};

const GapContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    .pnt-datepicker-container {
        margin: 0;
    }

    .pnt-radio {
        margin: 0;
    }
`;

const Label = styled.div`
    width: 60px;
    color: #565672 !important;
    font-size: 12.8px;
    font-weight: 600;
`;

const Filter = Object.assign(FilterMain, {
    Label: FilterLabel,
    DatePickerGroupWithPeriod: FilterDatePickerGroupWithPeriod,
    Select: FilterSelect,
    SearchButton: FilterSearchButton,
    SearchWithType: FilterSearchWithType,
    Refresh: FilterRefresh,
    DatePicker: FilterDatePicker,
    Search: FilterSearch,
});

export default Filter;
