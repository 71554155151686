import { Portal } from 'react-overlays';
import React, { useEffect, useRef } from 'react';
import { Route, Redirect, withRouter, useRouteMatch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/ko';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import LocationPopup from './AppHeader/Components/LocationPopup';
import AppFooter from './AppFooter';

import { getLsUserInfo, getUuid } from '@util/common/util';
import {
    setAdditionalInfo,
    setOauthInfo,
    setOauthStatusModal,
    logOut,
    setLanguage,
    setSmartSafeManagerInfo,
} from '@reducer/UserInfo';

import { useUserTokenCheck } from '@hooks/utilHooks';
import ConfirmModal from '@components/ConfirmModal';

import { ErrorHandleSwitch } from '../MainPages/Components/Router';

import { useAsync, useTranslation } from '@hooks';
import { SOCKET_CONNECTING } from '@util/symbol/window';
import { getApiURL } from '../../api';
import { fetchCompany } from '@api/login';
import { fetchCategory } from '@api/asset';
import { fetchFloorList, getCategoryPropertiesListApi } from '@api/common';
import { getEntrantsDivisionsApi } from '@api/tel';
import { setMarkerConfig } from '@reducer/Common/AppConfig';
import { setNotification } from '@reducer/Notification';
import { setFullScreen } from '@reducer/Dashboards/DashboardFrame';
import { setFloorList } from '@reducer/Common/FloorInfo';
import { setAllCategoryList, setCategoryPropertiesList } from '@reducer/Common/CategoryInfo';
import socketio from 'socket.io-client';
import { setSocket } from '@reducer/SocketInfo';
import alarmSound from '../../assets/audio/alertSound.mp3';
import useSocket from '@util/socket/hooks/useSocket';
import cx from 'classnames';
import { setTagInfos } from '@reducer/TagInfo';

import { AUTHORIZATION, DASHBOARD_SUBMENU, DISPATCHING_MANAGEMENT_SUBMENU, MAIN_MENU } from './AppNav/NavItems';

import Dashboard from '../MainPages/Dashboard';
import RealtimeStatus from '../MainPages/RealtimeStatus';
import DispatchingManagement from '../MainPages/DispatchingManagement';
import AccessInformation from '../MainPages/AccessInformation';
import { setCreatedCategoryList, setRental } from '@reducer/WorkerSafety';

const RENDERING_TIME = 1000;

const SOCKET_TYPES = {
    NOTIFICATION: 'notification',
    UPDATE_MARKER: 'updateMarker',
    UPDATE_RENTAL: 'updateRental',
};

const AppMain = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const location = useLocation();

    const { userInfo, modal, smartSafeManagerInfo } = useSelector(state => state.UserInfo);
    const { fullScreen } = useSelector(state => state.DashboardFrame);
    const socketBuffer = useRef({
        interval: null,
        buffer: [],
    });

    const t = useTranslation('Login');
    const appMainOuterRef = useRef();
    const portal = document.body;

    const { socket, setSocketEvent, closeSocket } = useSocket();

    const flushData = () => {
        // current buffer 어레이가 있는 경우에만 실행
        if (socketBuffer.current.buffer.length > 0) {
            dispatch(setTagInfos(socketBuffer.current.buffer.splice(0)));
        }
    };
    const flushDataInterval = () => {
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = setInterval(() => {
            flushData();
        }, RENDERING_TIME);
    };
    const stopFlushDataInterval = () => {
        flushData();
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = null;
    };

    const { promise: getMoreUserInfo } = useAsync({
        promise: fetchCompany,
        resolve: companyInfo => {
            dispatch(
                setMarkerConfig({
                    markerTransitionActive: companyInfo.markerTransitionActive,
                    markerPulseActive: companyInfo.markerPulseActive,
                    markerBorderRadius: companyInfo.markerBorderRadius,
                    markerPulseAnimation: companyInfo.markerPulseAnimation,
                    markerPulseRssi: companyInfo.markerPulseRssi,
                    markerPulseColor: companyInfo.markerPulseColor,
                }),
            );
        },
    });

    // 화면 리프레쉬 후 유저의 정보가 사라졌을때
    // 로컬스토리지에 저장된 정보 호출 후 저장
    useEffect(() => {
        if (!userInfo.userName) {
            const { oAuthInfo, userInfo, smartSafeManagerInfo } = getLsUserInfo();
            dispatch(setOauthInfo(oAuthInfo));
            if (userInfo) {
                const { lang, ...restUserInfo } = userInfo;
                dispatch(setAdditionalInfo(restUserInfo));
                getApiURL();
                dispatch(setLanguage(lang));
            }
            if (smartSafeManagerInfo) {
                dispatch(setSmartSafeManagerInfo(smartSafeManagerInfo));
            }
        }
    }, [userInfo]);

    useEffect(() => {
        if (socket) {
            setSocketEvent(SOCKET_TYPES.NOTIFICATION, data => {
                dispatch(setNotification(data));
            });
            setSocketEvent(SOCKET_TYPES.UPDATE_MARKER, data => {
                socketBuffer.current.buffer.push(data);
            });
            setSocketEvent(SOCKET_TYPES.UPDATE_RENTAL, data => {
                dispatch(setRental(data));
            });
        }
        return () => {
            setSocketEvent(SOCKET_TYPES.NOTIFICATION);
            setSocketEvent(SOCKET_TYPES.UPDATE_MARKER);
            setSocketEvent(SOCKET_TYPES.UPDATE_RENTAL);
        };
    }, [socket, location]);

    // refresh token의 에러가 발생했을 시 토글 메뉴 실행 후 유저인포 초기화
    // 유저인포의 초기화로 인하여 로그인 페이지로 이동
    const toggleOauthStatus = () => {
        dispatch(logOut());
        dispatch(setOauthStatusModal(!modal.modalOauthStatusOpen));
    };

    useUserTokenCheck();

    useEffect(() => {
        getMoreUserInfo();
        if (!window[SOCKET_CONNECTING]) {
            window[SOCKET_CONNECTING] = true;
            getApiURL().then(({ wmsSocketUrl }) => {
                const uuid = getUuid();
                const ws = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                ws.on('connect', function () {
                    console.log('SOCKET_CONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    ws.emit('join', `presence-ws01-${uuid}`);
                    dispatch(setSocket(ws));
                    flushDataInterval();
                });
                ws.on('disconnect', function () {
                    console.log('SOCKET_DISCONNECT : ', wmsSocketUrl);
                    dispatch(setSocket(ws));
                    stopFlushDataInterval();
                });
                ws.on('reconnect', function () {
                    console.log('SOCKET_RECONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    ws.emit('join', `presence-ws01-${uuid}`);
                    dispatch(setSocket(ws));
                    flushDataInterval();
                });
            });
        }

        return () => {
            closeSocket(socket);
        };
    }, []);

    // FloorList 받아서 Redux에 저장
    useAsync({
        promise: fetchFloorList,
        param: { isAll: 'Y' },
        resolve: response => {
            dispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    // CategoryList 받아서 Redux에 저장
    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setAllCategoryList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: getCategoryPropertiesListApi,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setCategoryPropertiesList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: getEntrantsDivisionsApi,
        resolve: ({ rows }) => {
            dispatch(setCreatedCategoryList(rows));
        },
        reject: err => {
            console.error(err);
        },
        immediate: true,
    });

    const handleFullscreenChange = () => {
        if (document.fullscreenElement) {
            dispatch(setFullScreen(true));
        } else {
            dispatch(setFullScreen(false));
        }
    };

    useEffect(() => {
        const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
        dashboardEl.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            dashboardEl.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, [match.url]);

    const redirectUrl =
        smartSafeManagerInfo.roleName === AUTHORIZATION.BEACON_MANAGER
            ? `/${MAIN_MENU.DISPATCH}/${DISPATCHING_MANAGEMENT_SUBMENU.DISPATCH_MANAGEMENT}`
            : `/${MAIN_MENU.DASHBOARD}/${DASHBOARD_SUBMENU.OCCUPANT_STATUS}`;

    return (
        <>
            {/*<ThemeOptions />*/}
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer" ref={appMainOuterRef}>
                    <div className="app-main__inner">
                        <div className={cx('app-page-content', fullScreen && 'dashboard-fullscreen')}>
                            <ErrorHandleSwitch>
                                <main className={cx('main-layout', fullScreen ? 'vh-100' : 'default-inner-height')}>
                                    <Route
                                        exact
                                        path={'/'}
                                        render={() => {
                                            return <Redirect to={redirectUrl} />;
                                        }}
                                    />
                                    <Route path={`/${MAIN_MENU.DASHBOARD}`} component={Dashboard} />
                                    <Route path={`/${MAIN_MENU.REALTIME_STATUS}`} component={RealtimeStatus} />
                                    <Route path={`/${MAIN_MENU.DISPATCH}`} component={DispatchingManagement} />
                                    <Route path={`/${MAIN_MENU.ACCESS}`} component={AccessInformation} />
                                </main>
                            </ErrorHandleSwitch>

                            <ConfirmModal
                                initModal={modal.modalOauthStatusOpen}
                                toggleModal={toggleOauthStatus}
                                header={{ title: t('Authentication Error') }}
                                confirmText={
                                    <span>
                                        {t('The authentication information is incorrect. Please log in again.')}
                                    </span>
                                }
                                okCallback={toggleOauthStatus}
                                removeCancel={true}
                            />
                            <Portal container={portal}>
                                <LocationPopup appMainOuterRef={appMainOuterRef} />
                            </Portal>
                            <div id="select-container" />
                        </div>
                    </div>
                    <AppFooter />
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default withRouter(AppMain);
