import React, { FC } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

import { useTranslation } from '@hooks';
import { DashboardResponse } from '../../type';
import { RADIAN } from '../../../../util/wsUtil';

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (value === 0) {
        return null;
    }

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: '1rem', fontWeight: 'bold' }}
        >
            {value}
        </text>
    );
};

interface Props {
    summaries: DashboardResponse['entire']['summaries'];
}

const OccupantPie: FC<Props> = ({ summaries }) => {
    const t = useTranslation('Occupant Location');

    const colors = summaries.map(({ markerColor }) => markerColor);
    const totalCount = summaries.reduce((acc, cur) => {
        return acc + cur.count;
    }, 0);

    const occupantsData = summaries.map(({ categoryName, count, markerColor }) => ({
        name: categoryName,
        value: count,
        color: markerColor,
    }));

    return (
        <SquareContainer>
            <ResponsiveContainer width="100%" aspect={1} debounce={1000}>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        data={!totalCount ? [{ name: 'empty', value: 1 }] : occupantsData}
                        dataKey="value"
                        outerRadius="90%"
                        innerRadius="60%"
                        startAngle={450}
                        endAngle={90}
                        labelLine={false}
                        label={!totalCount ? <></> : renderCustomizedLabel}
                    >
                        {!totalCount ? (
                            <Cell fill="#F3F6F944" />
                        ) : (
                            occupantsData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))
                        )}
                    </Pie>
                    <text x="50%" y="40%" textAnchor="middle" dominantBaseline="central" fill="#ffffff">
                        {t('Occupant Count')}
                    </text>
                    <text
                        className="fw-bold fs-2"
                        x="50%"
                        y="55%"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fill="#ffffff"
                    >
                        {totalCount}
                    </text>
                </PieChart>
            </ResponsiveContainer>
        </SquareContainer>
    );
};

const SquareContainer = styled.div`
    width: 100%;
    aspect-ratio: 1;
`;

export default OccupantPie;
