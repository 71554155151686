import React, { useEffect, useMemo, useRef } from 'react';
import { Checkbox, RotatedImageOverlay } from '@components';
import { useAppSelector, useTranslation } from '@util/hooks';
import Control from 'react-leaflet-control';
import Map from '../../../Components/CustomMap';
import styled from 'styled-components';
import L from 'leaflet';
import CustomMarker from './CustomMarker';
import { makeTimeFormat } from '../../../util';
import { decisionStayColor } from '@util/common/util';

const PULSE_CLASS = {
    RED: 'red-pulse',
    ORANGE: 'orange-pulse',
    YELLOW: 'yellow-pulse',
    ORANGE_AND_YELLOW: 'orange-and-yellow-pulse',
    RED_AND_YELLOW: 'red-and-yellow-pulse',
};

export const NOT_LONG_STAY = 'white';
export const LONG_STAY_WARNING = 'orange';
export const LONG_STAY_DANGER = 'red';

const getPulseClassName = (state, target) => {
    const { battery } = state;
    const { stayDuration } = target;
    const { bgColor: longStayStatus } = decisionStayColor(stayDuration);

    if (battery && longStayStatus === LONG_STAY_DANGER) {
        return PULSE_CLASS.RED_AND_YELLOW;
    }

    if (battery && longStayStatus === LONG_STAY_WARNING) {
        return PULSE_CLASS.ORANGE_AND_YELLOW;
    }

    if (longStayStatus === LONG_STAY_DANGER) {
        return PULSE_CLASS.RED;
    }

    if (longStayStatus === LONG_STAY_WARNING) {
        return PULSE_CLASS.ORANGE;
    }

    if (battery) {
        return PULSE_CLASS.YELLOW;
    }

    return '';
};

const getPulseClassNameFromArray = tagArray => {
    let isLowBattery = false;
    let stayDuration = 0;

    tagArray.forEach(({ state, target }) => {
        if (state.battery) {
            isLowBattery = true;
        }

        if (target.stayDuration) {
            stayDuration = Math.max(stayDuration, target.stayDuration);
        }
    });

    return getPulseClassName({ battery: isLowBattery }, { stayDuration });
};

const ICON_HALF_SIZE = 6;

const OccupantMap = ({ selectedFloor, selectedTargetNum, onUncheck }) => {
    const t = useTranslation('Occupant Location');
    const mapRef = useRef(null);
    const { floorList } = useAppSelector(state => state.FloorInfo);
    const floorInfo = floorList.find(({ floorId }) => floorId === selectedFloor.value);
    const { tagList, tagListByFloor } = useAppSelector(state => state.TagInfo);

    const selectedFloorId = floorInfo.floorId;
    const clusteredTagList = useMemo(() => {
        if (!selectedFloorId || !tagListByFloor?.[selectedFloorId] || !mapRef.current) return {};

        const leafletElement = mapRef.current.leafletElement;
        const tagList = tagListByFloor?.[selectedFloorId];

        return tagList.reduce((acc, cur) => {
            const { lat, lng } = cur.location;

            const point = leafletElement.latLngToLayerPoint([lat, lng]);

            const filteredKeys = Object.keys(acc).filter(data => {
                const key = data.split('+').map(Number);

                return L.bounds([
                    [key[0] - ICON_HALF_SIZE, key[1] - ICON_HALF_SIZE],
                    [key[0] + ICON_HALF_SIZE, key[1] + ICON_HALF_SIZE],
                ]).intersects([
                    [point.x - ICON_HALF_SIZE, point.y - ICON_HALF_SIZE],
                    [point.x + ICON_HALF_SIZE, point.y + ICON_HALF_SIZE],
                ]);
            });

            if (!!filteredKeys.length) {
                acc[filteredKeys[0]].push(cur);
            } else {
                acc[`${point.x}+${point.y}`] = [cur];
            }

            return acc;
        }, {});
    }, [selectedFloorId, tagListByFloor]);

    const selectedTag = Object.entries(tagList).filter(([targetNum]) => Number(targetNum) === selectedTargetNum);
    const selectedTagObject = selectedTargetNum && selectedTag[0] && { [selectedTag[0][0]]: { ...selectedTag[0][1] } };

    useEffect(() => {
        if (!selectedTagObject) {
            return;
        }
        const map = mapRef.current.leafletElement;

        map.setView(selectedTagObject[selectedTargetNum].location.latLng);
    }, [selectedTagObject, selectedTargetNum]);

    return (
        <Map ref={mapRef} tile={false} closePopupOnClick={false}>
            <Control position="topright">
                <div className="d-flex align-items-center bg-white px-1" onClick={onUncheck}>
                    <Checkbox className="m-0" checked={!!selectedTargetNum} />
                    <span className="fw-bold">{t('Center The Person You Choose')}</span>
                </div>
            </Control>
            <Control position="bottomleft">
                <LocationChip className="d-flex align-items-center px-2 py-1 gap-2">
                    <InfoIcon className="material-icons-round">info</InfoIcon>
                    <span className="text-white fw-bold">
                        {t('Current Location')} : {floorInfo.floorName}
                    </span>
                </LocationChip>
            </Control>
            {floorInfo.imgURL && (
                <RotatedImageOverlay
                    key={floorInfo.floorId}
                    url={floorInfo.imgURL}
                    deg={floorInfo.deg}
                    bounds={[
                        [floorInfo.neLat, floorInfo.neLng],
                        [floorInfo.swLat, floorInfo.swLng],
                    ]}
                />
            )}
            {selectedTargetNum && selectedTag[0] ? (
                <CustomMarker
                    targetInfo={selectedTagObject[selectedTargetNum]}
                    isActive={selectedTargetNum === selectedTagObject[selectedTargetNum].target.targetNum}
                    pulseClassName={getPulseClassName(
                        selectedTagObject[selectedTargetNum].state,
                        selectedTagObject[selectedTargetNum].target,
                    )}
                />
            ) : (
                <>
                    {Object.entries(clusteredTagList).map(([_, value]) => {
                        if (value.length === 1) {
                            return (
                                <CustomMarker
                                    key={value[0].target.targetNum}
                                    targetInfo={value[0]}
                                    isActive={selectedTargetNum === value[0].target.targetNum}
                                    pulseClassName={getPulseClassName(value[0].state, value[0].target)}
                                />
                            );
                        }

                        const target = { ...value[0].target };
                        target.targetId = '';
                        target.length = value.length;

                        return (
                            <CustomMarker
                                key={target.targetNum}
                                targetInfo={{ ...value[0], target }}
                                pulseClassName={getPulseClassNameFromArray(value)}
                                popupContent={
                                    <StyledPopupWrapper>
                                        {value.map(({ target: valueTarget, state, floorInOutState }) => {
                                            if (!valueTarget) return null;

                                            const { battery } = state;
                                            const { stayDuration, internal } = valueTarget;

                                            const { bgColor: stayDurationBgColor } = decisionStayColor(stayDuration);

                                            const cpName = internal
                                                ? valueTarget.properties.identifier
                                                : valueTarget.properties['cp-name'];

                                            return (
                                                <div
                                                    key={valueTarget.targetNum}
                                                    className="w-100 d-flex align-items-center justify-content-between cursor-pointer"
                                                >
                                                    <div className="d-flex align-items-center gap-2 w-100">
                                                        <ColoredDot $color={valueTarget.markerColor} $diameter={12} />
                                                        <StyledPopup>
                                                            {`${valueTarget.targetName} / ${
                                                                cpName ?? '-'
                                                            } / ${makeTimeFormat(floorInOutState.inTime)}`}
                                                        </StyledPopup>
                                                    </div>
                                                    {battery && (
                                                        <div className="material-icons-round md-16 color-warning">
                                                            battery_3_bar
                                                        </div>
                                                    )}
                                                    {stayDurationBgColor !== NOT_LONG_STAY && (
                                                        <ColoredDot $color={stayDurationBgColor} $diameter={12} />
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </StyledPopupWrapper>
                                }
                            />
                        );
                    })}
                </>
            )}
        </Map>
    );
};

const LocationChip = styled.div`
    background-color: #6f6e7a;
    font-size: 0.75rem;
`;

const InfoIcon = styled.span`
    color: #9f9eae !important;
`;

export const ColoredDot = styled.div`
    width: ${({ $diameter }) => `${$diameter}px`};
    height: ${({ $diameter }) => `${$diameter}px`};
    border-radius: 50%;
    background-color: ${({ $color }) => $color};
`;

const StyledPopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;

    max-height: 8rem;
    overflow: hidden auto;
`;

const StyledPopup = styled.span`
    display: block;

    color: black !important;
    font-weight: bold;
    font-size: medium;
    margin-right: 0.5rem;

    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export default OccupantMap;
