import React, { Fragment } from 'react';
import { PieChart, Cell, Legend, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import { CHART_COLORS } from '../config';
import useToggleLegend from '../../../Widgets/util/useToggleLegend';

const CustomTooltip = ({ payload }) => {
    return (
        <div className={'custom-tooltip'}>
            {payload.length
                ? payload.map(item => {
                      return (
                          <Fragment key={`tooltip_${payload.name}`}>
                              <span>
                                  {' '}
                                  {item.name} : {item.value}{' '}
                              </span>
                              <br />
                          </Fragment>
                      );
                  })
                : null}
        </div>
    );
};
const ResponsivePieChart = ({ settings, data }) => {
    const {
        ToggleLegendRender,
        filteredData,
        // disable
    } = useToggleLegend({ data });
    return (
        <ResponsiveContainer width="100%">
            <PieChart>
                <Legend
                    content={ToggleLegendRender}
                    payload={data.map((v, i) => ({
                        value: v.name,
                        id: v.key,
                        color: CHART_COLORS[i % CHART_COLORS.length],
                    }))}
                />
                <Tooltip
                    // wrapperStyle={{ backgroundColor: 'white' }}
                    // labelFormatter={() => 'name'}
                    // payload={data.map((v, i) => ({
                    //     value: v.name,
                    //     id: v.key,
                    //     color: CHART_COLORS[i % CHART_COLORS.length],
                    // }))}
                    content={<CustomTooltip />}
                />
                <Pie
                    startAngle={90}
                    endAngle={450}
                    data={filteredData}
                    dataKey={'value'}
                    innerRadius={settings.chartType === 'doughnut' ? '50%' : ''}
                >
                    {/*{data.map(*/}
                    {/*    (v, i) =>*/}
                    {/*        !disable[v.name] && (*/}
                    {/*            <Cell key={v.name} fill={CHART_COLORS[i % CHART_COLORS.length]} />*/}
                    {/*        ),*/}
                    {/*)}*/}
                    {data.map((v, i) => (
                        <Cell key={v.key} fill={CHART_COLORS[i % CHART_COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default ResponsivePieChart;
