import React, { ChangeEvent, FC } from 'react';
import cx from 'classnames';

import { TextInput } from '@components';
import { useTranslation } from '@hooks';

import Topic from './Components/Topic';

import { PLACE_HOLDER } from '../util';

interface Props {
    title: string;
    value: string;
    name: string;
    formMust?: boolean;
    handleInput?: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const FormInput: FC<Props> = ({ title, name, value, handleInput, disabled, formMust }) => {
    const t = useTranslation('Visitor');

    return (
        <Topic title={title}>
            <TextInput
                inputGroupClassName={cx(formMust && 'form-must')}
                name={name}
                value={value}
                placeholder={t(PLACE_HOLDER)}
                handleChange={handleInput}
                disabled={disabled}
            />
        </Topic>
    );
};

export default FormInput;
