import { UNSELECTED, CHOOSE, KEYWORD_OPTIONS } from '../../util/wsUtil';
import TIMES from '@util/times';

export const SEARCH_PARAMS = {
    SEARCH_LOCATION: 'floorId',
    COMPANY_CLASSIFICATION: 'categoryCode',
    SEARCH_OPTION: 'opt',
    SEARCH_WORD: 'keyword',
    DURATION: 'duration',
} as const;

export const ALL_CATEGORIES = {
    label: 'All categories',
    value: null,
};

export const ONE_HOURS = TIMES.HOUR_1;
export const THREE_HOURS = 3 * ONE_HOURS;
export const SIX_HOURS = 6 * ONE_HOURS;

export const INITIAL_SEARCH_OPTIONS = {
    [SEARCH_PARAMS.SEARCH_LOCATION]: [UNSELECTED],
    [SEARCH_PARAMS.COMPANY_CLASSIFICATION]: [ALL_CATEGORIES],
    [SEARCH_PARAMS.SEARCH_OPTION]: KEYWORD_OPTIONS,
    [SEARCH_PARAMS.DURATION]: [
        UNSELECTED,
        { label: 'More than an hour', value: ONE_HOURS },
        { label: 'More than three hours', value: THREE_HOURS },
        { label: 'More than six hours', value: SIX_HOURS },
    ],
};

export const INITIAL_SEARCH_PARAMS = {
    [SEARCH_PARAMS.SEARCH_LOCATION]: UNSELECTED,
    [SEARCH_PARAMS.COMPANY_CLASSIFICATION]: ALL_CATEGORIES,
    [SEARCH_PARAMS.SEARCH_OPTION]: CHOOSE,
    [SEARCH_PARAMS.SEARCH_WORD]: '',
    [SEARCH_PARAMS.DURATION]: UNSELECTED,
};
