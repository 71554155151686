export const generateURL = mainMenu => subMenu => `#/${mainMenu}/${subMenu}`;

export const MAIN_MENU = {
    DASHBOARD: 'dashboard',
    REALTIME_STATUS: 'realtime-status',
    ACCESS: 'access',
    ALARM: 'alarm',
    DISPATCH: 'dispatch',
    WORK: 'work',
};

const dashboardURL = generateURL(MAIN_MENU.DASHBOARD);
const realtimeStatusURL = generateURL(MAIN_MENU.REALTIME_STATUS);
const dispatchingURL = generateURL(MAIN_MENU.DISPATCH);
const accessURL = generateURL(MAIN_MENU.ACCESS);

export const DASHBOARD_SUBMENU = {
    OCCUPANT_STATUS: 'occupant-status',
};

export const REALTIME_STATUS_SUBMENU = {
    OCCUPANT_LIST: 'occupant-list',
    OCCUPANT_LOCATION: 'occupant-location',
};

export const DISPATCHING_MANAGEMENT_SUBMENU = {
    DISPATCH_MANAGEMENT: 'dispatch-management',
    DISPATCH_RECORD: 'dispatch-record',
};

export const ACCESS_INFO_SUBMENU = {
    RECORD: 'record',
    LIST: 'list',
    MOVEMENT: 'movement',
};

export const AUTHORIZATION = {
    RTLS_MONITORING: 'RTLS_MONITORING',
    BEACON_MANAGER: 'BEACON_MANAGER',
    RTLS_MONITOR: 'RTLS_MONITOR',
};

export const CustomNav = [
    {
        id: MAIN_MENU.DASHBOARD,
        icon: 'icon-newtab',
        label: 'Dashboard',
        auth: [AUTHORIZATION.RTLS_MONITORING, AUTHORIZATION.RTLS_MONITOR],
        content: [
            {
                label: 'Occupant Status',
                to: dashboardURL(DASHBOARD_SUBMENU.OCCUPANT_STATUS),
            },
        ],
    },
    {
        id: MAIN_MENU.REALTIME_STATUS,
        icon: 'icon-newtab',
        label: 'Real-time Status',
        auth: [AUTHORIZATION.RTLS_MONITORING, AUTHORIZATION.RTLS_MONITOR],
        content: [
            {
                label: 'List Of Occupant',
                to: realtimeStatusURL(REALTIME_STATUS_SUBMENU.OCCUPANT_LIST),
            },
            {
                label: 'Occupant Location',
                to: realtimeStatusURL(REALTIME_STATUS_SUBMENU.OCCUPANT_LOCATION),
            },
        ],
    },
    {
        id: MAIN_MENU.DISPATCH,
        icon: 'icon-newtab',
        label: 'Access Card Dispatching Management',
        auth: [AUTHORIZATION.BEACON_MANAGER],
        content: [
            {
                label: 'Access Card Dispatching Management',
                to: dispatchingURL(DISPATCHING_MANAGEMENT_SUBMENU.DISPATCH_MANAGEMENT),
            },
            {
                label: 'Access Card Dispatch Record Status',
                to: dispatchingURL(DISPATCHING_MANAGEMENT_SUBMENU.DISPATCH_RECORD),
            },
        ],
    },
    {
        id: 'accessInformation',
        icon: 'icon-newtab',
        auth: [AUTHORIZATION.RTLS_MONITORING],
        label: 'Access Information',
        content: [
            {
                label: 'List Of Visitors',
                to: accessURL(ACCESS_INFO_SUBMENU.LIST),
            },
            {
                label: 'Entry/Exit Record Status',
                to: accessURL(ACCESS_INFO_SUBMENU.RECORD),
            },
            {
                label: 'Check Movement Of Visitor',
                to: accessURL(ACCESS_INFO_SUBMENU.MOVEMENT),
            },
        ],
    },
];

export const SettingsNav = [
    {
        id: 'wmsConnection',
        icon: 'icon-setting',
        label: 'Move to WMS',
        to: '',
    },
];
