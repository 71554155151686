import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { SensorStatusDispatchContext, SensorStatusStateContext } from '../../../index';
import { setLocationPopup } from '../../../sensorStatusReducer';
import useTranslation from '@hooks/useTranslation';
import { TextInput, Map, RotatedImageOverlay } from '../../../../../Common';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { useSelector } from 'react-redux';

// 센서 일별 / 월별 리포트에서 센서 정보에 위치보기를 눌렀을 때 나타나는 팝업
const LocationPopup = ({ cardRef }) => {
    const t = useTranslation('SensorStatus');
    const { floorList } = useSelector(state => state.FloorInfo);

    const { locationPopup, selectedSensor, floorId } = useContext(SensorStatusStateContext);
    const dispatch = useContext(SensorStatusDispatchContext);
    const [floorInfo, setFloorInfo] = useState(null);

    const mapRef = useRef();

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        width: 0,
        height: 0,
    });

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = -widgetPosition.height + 60;
        setPosition({
            x: x,
            y: y,
        });
    }, [popupSize]);

    useEffect(() => {
        if (floorId) {
            setFloorInfo(floorList.find(floorInfo => floorInfo.floorId === floorId));
        }
    }, [floorId]);

    useEffect(() => {
        if (mapRef && mapRef.current) {
            const map = mapRef.current.leafletElement;

            map.setView([selectedSensor.lat, selectedSensor.lng]);
        }
    }, [mapRef.current]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (cardRef.current) {
            const searchContainer = cardRef.current;
            const { offsetWidth: width, offsetHeight: height } = searchContainer;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    return (
        <Draggable key={JSON.stringify(position)} bounds=".sensor-status-container" defaultPosition={position}>
            <Toast isOpen={locationPopup} className={'popup-open'} innerRef={popupRef}>
                <ToastHeader toggle={() => dispatch(setLocationPopup(!locationPopup))}>{t('Location')}</ToastHeader>
                <ToastBody className={'h-100'}>
                    <div className={'flx-row row-space-around mb-2'}>
                        <div>
                            {t('Lat')}/{t('Lng')}
                        </div>
                        <div className={'flx-row'}>
                            <TextInput name={'lat'} value={selectedSensor.lat} disabled />
                            <span> / </span>
                            <TextInput name={'lng'} value={selectedSensor.lng} disabled />
                        </div>
                    </div>
                    <Map className={'p-0 h-90'} ref={mapRef}>
                        {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                            <RotatedImageOverlay
                                key={floorInfo.floorId}
                                url={floorInfo.imgURL}
                                deg={floorInfo.deg}
                                bounds={floorInfo.bounds}
                            />
                        )}
                        <Marker
                            key={selectedSensor.targetNum}
                            position={[selectedSensor.lat, selectedSensor.lng]}
                            icon={L.divIcon({
                                className: 'category-marker',
                                html: `<div class="marker-img replace-img"><div></div></div>`,
                            })}
                        />
                    </Map>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default React.memo(LocationPopup);
