import React from 'react';

const AppFooter = () => {
    return (
        <>
            <footer className="app-footer">
                <div className="footer-wrap">Worker Safety Management System</div>
            </footer>
        </>
    );
};
export default AppFooter;
