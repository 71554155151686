import React, { useReducer, useRef, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import iotItemStatusReducer, { clearIotItemStatusWidget, initialState, setSearchInput } from './iotItemStatusReducer';
import GridLayoutCard from '../Components/GridLayoutCard';
import FilterSearchGroup from '../../MainPages/Components/FilterSearchGroup';
import { Button, TextInput } from '../../Common';
import {
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../MainPages/Components/FilterSearchGroup/Components/Part';
import IotItemStatusCount from './Components/IotItemStatusCount';
import IotItemStatusList from './Components/IotItemStatusList';
import CategorySingleTreeSelect from './Components/CategorySingleTreeSelect';
import FloorTreeSelect from '../Components/FloorTreeSelect';
import { useSelector } from 'react-redux';
import { useSettings } from '../util/useSettings';
import SearchGroup from '../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';

export const IotItemStatusStateContext = React.createContext();
export const IotItemStatusDispatchContext = React.createContext();

const IotItemStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('IotItemStatus');
    const [state, dispatch] = useReducer(iotItemStatusReducer, initialState);
    const { floorList } = useSelector(state => state.FloorInfo);

    const widgetRef = useRef();
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const { iotItemCategoryOptions } = state;

    const [showSearchArea, setShowSearchArea] = useState(false);
    const [input, setInput] = useState({
        target: '',
        floorIds: '',
        categoryCode: '',
    });

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleSearchInputChange = e => {
        const { name, value } = e.target;
        setInput(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleSearchButtonClick = () => {
        dispatch(setSearchInput({ ...input }));
        setShowSearchArea(!showSearchArea);
    };

    const handleRefreshClick = () => {
        dispatch(clearIotItemStatusWidget());
        setInput({
            target: '',
            floorIds: '',
            categoryCode: '',
        });
    };

    return (
        <IotItemStatusDispatchContext.Provider value={dispatch}>
            <IotItemStatusStateContext.Provider value={state}>
                <GridLayoutCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    subTitle={t(settings.categoryCode)}
                    headerAction={
                        <>
                            <Button
                                className="btn-danger btn-icon-only"
                                iconClassName={'icon-refresh'}
                                onClick={handleRefreshClick}
                            />
                            <div className="pnt-border border-h" />
                            <Button className={'btn-secondary'} onClick={handleSearchAreaClick}>
                                {t('Search', 'Button')}
                            </Button>
                        </>
                    }
                    searchFilter={
                        showSearchArea && (
                            <>
                                <FilterSearchGroup className={'card absolute-filter gap-2'}>
                                    <SearchGroup className={'bg-brand'} label={t('Category', 'CommonColumn')}>
                                        <SelectGroup className={'w-100'}>
                                            <CategorySingleTreeSelect
                                                filteredOptions={iotItemCategoryOptions}
                                                onChange={selected => {
                                                    setInput(prev => {
                                                        return { ...prev, categoryCode: selected.categoryCode };
                                                    });
                                                }}
                                            />
                                        </SelectGroup>
                                    </SearchGroup>
                                    <SearchGroup className={'bg-brand'} label={t('Floor', 'CommonColumn')}>
                                        <SelectGroup className={'w-100'}>
                                            <FloorTreeSelect
                                                floorList={floorList}
                                                selectedFloorStr={input.floorIds}
                                                mode={'radioSelect'}
                                                handleChange={selected => {
                                                    setInput(prev => {
                                                        return {
                                                            ...prev,
                                                            floorIds: selected[0].floorId,
                                                        };
                                                    });
                                                }}
                                            />
                                        </SelectGroup>
                                    </SearchGroup>
                                    <SearchGroup className={'bg-brand'} label={t('Target')}>
                                        <SearchWrap className={'w-100'}>
                                            <InputWrap>
                                                <InputGroup>
                                                    <TextInput
                                                        type={'text'}
                                                        placeholder={t('Enter the IoT item name Or ID')}
                                                        name={'target'}
                                                        value={input.target}
                                                        handleChange={handleSearchInputChange}
                                                    />
                                                </InputGroup>
                                                <Button
                                                    className={'btn-brand btn-icon'}
                                                    iconClassName={'icon-search'}
                                                    onClick={handleSearchButtonClick}
                                                >
                                                    {t('Search', 'Search')}
                                                </Button>
                                            </InputWrap>
                                        </SearchWrap>
                                    </SearchGroup>
                                </FilterSearchGroup>
                            </>
                        )
                    }
                    {...restProps}
                >
                    <IotItemStatusCount widgetInfo={widgetInfo} />
                    <IotItemStatusList widgetInfo={widgetInfo} />
                    {children}
                </GridLayoutCard>
            </IotItemStatusStateContext.Provider>
        </IotItemStatusDispatchContext.Provider>
    );
};

export default IotItemStatus;
