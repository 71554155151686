import React, { useCallback } from 'react';

import ConfirmModal from '@components/ConfirmModal';
import useTranslation from '@hooks/useTranslation';
import useSocket from '@util/socket/hooks/useSocket';

import { useDispatch } from 'react-redux';
import { logOut } from '@reducer/UserInfo';

/**
 * @param {boolean} initModal 모달 여부 상태
 * @param {function} toggleModal 모달 열림닫힘 함수
 *
 * @author created bu 정진범 2022-12-08
 * */
const LogoutModal = ({ initModal, toggleModal }) => {
    const t = useTranslation('ConfirmModal');
    const dispatch = useDispatch();
    const { socket, closeSocket } = useSocket();

    const handleLogout = useCallback(() => {
        closeSocket();
        dispatch(logOut());
    }, [socket]);
    return (
        <ConfirmModal
            header={{ title: t('Logout') }}
            confirmText={t('Do you want to log out?')}
            initModal={initModal}
            toggleModal={toggleModal}
            okCallback={handleLogout}
        />
    );
};

export default LogoutModal;
