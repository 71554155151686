import React from 'react';
import CountUp from 'react-countup';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';
import { GoArrowRight, GoArrowDown } from 'react-icons/go';

const ItemCount = ({ start = 0, end = 0, label, onClick, selectedIotItemStatus, parentCategory }) => {
    const t = useTranslation('IotItemStatus');
    const dataLabel = {
        totalCount: {
            value: null,
            label: parentCategory === 'ASSET' ? 'Registered Asset' : 'Registered People',
        },
        restrictedCount: { value: 'isUnPermitted', label: 'Out of Authorized Zone' },
        lostSignalCount: { value: 'isLostSignal', label: 'Lost Signal' },
        lowBatteryCount: { value: 'isLowBattery', label: 'Low Battery' },
        neverDetectedCount: { value: 'isNeverDetected', label: 'No Response' },
    };
    return (
        <div className={'flex-center'} style={{ flexDirection: 'column', height: '100%' }}>
            <CountUp
                className={'font-weight-bold'}
                style={{ fontSize: '2.5rem' }}
                start={start}
                end={end}
                separator=""
                decimals={0}
                decimal=","
                prefix=""
                useEasing={false}
                suffix=""
                duration="1"
            />
            <h6
                className={cx(
                    'mt-2 color-darkgray status-count-container',
                    selectedIotItemStatus === dataLabel[label].value && 'bg-secondary color-white',
                )}
                onClick={() => onClick(dataLabel[label].value)}
            >
                {t(dataLabel[label].label)}
                {selectedIotItemStatus === dataLabel[label].value ? (
                    <GoArrowDown className={'ml-1'} />
                ) : (
                    <GoArrowRight className={'ml-1'} />
                )}
            </h6>
        </div>
    );
};

export default ItemCount;
