import React, { useState, useReducer, ChangeEvent, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { Card, Modal } from '@components';
import { useAppSelector, useAsync, useModal, useTranslation } from '@hooks';

import {
    getEntrantsApi,
    getRentalPresentApi,
    getEntrantApi,
    postRentalApi,
    patchRentalApi,
    postEntrantsApi,
    patchEntrantsAPi,
} from '@api/tel';

import Search from './Components/Search';
import Banner from './Components/Banner';
import PassCard from './Components/PassCard';

import RegisterPass from '../../Components/Pass/RegisterPass';
import ViewPass from '../../Components/Pass/ViewPass';
import ChangePass from '../../Components/Pass/ChangePass';

import type { Occupant, OccupantWithRental } from '../../RealtimeStatus/ListOfOccupant/types';

import {
    MIN_LAYOUT_COUNT,
    makeSkeleton,
    isSkeleton,
    INITIAL_PERSONAL_PASS_STATE,
    personalPassReducer,
    PASS,
    createActionHandlers,
    makeAutoCompleteRow,
} from './utils';

import type { Nullable, SuccessResponse, PageInfo, RealtimeTable } from '@util/type/util';

const INITIAL_ACCESS_CARD_NUMBER = 0;

export type RentalResponse = Pick<OccupantWithRental, 'category' | 'entrant' | 'rental' | 'properties'>;
export type OccupantCard = { onBoard?: boolean } & RentalResponse;

export type AutoCompleteRow = {
    label: string;
} & OccupantCard;

const Management = () => {
    const t = useTranslation('Visitor');

    const { rental } = useAppSelector(state => state.WorkerSafety);

    const [passCards, setPassCards] = useState<OccupantCard[]>([]);
    const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteRow[]>([]);

    useEffect(() => {
        if (!rental) return;
        const { data, command } = rental;

        if (command === 'CREATED') {
            setAutoCompleteList(prevState => [makeAutoCompleteRow(data), ...prevState]);
            return;
        }
        setAutoCompleteList(prevState =>
            prevState.map(row =>
                row.entrant.entrantNum === data.entrant.entrantNum ? makeAutoCompleteRow(data) : row,
            ),
        );

        if (command === 'UNLINKED' || !data.onBoard) {
            setPassCards(prevState => {
                return prevState.filter(card => card.entrant.entrantNum !== data.entrant.entrantNum);
            });
            return;
        }

        if (data.onBoard && !data.rental.rentalNum) return;

        const isExisted = !!passCards.find(row => row.rental.beaconNum === data.rental.beaconNum);

        if (command === 'MODIFIED' && isExisted) {
            setPassCards(prevState => {
                return prevState.map(row => {
                    if (row.rental.beaconNum === data.rental.beaconNum) {
                        return data;
                    }
                    return row;
                });
            });

            return;
        }

        setPassCards(prevState => {
            const updatedRows = [...prevState];

            const insertIndex = updatedRows.findIndex(row => row.rental.beaconNum > data.rental.beaconNum);

            if (insertIndex === -1) {
                updatedRows.push(data);
            } else {
                updatedRows.splice(insertIndex, 0, data);
            }

            return updatedRows;
        });
    }, [rental]);

    const [modalState, dispatch] = useReducer(personalPassReducer, INITIAL_PERSONAL_PASS_STATE);

    const [selectedVisitor, setSelectedVisitor] = useState<Nullable<RentalResponse>>(null);

    const [beaconNum, setBeaconNum] = useState<number>(INITIAL_ACCESS_CARD_NUMBER);

    const actionHandlers = createActionHandlers(dispatch);

    useEffect(() => {
        setBeaconNum(selectedVisitor?.rental?.beaconNum || INITIAL_ACCESS_CARD_NUMBER);
    }, [selectedVisitor]);

    const { promise: getEntrants } = useAsync({
        promise: getEntrantsApi,
        fixedParam: {
            isAll: 'Y',
        },
        resolve: (res: PageInfo<RentalResponse>) => {
            const { rows } = res;
            setAutoCompleteList(rows.map(rentalRes => makeAutoCompleteRow(rentalRes)));
        },
        reject: (err: Error) => {
            console.error(err);
            setAutoCompleteList([]);
        },
        immediate: true,
    });

    useAsync({
        promise: getRentalPresentApi,
        resolve: (res: RealtimeTable<OccupantCard>) => {
            setPassCards(res.rows);
        },
        reject: (err: Error) => {
            console.error(err);
            setPassCards([]);
        },
        immediate: true,
    });

    const { promise: createEntrants } = useAsync({
        promise: postEntrantsApi,
        resolve: (res: any) => {
            actionHandlers.closePersonalPass();
            actionHandlers.openViewPass();
            getEntrant({
                entrantNum: res.entrant?.entrantNum,
            });
        },
        reject: (err: Error) => console.error(err),
    });

    const handleSelectedVisitor = (visitor: Nullable<RentalResponse>) => {
        setSelectedVisitor(visitor);
        if (visitor) {
            actionHandlers.openViewPass();
        }
    };

    const [isRentalModalOpen, openRentalModal, toggleRentalModal] = useModal();
    const [isReturnModalOpen, openReturnModal, toggleReturnModal] = useModal();
    const [isOverlapModalOpen, openOverlapModal, toggleOverlapModal] = useModal();

    const { promise: postRental } = useAsync({
        promise: postRentalApi,
        resolve: (res: SuccessResponse) => {
            getEntrant({
                entrantNum: selectedVisitor?.entrant.entrantNum,
            });
        },
        reject: (err: Error) => {
            console.error(err);
            openOverlapModal();
        },
    });

    const { promise: patchRental } = useAsync({
        promise: patchRentalApi,
        resolve: (res: SuccessResponse) => {
            getEntrant({
                entrantNum: selectedVisitor?.entrant.entrantNum,
            });
        },
        reject: (err: Error) => {
            console.error(err);
        },
    });

    const handleToggleSwitch = (visitor: RentalResponse) => {
        const { rental } = visitor;
        const isActivated = !!rental.beaconNum;

        if (isActivated) {
            openReturnModal();
        }

        if (!isActivated) {
            openRentalModal();
        }
    };

    const handleRental = () => {
        postRental({
            beaconNum,
            entrantNum: selectedVisitor?.entrant.entrantNum,
        });
    };

    const handleReturn = () => {
        if (!selectedVisitor?.rental?.rentalNum) return;

        patchRental({
            rentalNum: selectedVisitor.rental.rentalNum,
        });
    };

    const { promise: getEntrant } = useAsync({
        promise: getEntrantApi,
        resolve: (res: RentalResponse) => {
            setSelectedVisitor(res);
            actionHandlers.openViewPass();
        },
        reject: (err: Error) => {
            console.error(err);
            setSelectedVisitor(null);
        },
    });

    const handlePassCardClick = (entrantNum: Occupant['entrant']['entrantNum']) => {
        getEntrant({
            entrantNum,
        });
    };

    const handleAccessCardNumber = (event: ChangeEvent<HTMLInputElement>) => {
        setBeaconNum(Number(event.target.value));
    };

    const { promise: updateEntrants } = useAsync({
        promise: patchEntrantsAPi,
        resolve: (res: SuccessResponse) => {
            getEntrant({
                entrantNum: selectedVisitor?.entrant.entrantNum,
            });
            actionHandlers.openViewPass();
        },
        reject: (err: Error) => {
            console.error(err);

            setSelectedVisitor(null);
            actionHandlers.closeAll();
        },
    });

    const filteredRows = passCards.filter(card => card.onBoard);
    const viewedPassCards = [
        ...filteredRows,
        ...Array.from({ length: MIN_LAYOUT_COUNT - filteredRows.length }, () => makeSkeleton()),
    ];

    return (
        <div className="d-flex gap-2 h-100">
            <Card className="main-card-height w-100">
                <Search
                    list={autoCompleteList}
                    getEntrantsAggregated={getEntrants}
                    handleSelectedVisitor={handleSelectedVisitor}
                    dispatch={dispatch}
                />
                <div className="search-table">
                    <div className="d-flex flex-column gap-2">
                        <Banner
                            totalCount={filteredRows.length}
                            handleOpenPersonalPass={actionHandlers.openPersonalPass}
                        />
                        <div className="d-flex flex-wrap flex-grow-1 py-1">
                            {viewedPassCards.map((cardInfo, idx) => {
                                if (isSkeleton(cardInfo)) {
                                    return <PassCard key={'s_' + idx} />;
                                }
                                const { category, rental, entrant } = cardInfo;
                                return (
                                    <PassCard
                                        key={entrant.entrantNum}
                                        labelColor={category.markerColor}
                                        passNumber={rental.beaconNum}
                                        name={entrant.entrantName}
                                        entrantNum={entrant.entrantNum}
                                        handleClick={handlePassCardClick}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Card>
            {modalState[PASS.REGISTER] && (
                <RegisterPass createEntrants={createEntrants} handleClosePass={actionHandlers.closePersonalPass} />
            )}
            {modalState[PASS.VIEW] && selectedVisitor && (
                <ViewPass
                    visitor={selectedVisitor}
                    handleClosePass={() => {
                        actionHandlers.closeViewPass();
                        setSelectedVisitor(null);
                    }}
                    handleOpenChangePass={actionHandlers.openChangePass}
                    handleToggleSwitch={handleToggleSwitch}
                    handleAccessCardNumber={handleAccessCardNumber}
                    accessCardNumber={beaconNum}
                />
            )}
            {modalState[PASS.CHANGE] && selectedVisitor && (
                <ChangePass
                    handleClosePass={actionHandlers.openViewPass}
                    updateEntrants={updateEntrants}
                    visitor={selectedVisitor}
                />
            )}
            <Modal
                initModal={isRentalModalOpen}
                toggleModal={toggleRentalModal}
                headerTitle={t('Rental Access Pass')}
                bodyText={
                    <Trans t={t} values={{ passNumber: beaconNum }}>
                        {'Would you like to rent a pass number {{passNumber}}?'}
                    </Trans>
                }
                okCallback={handleRental}
            />
            <Modal
                initModal={isReturnModalOpen}
                toggleModal={toggleReturnModal}
                headerTitle={t('End Of Use Of Access Pass')}
                bodyText={t('Would you like to return your pass?')}
                okCallback={handleReturn}
            />
            <Modal
                initModal={isOverlapModalOpen}
                toggleModal={toggleOverlapModal}
                headerTitle={t('Alert', 'ConfirmModal')}
                bodyText={t('This is a pass that has already been rented or does not exist.')}
                removeCancel
            />
        </div>
    );
};

export default Management;
