import React, { FC } from 'react';

import { DatePicker } from '@components';

import Topic from './Components/Topic';

import CalendarContainer from './CalendarContainer';

import type { NamedDateSearchHandler, Nullable, UnixTimestamp } from '@util/type/util';
import { DATE } from '../util';
import styled from 'styled-components';

interface Props {
    title: string;
    startDate: Nullable<UnixTimestamp>;
    endDate: Nullable<UnixTimestamp>;
    namedDateSearchHandler: NamedDateSearchHandler;
    disabled?: boolean;
}

const FormSelectDuration: FC<Props> = ({ title, startDate, endDate, namedDateSearchHandler, disabled }) => {
    return (
        <Topic title={title}>
            <DatepickerWrapper>
                <DatePicker
                    popperPlacement="auto"
                    popperContainer={CalendarContainer}
                    value={startDate}
                    handleChange={namedDateSearchHandler(DATE.START_DATE)}
                    maxDate={endDate || undefined}
                    valueType="ms"
                    disabled={disabled}
                    withoutTime
                    isClearable
                />
            </DatepickerWrapper>
            <div className="text-center"> ~ </div>
            <DatepickerWrapper>
                <DatePicker
                    popperPlacement="auto"
                    popperContainer={CalendarContainer}
                    value={endDate}
                    handleChange={namedDateSearchHandler(DATE.END_DATE)}
                    minDate={startDate || undefined}
                    valueType="ms"
                    disabled={disabled}
                    withoutTime
                    isClearable
                />
            </DatepickerWrapper>
        </Topic>
    );
};

const DatepickerWrapper = styled.div`
    .pnt-datepicker-container {
        margin: 0;
    }

    .react-datepicker__close-icon::after {
        margin-right: 5px;
        margin-bottom: 5px;
    }
`;
export default FormSelectDuration;
