import React from 'react';
import { Modal as RSModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DefaultModalProps } from '@components/type';
import cx from 'classnames';
import Button from '@components/Button';
import useTranslation from '@util/hooks/useTranslation';

export interface CommonModalProps extends DefaultModalProps {
    headerClassName?: string;
    bodyClassName?: string;
    footerClassName?: string;
    headerTitle?: React.ReactNode;
    bodyText?: React.ReactNode;
    modalHeader?: React.ReactNode;
    modalFooter?: React.ReactNode;
    okValidation?: boolean;
    modalClose?: React.ReactNode;
    removeModalClose?: boolean;
}

const Modal = ({
    className,
    headerClassName,
    bodyClassName,
    footerClassName,
    headerTitle,
    bodyText,
    modalHeader,
    modalFooter,
    initModal = false,
    toggleModal = function () {},
    okCallback,
    okValidation = true,
    callbackParam,
    cancelCallback,
    removeCancel,
    container,
    backdrop = 'static',
    keyboard = false,
    centered = false,
    size = '',
    scrollable = false,
    external,
    onOpened,
    onClosed,
    wrapClassName,
    modalClassName,
    backdropClassName,
    contentClassName,
    modalClose,
    removeModalClose = true,
    children,
    ...restProps
}: CommonModalProps) => {
    const t = useTranslation('ConfirmModal');

    // cancel 버튼 & header X 버튼 callback
    const handleCancel = () => {
        if (typeof cancelCallback === 'function') {
            cancelCallback(callbackParam);
        }
        toggleModal();
    };
    return (
        <RSModal
            backdrop={backdrop}
            container={container ?? (document.getElementsByClassName('app-container')[0] as HTMLElement)}
            className={cx('pnt-modal', className, centered && 'shadow-none')}
            isOpen={initModal}
            toggle={toggleModal}
            keyboard={keyboard}
            centered={centered}
            size={size}
            scrollable={scrollable}
            external={external}
            onOpened={onOpened}
            onClosed={onClosed}
            wrapClassName={cx(wrapClassName)}
            modalClassName={cx(modalClassName)}
            backdropClassName={cx(backdropClassName)}
            contentClassName={cx(contentClassName)}
            {...restProps}
        >
            {modalHeader || (
                <ModalHeader
                    // className={cx(headerClassName, 'bg-depth-4')}
                    // 세종 디자인 적용
                    className={cx(headerClassName, 'modal-body font-weight-bold')}
                    close={removeModalClose ? <></> : modalClose || null}
                    toggle={handleCancel}
                >
                    {headerTitle}
                </ModalHeader>
            )}
            {children || <ModalBody className={bodyClassName}>{bodyText}</ModalBody>}
            {modalFooter || (
                <ModalFooter className={footerClassName}>
                    {!removeCancel && (
                        <Button className={'btn-gray'} onClick={handleCancel}>
                            {t('Cancel')}
                        </Button>
                    )}

                    <Button
                        className={'btn-secondary'}
                        onClick={e => {
                            if (typeof okCallback === 'function') {
                                okCallback(callbackParam);
                            }
                            if (okValidation) {
                                toggleModal();
                            }
                        }}
                    >
                        {t('OK')}
                    </Button>
                </ModalFooter>
            )}
            {/*
                modal react-select portal
                modal의 경우 app-main__inner 태그 밖에 생성되므로
                select menu portal 대상을 내부에서 정의해야 함
            */}
            <div id="modal-select-container" />
        </RSModal>
    );
};

export default Modal;
