import React, { useEffect, useRef, useState } from 'react';

import { Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import EVT_TYPE from '@util/staticData/eventType';
import { useAsync, useTranslation } from '@hooks';
import { fetchAlertInfo, fetchNumOfUnreadAlert } from '@api/alert';
import TimeLine from '@components/TimeLine';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import TIMES from '@util/times';

const WrappedAlertBody = styled.div`
    margin-left: 1rem;
    height: 300px;
`;

const SeeMoreBtn = styled.button`
    width: 120px;
    height: 35px;
    color: white;
    background-color: #6e6c96;
    border-radius: 20px;
    outline: none;
    border: none;
    padding: 0.5rem;
`;

const getEvtType = evtTypeMap => {
    const evtTypes = [];
    for (let type in evtTypeMap) {
        evtTypes.push({
            type: type,
            name: evtTypeMap[type],
            typeClassName: '', // isDanger(type) ? 'text-danger' : 'text-primary',
        });
    }
    return evtTypes;
};

const COUNT_TIME = TIMES.SEC_5;
const END_OF_LIST = -1;

const NotificationDots = () => {
    const t = useTranslation('Alert');
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [lastKeyNum, setLastKeyNum] = useState();
    const [clickBtn, setClickBtn] = useState(false);
    const [showArrow, setShowArrow] = useState(false);

    const eventType = getEvtType(EVT_TYPE);

    const [count, setCount] = useState(0);
    const { promise: getUnreadNotificationCount } = useAsync({
        promise: fetchNumOfUnreadAlert,
        keepState: true,
        resolve: res => {
            const { count } = res;
            setCount(count);
        },
        reject: error => console.error(error),
    });

    const { promise: getNotificationLogs } = useAsync({
        promise: fetchAlertInfo,
        fixedParam: { isAlertCheckStatus: 'Y', firstKeyNum: lastKeyNum },
        resolve: res => {
            if (clickBtn && res.rows.length > 0) {
                setShowArrow(true);
                setClickBtn(false);
            }
            setLastKeyNum(res.lastKeyNum);
            setNotificationList(notificationList.concat(makeNotificationList(res.rows)));
        },
        reject: error => console.error(error),
    });

    const makeNotificationList = notificationList => {
        return notificationList.reduce((acc, alert) => {
            let evtType = eventType.find(type => type.type === alert.interfaceCommandType);
            if (evtType) {
                const day = alert.regDate ? moment.unix(alert.regDate).format('YYYY.MM.DD') : '-';
                let time = alert.regDate ? moment.unix(alert.regDate).format('HH:mm') : '-';
                let hour = alert.regDate ? moment.unix(alert.regDate).format('HH') : '-';
                if (alert.regDate) {
                    hour >= 12 && hour <= 23 ? (time = `${time} PM`) : (time = `${time} AM`);
                }

                acc.push({
                    level: 'warning',
                    type: t(evtType.name, 'Alarm'),
                    typeClassName: evtType.typeClassName,
                    day,
                    dateStr: time,
                    targetName: alert.targetName,
                    location: alert.floorName,
                    targetNum: alert.targetNum,
                    interfaceCommandType: alert.interfaceCommandType,
                    latLng: [alert.lat, alert.lng],
                    floorId: alert.floorId,
                });
            }
            return acc;
        }, []);
    };

    const handleClick = e => {
        e.stopPropagation();
        if (lastKeyNum !== -1) {
            getNotificationLogs({ isCheck: 'Y', pageSize: 10 });
        }
        setClickBtn(true);
    };

    const handleScrollEvent = event => {
        if (event) {
            setShowArrow(false);
        }
    };

    const click = () => {
        setNotificationList([]);
        setCount(0);

        if (!open) {
            getNotificationLogs({
                isCheck: 'Y',
                pageSize: 10,
            });
        } else {
            setLastKeyNum(null);
        }
        setOpen(!open);
    };

    const countIntvRef = useRef();
    const clearCountIntv = () => {
        clearInterval(countIntvRef.current);
        countIntvRef.current = null;
    };

    useEffect(() => {
        if (countIntvRef.current) {
            clearCountIntv();
        }
        getUnreadNotificationCount();
        countIntvRef.current = setInterval(() => {
            getUnreadNotificationCount();
        }, COUNT_TIME);
        return () => {
            clearCountIntv();
        };
    }, []);

    useEffect(() => {
        setOpen(false);
    }, [location]);

    return (
        <div className="header-dots alarm-box">
            <Dropdown isOpen={open} toggle={click}>
                <DropdownToggle
                    className="pnt-btn btn-icon-only btn-trans d-flex border-0 overflow-visible text-decoration-none"
                    color="link"
                    style={{ width: '28px', height: '28px', minWidth: '28px' }}
                >
                    <span className="material-icons-round">notifications</span>
                    {!!count && <div className="alarm-num">{count > 999 ? '999+' : count}</div>}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xl rm-pointers bg-depth-base">
                    <div className="dropdown-menu-header mb-0">
                        <div className="dropdown-menu-header-inner" style={{ backgroundColor: '#453e6f' }}>
                            {/*<div className="menu-header-image opacity-1" style={{ backgroundImage: `url(${city3})` }} />*/}
                            <div className="menu-header-content ">
                                <h5 className="menu-header-title text-white">{t('Alerts')}</h5>
                            </div>
                        </div>
                    </div>
                    <WrappedAlertBody onScroll={handleScrollEvent}>
                        <TimeLine events={notificationList} />
                        {showArrow && (
                            <span
                                className="material-icons-round"
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 80,
                                    color: '#bdbebf',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                }}
                            >
                                arrow_downward
                            </span>
                        )}
                    </WrappedAlertBody>
                    {lastKeyNum !== END_OF_LIST && (
                        <Nav vertical>
                            <NavItem className="nav-item-divider" />
                            <NavItem className="nav-item-btn text-center">
                                <SeeMoreBtn
                                    className="btn-shadow btn-wide btn-pill"
                                    color="focus"
                                    onClick={handleClick}
                                >
                                    {t('See more')}
                                </SeeMoreBtn>
                            </NavItem>
                        </Nav>
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default NotificationDots;
