import React, { useEffect, useState } from 'react';
import { CardHeaderProps, CardProps } from '../type';
import { Card as RSCard, CardBody, CardFooter, CardHeader, CardText, CardTitle } from 'reactstrap';
import Button from '../Button';

const DEFAULT_HEADER_PROPS: CardHeaderProps = {
    action: undefined,
    className: '',
    subTitle: '',
    title: '',
    titleIcon: '',
    titleIconClassName: 'material-icons-round',
};

const Card = ({
    className,
    bodyClassName,
    header = DEFAULT_HEADER_PROPS,
    footerTitle,
    overlayOn: on = false,
    overlay = { title: '' },
    children,
    innerRef,
    footerClassName,
}: CardProps): JSX.Element => {
    const { title, subTitle, action, titleIcon, titleIconClassName = 'material-icons-round' } = header;
    const { title: overlayTitle, action: overlayAction, content } = overlay;
    const [overlayOn, setOverlayOn] = useState(on);

    useEffect(() => {
        return () => {
            // state clear
            setOverlayOn(false);
        };
    }, []);

    return (
        <>
            <RSCard className={className} innerRef={innerRef}>
                {!!title && (
                    <CardHeader>
                        <div className={'card-header__title'}>
                            {titleIcon && <span className={`${titleIconClassName}`}>{titleIcon}</span>}
                            <div className="title-wrap">
                                <CardTitle tag={'h3'} className={'title__main'}>
                                    {title}
                                </CardTitle>
                                {subTitle && <p className={'title__sub'}>{subTitle}</p>}
                            </div>
                        </div>

                        {(!!action || !!overlayTitle) && (
                            <div className={'card-header__function'}>
                                {action}
                                {!!overlayTitle && (
                                    <Button
                                        className={'btn-icon-only btn-trans card-overwrap-btn'}
                                        onClick={e => setOverlayOn(!overlayOn)}
                                        iconName="info"
                                    />
                                )}
                            </div>
                        )}
                    </CardHeader>
                )}

                <CardBody className={bodyClassName}>{children}</CardBody>

                {footerTitle && <CardFooter className={footerClassName}>{footerTitle}</CardFooter>}

                {overlayOn && (
                    <div className={`card-overlay on`}>
                        <RSCard>
                            <CardHeader>
                                <div className="card-header__title">
                                    <CardTitle tag={'h3'} className={'title__main'}>
                                        {overlayTitle}
                                    </CardTitle>
                                </div>
                                <div className="card-header__function">
                                    {overlayAction}
                                    <Button
                                        className={'btn-trans btn-icon-only btn-small p-0 card-overwrap-close-btn'}
                                        onClick={e => setOverlayOn(!overlayOn)}
                                        iconName="close"
                                    />
                                </div>
                            </CardHeader>

                            <CardBody>{content}</CardBody>
                        </RSCard>
                    </div>
                )}
            </RSCard>
        </>
    );
};

export default Card;
