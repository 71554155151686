import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTranslation, useAppSelector, useModal } from '@hooks';
import { Button, Card, ConfirmModal } from '@components';

import Form from './Form';
import { useInputValues } from './hooks';
import {
    ACCESS_PASS_INPUTS,
    VISITOR_INPUTS,
    AFFILIATION_INPUTS,
    COMBINED_INPUTS,
    INITIAL_SELECTED_VALUES,
    SELECT_TYPES,
    cleanFalsyToNull,
    checkRequireInput,
    UNSELECTED,
} from './util';

import { translateValue, translateOptions } from '@util/common/util';

import type { SelectedValues, HandleSelect } from './types';
import type { RentalResponse } from '../../DispatchingManagement/Management';

interface Props {
    visitor: RentalResponse;
    handleClosePass: () => void;
    updateEntrants: (param: Object) => void;
}

const ChangePass: FC<Props> = ({ visitor, handleClosePass, updateEntrants }) => {
    const t = useTranslation('Visitor');
    const cT = useTranslation('Translate Choose');

    const { rental } = visitor;

    const { createdCategoryList } = useAppSelector(state => state.WorkerSafety);

    const { inputValues, handleInput, setInputValues } = useInputValues(COMBINED_INPUTS);

    const [selectedValues, setSelectedValues] = useState<SelectedValues>(INITIAL_SELECTED_VALUES);

    useEffect(() => {
        if (!visitor) return;

        const { category, properties, entrant } = visitor;

        setInputValues({
            entrantName: entrant.entrantName,
            cpName: properties.cpName,
            identifier: properties.identifier,
            department: properties.department,
            designation: properties.designation,
        });

        if (category) {
            const { categoryCode, categoryName } = category;
            setSelectedValues(prevState => ({
                ...prevState,
                [SELECT_TYPES.AFFILIATION]: { label: categoryName, value: categoryCode },
            }));
        }
    }, [visitor]);

    const handleSelect: HandleSelect = (selected, actionMeta) => {
        const { name } = actionMeta;
        if (!name || !selected) return;

        setSelectedValues(prevState => ({
            ...prevState,
            [name]: selected,
        }));
    };

    const [isValidationModalOpen, openValidationModal, toggleValidationModal] = useModal();
    const handleChangeClick = () => {
        // input 중 require 인 로직에 값이 있는지 여부 판단
        const isInputValidationPass = checkRequireInput(inputValues);

        const isSelectValidationPass = !!selectedValues[SELECT_TYPES.AFFILIATION].value;

        if (!isInputValidationPass || !isSelectValidationPass) {
            toggleValidationModal();
            return;
        }

        updateEntrants(
            cleanFalsyToNull({
                ...inputValues,
                categoryCode: selectedValues[SELECT_TYPES.AFFILIATION].value,
                enterReasonCode: selectedValues[SELECT_TYPES.PURPOSE_TO_ENTRY].value,
                entrantNum: visitor.entrant.entrantNum,
            }),
        );
    };

    return (
        <ChangePassCard
            header={{
                titleIconClassName: 'material-icons-round md-20 filter-grayscale-80',
                titleIcon: 'edit',
                title: t('Modification Of Visitors'),
            }}
            bodyClassName="d-flex flex-column gap-4"
            footerClassName="gap-1"
            footerTitle={
                <>
                    <Button className="btn-gray" onClick={handleClosePass}>
                        {t('Cancel', 'Button')}
                    </Button>
                    <Button
                        iconName="check"
                        iconClassName="material-icons-round md-16"
                        className="btn-brand flx-1"
                        onClick={handleChangeClick}
                    >
                        {t('Save Modifications')}
                    </Button>
                </>
            }
        >
            <Form title={t('Access Card Information')}>
                {ACCESS_PASS_INPUTS.map(({ key, title, require, disabled, valueFunc }) => {
                    return (
                        <Form.Input
                            key={key}
                            formMust={require}
                            title={t(title)}
                            name={key}
                            disabled={disabled}
                            value={valueFunc(rental[key])}
                        />
                    );
                })}
            </Form>
            <Form title={t('Personal Information')}>
                {VISITOR_INPUTS.map(({ key, title, require, disabled }) => {
                    return (
                        <Form.Input
                            key={key}
                            formMust={require}
                            title={t(title)}
                            name={key}
                            value={inputValues[key]}
                            handleInput={handleInput}
                            disabled={disabled}
                        />
                    );
                })}
            </Form>
            <Form title={t('Affiliation')}>
                <Form.Select
                    title={t(SELECT_TYPES.AFFILIATION)}
                    formMust
                    name={SELECT_TYPES.AFFILIATION}
                    value={translateValue(selectedValues[SELECT_TYPES.AFFILIATION], cT)}
                    options={translateOptions(
                        [
                            UNSELECTED,
                            ...createdCategoryList.map(({ categoryName, categoryCode }) => ({
                                label: categoryName,
                                value: categoryCode,
                            })),
                        ],
                        cT,
                    )}
                    handleSelect={handleSelect}
                />
                {AFFILIATION_INPUTS.map(({ key, title, require, disabled }) => {
                    return (
                        <Form.Input
                            key={key}
                            formMust={require}
                            title={t(title)}
                            name={key}
                            value={inputValues[key]}
                            handleInput={handleInput}
                            disabled={disabled}
                        />
                    );
                })}
            </Form>
            <ConfirmModal
                initModal={isValidationModalOpen}
                toggleModal={toggleValidationModal}
                header={{ title: t('Notification', 'Locker') }}
                confirmText={t(
                    'Required input is missing. Please enter and try again. (Required input value: Name/affiliated division/company name)',
                )}
                removeCancel={true}
            />
        </ChangePassCard>
    );
};

const ChangePassCard = styled(Card)`
    min-width: 25rem;
    .card-header {
        background-color: #f6f5f8 !important;
    }
`;

export default ChangePass;
