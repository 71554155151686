import React, { useEffect, useState, FC } from 'react';
import type { ActionMeta } from 'react-select';

import { useAppSelector, useTranslation } from '@hooks';
import { Select, TextInput, Button } from '@components';

import FilterSearchGroup from '../../../Components/FilterSearchGroup';
import FilterGroup from '../../../Components/FilterSearchGroup/Components/FilterGroup';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../Components/FilterSearchGroup/Components/Part';
import SearchGroup from '../../../Components/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '../../../Components/FilterSearchGroup/Components/FilterButtonArea';

import { SEARCH_PARAMS, INITIAL_SEARCH_OPTIONS, INITIAL_SEARCH_PARAMS, ALL_CATEGORIES } from '../util';
import { UNSELECTED } from '../../../util/wsUtil';
import { translateOptions, translateValue } from '@util/common/util';

import type { OptionType } from '@components/type';
import type { Nullable, OptionList, HandleKeyword } from '@util/type/util';
import type { SearchParams, Floor } from '../types';

interface Props {
    handleSearchParams: (params: SearchParams) => void;
}

const Search: FC<Props> = ({ handleSearchParams }) => {
    const t = useTranslation('Filter');

    const { createdCategoryList } = useAppSelector(state => state.WorkerSafety);
    const { floorList } = useAppSelector(state => state.FloorInfo);

    const [searchOptions, setSearchOptions] = useState<OptionList>(INITIAL_SEARCH_OPTIONS);

    const [searchParams, setSearchParams] = useState<SearchParams>(INITIAL_SEARCH_PARAMS);

    useEffect(() => {
        if (!floorList.length) return;

        const filteredFloorList = floorList.reduce<Floor[]>((acc, cur: Floor) => {
            const filteredList = acc.filter(floor => !cur.floorIdPath.includes(floor.floorIdPath));

            filteredList.push(cur);
            return filteredList;
        }, []);

        const floorOptions = filteredFloorList.map(({ floorName, floorId }: Floor) => ({
            label: floorName,
            value: floorId,
        }));

        setSearchOptions(prevState => ({
            ...prevState,
            [SEARCH_PARAMS.SEARCH_LOCATION]: [UNSELECTED, ...floorOptions],
        }));
    }, [floorList]);

    useEffect(() => {
        setSearchOptions(prevState => ({
            ...prevState,
            [SEARCH_PARAMS.COMPANY_CLASSIFICATION]: [
                ALL_CATEGORIES,
                ...createdCategoryList.map(({ categoryName, categoryCode }) => ({
                    label: categoryName,
                    value: categoryCode,
                })),
            ],
        }));
    }, [createdCategoryList]);

    const handleSelect = (selected: Nullable<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        const { name } = actionMeta;
        if (!name) return;

        setSearchParams(prevState => {
            return {
                ...prevState,
                [SEARCH_PARAMS.SEARCH_WORD]:
                    name === SEARCH_PARAMS.SEARCH_OPTION ? '' : prevState[SEARCH_PARAMS.SEARCH_WORD],
                [name]: selected,
            };
        });
    };

    const handleInput: HandleKeyword = e => {
        const { name, value } = e.target;
        setSearchParams(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSearchClick = () => {
        handleSearchParams(searchParams);
    };

    const handleRefresh = () => {
        setSearchParams(INITIAL_SEARCH_PARAMS);
        handleSearchParams(INITIAL_SEARCH_PARAMS);
    };

    return (
        <FilterSearchGroup className="p-0">
            <FilterGroup label={t('Search Location')} className="gap-2">
                <FilterList>
                    <SelectGroup>
                        <Select
                            name={SEARCH_PARAMS.SEARCH_LOCATION}
                            value={translateValue(searchParams[SEARCH_PARAMS.SEARCH_LOCATION], t)}
                            options={translateOptions(searchOptions[SEARCH_PARAMS.SEARCH_LOCATION], t)}
                            onChange={handleSelect}
                        />
                    </SelectGroup>

                    <div className="filter-label">{t('Belong to')}</div>

                    <SelectGroup>
                        <Select
                            name={SEARCH_PARAMS.COMPANY_CLASSIFICATION}
                            value={translateValue(searchParams[SEARCH_PARAMS.COMPANY_CLASSIFICATION], t)}
                            options={translateOptions(searchOptions[SEARCH_PARAMS.COMPANY_CLASSIFICATION], t)}
                            onChange={handleSelect}
                        />
                    </SelectGroup>

                    <div className="filter-label">{t('Stay Duration', 'Entry Exit Record Status')}</div>

                    <SelectGroup>
                        <Select
                            name={SEARCH_PARAMS.DURATION}
                            value={translateValue(searchParams[SEARCH_PARAMS.DURATION], t)}
                            options={translateOptions(searchOptions[SEARCH_PARAMS.DURATION], t)}
                            onChange={handleSelect}
                        />
                    </SelectGroup>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
            <SearchGroup label={t('Keyword')} className="gap-2">
                <SearchWrap>
                    <SelectGroup>
                        <Select
                            name={SEARCH_PARAMS.SEARCH_OPTION}
                            value={translateValue(searchParams[SEARCH_PARAMS.SEARCH_OPTION], t)?.value || undefined}
                            options={translateOptions(searchOptions[SEARCH_PARAMS.SEARCH_OPTION], t)}
                            onChange={handleSelect}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                name={SEARCH_PARAMS.SEARCH_WORD}
                                size={80}
                                disabled={!searchParams[SEARCH_PARAMS.SEARCH_OPTION].value}
                                placeholder={t('Please select search conditions first')}
                                type="text"
                                value={searchParams[SEARCH_PARAMS.SEARCH_WORD]}
                                handleChange={handleInput}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <div className="d-flex gap-2">
                    <Button className="btn-secondary" onClick={handleSearchClick}>
                        {t('Search')}
                    </Button>
                    <Button className="btn-lightgray btn-icon-only" iconName="refresh" onClick={handleRefresh} />
                </div>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
