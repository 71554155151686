import { PlayerState } from '../types';
import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SEARCH_TIME_GAP } from './utils';

type ActionPayloadUndefined = PayloadAction<undefined>;

export const initialState: PlayerState = {
    play: false,
    speed: 1,
    playTime: null,
    startTime: null,
    endTime: null,
    displayStartTime: null,
    displayEndTime: null,
    selectedTarget: null,
};

const playerSlice = createSlice({
    name: 'ws/player',
    initialState,
    reducers: {
        setDateChange: (state, action) => {
            const { startTime, endTime } = action.payload;
            state.startTime = startTime;
            state.endTime = endTime;
        },
        setPlayTime: (state, action) => {
            if (action.payload) {
                state.playTime = action.payload;
            }
        },
        setDisplayTimeRange: (state, action) => {
            if (action.payload === null) {
                const startTime = moment().subtract(SEARCH_TIME_GAP, 'minutes').startOf('minutes').valueOf();
                const endTime = moment().startOf('minutes').valueOf();
                return {
                    ...initialState,
                    playTime: startTime,
                    startTime,
                    endTime,
                    displayStartTime: null,
                    displayEndTime: null,
                };
            }

            const { startTime, endTime } = action.payload;
            return {
                ...state,
                play: initialState.play,
                speed: initialState.speed,
                playTime: startTime,
                displayStartTime: startTime,
                displayEndTime: endTime,
            };
        },
        setPlay: (state, action) => {
            const { playTime, startTime, endTime } = action.payload;
            if (playTime && playTime === endTime) {
                state.playTime = startTime;
            }
            state.play = true;
        },
        setSpeed: (state, action) => {
            state.speed = action.payload;
        },
        setPause: (state, action: ActionPayloadUndefined) => {
            state.play = false;
        },
        setForward: (state, action: ActionPayloadUndefined) => {
            state.play = false;
            state.playTime = state.endTime;
        },
        setBackward: (state, action: ActionPayloadUndefined) => {
            state.play = false;
            state.playTime = state.startTime;
        },
        setSelectedTarget: (state, action) => {
            state.selectedTarget = action.payload;
        },
    },
});

export const {
    setDateChange,
    setDisplayTimeRange,
    setPlayTime,
    setPlay,
    setSpeed,
    setPause,
    setForward,
    setBackward,
    setSelectedTarget,
} = playerSlice.actions;
export default playerSlice;
