import { Button, Card, Select } from '@components';
import { OptionType } from '@components/type';
import { useAppSelector, useTranslation } from '@util/hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import AutoCompleteInput from './AutoCompleteInput';

interface Props {
    floorOptions: OptionType[];
    selectedFloor: OptionType | null;
    onFloorChange: (floor: OptionType | null) => void;
    onSearch: (targetNum: number) => void;
    onRefreshClick: () => void;
}

const SearchCard = ({ onFloorChange, onSearch, floorOptions, selectedFloor, onRefreshClick }: Props) => {
    const t = useTranslation('Occupant Location');

    const [isExpanded, setIsExpanded] = useState(false);

    const { tagList } = useAppSelector(state => state.TagInfo);

    const searchOptions = Object.entries(tagList).map(([targetNum, info]: any) => {
        return {
            targetNum: Number(targetNum),
            label: `${info.target.targetName ?? '-'} / ${info.target.properties['cp-name'] ?? '-'} / ${
                info.target.properties.identifier ?? '-'
            } / ${info.target.properties.department ?? '-'} / ${info.beacon.beaconNum ?? '-'}`,
        };
    });

    const [keyword, setKeyword] = useState('');
    const [selectedItem, setSelectedItem] = useState<{ targetNum: number; label: string } | null>(null);

    const handleExpandClick = () => {
        setIsExpanded(true);
    };

    const handleSearchClick = () => {
        if (!selectedItem) {
            alert(t('Choose occupant in list.'));
            return;
        }

        onSearch(selectedItem.targetNum);
    };

    const handleRefreshClick = () => {
        setSelectedItem(null);
        setKeyword('');
        onRefreshClick();
    };

    const disabledValue = { value: null, label: t('All') };

    return (
        <Card bodyClassName="d-flex flex-column gap-2 overflow-visible">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fw-bold text-secondary">{t('Select floor')}</span>
                <div className="d-flex gap-2">
                    <Select
                        value={isExpanded ? disabledValue : selectedFloor}
                        options={isExpanded ? [disabledValue] : floorOptions}
                        onChange={onFloorChange}
                        disabled={isExpanded}
                    />
                    {isExpanded ? (
                        <StyledButton
                            className="btn-icon-only"
                            iconName="keyboard_arrow_up"
                            onClick={() => setIsExpanded(false)}
                        />
                    ) : (
                        <StyledButton className="btn-icon-only" onClick={handleExpandClick}>
                            <PeopleSearchIcon />
                        </StyledButton>
                    )}
                </div>
            </div>
            {isExpanded && (
                <>
                    <AutoCompleteInput
                        keyword={keyword}
                        onKeywordChange={keyword => setKeyword(keyword)}
                        options={searchOptions}
                        onItemClick={selected => setSelectedItem(selected)}
                        placeholder={t('Search name, company name, identifier, department name, beacon number')}
                        emptyResult={t('No matches found.')}
                    />
                    <div className="d-flex justify-content-end gap-2">
                        <Button className="btn-secondary" onClick={handleSearchClick}>
                            {t('Search')}
                        </Button>
                        <StyledButton
                            className="btn-refresh btn-icon-only"
                            iconName="refresh"
                            onClick={handleRefreshClick}
                        />
                    </div>
                </>
            )}
        </Card>
    );
};

const PeopleSearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
            <g clipPath="url(#a)">
                <path fill="#453E6F" d="M7.5 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                    fill="#000"
                    d="M7.763 10.508C5.715 10.433 1.5 11.453 1.5 13.5V15h7.155c-1.852-2.07-.922-4.418-.892-4.492ZM14.572 13.515c.27-.443.428-.96.428-1.515a3 3 0 1 0-6 0 3 3 0 0 0 3 3c.555 0 1.072-.165 1.515-.428l1.928 1.928 1.057-1.057-1.928-1.928ZM12 13.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h18v18H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

const StyledButton = styled(Button)`
    width: 28px;
    height: 1.75rem;
    background-color: #e5e4ee !important;
`;

export default SearchCard;
