import React, { ChangeEvent, useEffect, useMemo, useReducer, useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Modal, Table } from '@components';
import { useAsync, useAsyncExcel, useModal, useTranslation } from '@hooks';

import Search from './Components/Search';
import { useFilterSearchWithType, useFilterSelect } from '../../Components/Filter';
import {
    getEntrantApi,
    getEntrantsApi,
    patchEntrantsAPi,
    patchRentalApi,
    postEntrantsApi,
    postEntrantsExportApi,
    postRentalApi,
} from '@api/tel';
import ViewPass from '../../Components/Pass/ViewPass';
import { falsyToUndefined, nullishToDash } from '../../util';
import {
    createActionHandlers,
    INITIAL_PERSONAL_PASS_STATE,
    PASS,
    personalPassReducer,
} from '../../DispatchingManagement/Management/utils';

import type { CellValue, Nullable } from '@util/type/util';
import type { RentalResponse } from '../../DispatchingManagement/Management';
import { SuccessResponse } from '@util/type/util';
import RegisterPass from '../../Components/Pass/RegisterPass';
import ChangePass from '../../Components/Pass/ChangePass';
import { Trans } from 'react-i18next';

const INITIAL_PAGE_DATA = {
    page: 1,
    pageSize: 30,
    totalCount: 0,
    rows: [],
};

const INITIAL_BEACON_NUMBER = 0;

// 출입자 목록
const ListOfVisitors = () => {
    const t = useTranslation('Entry Exit Record Status');
    const vT = useTranslation('Visitor');

    const [modalState, dispatch] = useReducer(personalPassReducer, INITIAL_PERSONAL_PASS_STATE);

    const [selectedVisitor, setSelectedVisitor] = useState<Nullable<RentalResponse>>(null);

    const [beaconNum, setBeaconNum] = useState<number>(INITIAL_BEACON_NUMBER);

    const actionHandlers = createActionHandlers(dispatch);

    const categoryProps = useFilterSelect();
    const keywordProps = useFilterSearchWithType();

    const initialParam = {
        page: INITIAL_PAGE_DATA.page,
    };

    const { state, promise: getList } = useAsync({
        promise: getEntrantsApi,
        resolve: () => {},
        immediate: true,
        param: initialParam,
        fixedParam: {
            pageSize: INITIAL_PAGE_DATA.pageSize,
        },
        reject: (err: Error) => console.error(err),
    });

    const data = state.response ? { ...state.response, pageSize: INITIAL_PAGE_DATA.pageSize } : INITIAL_PAGE_DATA;

    const { request } = state;

    const refreshList = () => {
        getList(request);
    };

    useEffect(() => {
        setBeaconNum(selectedVisitor?.rental?.beaconNum || INITIAL_BEACON_NUMBER);
    }, [selectedVisitor]);

    const { promise: createEntrants } = useAsync({
        promise: postEntrantsApi,
        resolve: (res: RentalResponse) => {
            refreshList();
            actionHandlers.closePersonalPass();
            actionHandlers.openViewPass();
            getEntrant({
                entrantNum: res.entrant?.entrantNum,
            });
        },
        reject: (err: Error) => console.error(err),
    });

    const [isRentalModalOpen, openRentalModal, toggleRentalModal] = useModal();
    const [isReturnModalOpen, openReturnModal, toggleReturnModal] = useModal();
    const [isOverlapModalOpen, openOverlapModal, toggleOverlapModal] = useModal();

    const { promise: postRental } = useAsync({
        promise: postRentalApi,
        resolve: (res: SuccessResponse) => {
            refreshList();
            getEntrant({
                entrantNum: selectedVisitor?.entrant.entrantNum,
            });
        },
        reject: (err: Error) => {
            console.error(err);
            openOverlapModal();
        },
    });

    const { promise: patchRental } = useAsync({
        promise: patchRentalApi,
        resolve: (res: SuccessResponse) => {
            refreshList();
            getEntrant({
                entrantNum: selectedVisitor?.entrant.entrantNum,
            });
        },
        reject: (err: Error) => {
            console.error(err);
        },
    });

    const handleToggleSwitch = (visitor: RentalResponse) => {
        const { rental } = visitor;
        const isActivated = !!rental?.beaconNum;

        if (isActivated) {
            openReturnModal();
        }

        if (!isActivated) {
            openRentalModal();
        }
    };

    const handleRental = () => {
        postRental({
            beaconNum,
            entrantNum: selectedVisitor?.entrant.entrantNum,
        });
    };

    const handleReturn = () => {
        if (!selectedVisitor?.rental?.rentalNum) return;

        patchRental({
            rentalNum: selectedVisitor?.rental.rentalNum,
        });
    };

    const { promise: getEntrant } = useAsync({
        promise: getEntrantApi,
        resolve: (res: RentalResponse) => {
            setSelectedVisitor(res);
            actionHandlers.openViewPass();
        },
        reject: (err: Error) => {
            console.error(err);
            setSelectedVisitor(null);
        },
    });

    const handleAccessCardNumber = (event: ChangeEvent<HTMLInputElement>) => {
        setBeaconNum(Number(event.target.value));
    };

    const { promise: updateEntrants } = useAsync({
        promise: patchEntrantsAPi,
        resolve: (res: SuccessResponse) => {
            refreshList();
            getEntrant({
                entrantNum: selectedVisitor?.entrant.entrantNum,
            });
            actionHandlers.openViewPass();
        },
        reject: (err: Error) => {
            console.error(err);

            setSelectedVisitor(null);
            actionHandlers.closeAll();
        },
    });

    const triggerDownload = useAsyncExcel({
        promise: postEntrantsExportApi,
        param: {
            categoryCode: falsyToUndefined(request?.categoryCode),
            opt: falsyToUndefined(request?.opt),
            keyword: falsyToUndefined(request?.keyword),
            columnMetas: [
                {
                    name: t('Name'),
                    key: 'entrantName',
                },
                {
                    name: t('Belong to'),
                    key: 'categoryName',
                },
                {
                    name: t('Company Name'),
                    key: 'cpName',
                },
                {
                    name: t('Identifier'),
                    key: 'identifier',
                },
                {
                    name: t('Department Name'),
                    key: 'department',
                },
                {
                    name: t('Position'),
                    key: 'designation',
                },
                {
                    name: t('Beacon Number'),
                    key: 'beaconNum',
                },
            ],
            zipFileName: 'user_list',
            fileName: 'user_list',
        },
    });

    const columns = useMemo(
        () => [
            {
                Header: t('Personal information'),
                columns: [
                    {
                        Header: t('Name'),
                        accessor: 'entrant.entrantName',
                        width: 150,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
            {
                Header: t('Affiliation information'),
                columns: [
                    {
                        Header: t('Belong to'),
                        accessor: 'category.categoryName',
                        width: 150,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Company Name'),
                        accessor: 'properties.cpName',
                        width: 150,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Identifier'),
                        accessor: 'properties.identifier',
                        width: 150,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Department Name'),
                        accessor: 'properties.department',
                        width: 150,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                    {
                        Header: t('Position'),
                        accessor: 'properties.designation',
                        width: 150,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<string>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
            {
                Header: t('Pass Information'),
                columns: [
                    {
                        Header: t('Beacon Number'),
                        accessor: 'rental.beaconNum',
                        width: 150,
                        className: 'text-ellipsis',
                        Cell: ({ value }: CellValue<number>) => {
                            return nullishToDash(value);
                        },
                        formatTitle: ({ value }: CellValue<number>) => {
                            return nullishToDash(value);
                        },
                    },
                ],
            },
        ],
        [selectedVisitor, t],
    ) as any;

    const handleSearchClick = () => {
        // 검색옵션이 '선택하세요'와 '전체'가 아닐 땐 검색어가 있어야 함
        if (!!keywordProps.keyword.type?.value && keywordProps.keyword.text.length === 0) {
            alert(t('Enter a keyword'));
            return;
        }

        getList({
            page: INITIAL_PAGE_DATA.page,
            categoryCode: categoryProps.value ? categoryProps.value.value : undefined,
            opt: keywordProps.keyword.type ? keywordProps.keyword.type.value : undefined,
            keyword: keywordProps.keyword.text.length !== 0 ? keywordProps.keyword.text : undefined,
        });
    };

    const handleResetClick = () => {
        categoryProps.reset();
        keywordProps.reset();
        getList(initialParam);
    };

    const handlePageChange = (pageIndex: number) => {
        getList({ ...state.request, page: pageIndex });
    };

    return (
        <div className="d-flex gap-2 h-100 w-100">
            <Card className="w-100" bodyClassName="overflow-hidden">
                <Search
                    categoryProps={categoryProps}
                    keywordProps={keywordProps}
                    onSearchClick={handleSearchClick}
                    onResetClick={handleResetClick}
                    handleOpenPersonalPass={actionHandlers.openPersonalPass}
                />
                <TableWrapper className="search-table">
                    <Table
                        columns={columns}
                        data={data}
                        onPageChange={handlePageChange}
                        loading={state.isLoading}
                        onTrClick={(trData: any) => {
                            getEntrant({
                                entrantNum: trData.entrant.entrantNum,
                            });
                        }}
                        actionButton={
                            <Button
                                iconName="download"
                                className="btn-secondary"
                                onClick={() => {
                                    triggerDownload();
                                }}
                            >
                                {t('Download', 'Access Card Dispatch Status')}
                            </Button>
                        }
                    />
                </TableWrapper>
            </Card>
            {modalState[PASS.REGISTER] && (
                <RegisterPass createEntrants={createEntrants} handleClosePass={actionHandlers.closePersonalPass} />
            )}
            {modalState[PASS.VIEW] && selectedVisitor && (
                <ViewPass
                    visitor={selectedVisitor}
                    handleClosePass={() => {
                        actionHandlers.closeViewPass();
                        setSelectedVisitor(null);
                    }}
                    handleOpenChangePass={actionHandlers.openChangePass}
                    handleToggleSwitch={handleToggleSwitch}
                    handleAccessCardNumber={handleAccessCardNumber}
                    accessCardNumber={beaconNum}
                />
            )}
            {modalState[PASS.CHANGE] && selectedVisitor && (
                <ChangePass
                    handleClosePass={actionHandlers.openViewPass}
                    updateEntrants={updateEntrants}
                    visitor={selectedVisitor}
                />
            )}
            <Modal
                initModal={isRentalModalOpen}
                toggleModal={toggleRentalModal}
                headerTitle={vT('Rental Access Pass')}
                bodyText={
                    <Trans t={vT} values={{ passNumber: beaconNum }}>
                        {'Would you like to rent a pass number {{passNumber}}?'}
                    </Trans>
                }
                okCallback={handleRental}
            />
            <Modal
                initModal={isReturnModalOpen}
                toggleModal={toggleReturnModal}
                headerTitle={vT('End Of Use Of Access Pass')}
                bodyText={vT('Would you like to return your pass?')}
                okCallback={handleReturn}
            />
            <Modal
                initModal={isOverlapModalOpen}
                toggleModal={toggleOverlapModal}
                headerTitle={vT('Alert', 'ConfirmModal')}
                bodyText={vT('This is a pass that has already been rented or does not exist.')}
                removeCancel
            />
        </div>
    );
};

const TableWrapper = styled.div`
    margin-top: 20px;

    .th {
        text-align: center !important;
        justify-content: center;
    }

    & .td {
        display: flex !important;
        justify-content: center !important;
    }
`;

export default ListOfVisitors;
