import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';

import { DISPATCHING_MANAGEMENT_SUBMENU } from '../../Layout/AppNav/NavItems';

import Management from './Management';
import AccessCardDispatchRecordStatus from './AccessCardDispatchRecordStatus';

import { MatchProps } from '@util/type/util';

const DispatchingManagement = ({ match }: MatchProps) => {
    return (
        <ErrorHandleSwitch>
            <Route
                exact
                path={`${match.path}/${DISPATCHING_MANAGEMENT_SUBMENU.DISPATCH_MANAGEMENT}`}
                component={Management}
            />
            <Route
                exact
                path={`${match.path}/${DISPATCHING_MANAGEMENT_SUBMENU.DISPATCH_RECORD}`}
                component={AccessCardDispatchRecordStatus}
            />
        </ErrorHandleSwitch>
    );
};

export default DispatchingManagement;
