import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Marker, FeatureGroup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import { useSelector } from 'react-redux';

import { Checkbox, GeofenceLayer, RotatedImageOverlay } from '@components';
import { useTranslation } from '@hooks';
import Map from '../../../../Components/CustomMap';

import { usePlayerStateContext } from '../../index';

import PlayController from './PlayController';
import Control from 'react-leaflet-control';
import { falsyToDash } from '../../../../util';
import LOGO_URL from '@asset/images/worker-safety/copperWire.svg';
import styled from 'styled-components';

const DEFAULT_TIME_MARKER = [null, null, null, null, null, null];

const Player = ({ targetTimeMarkerLog }) => {
    const t = useTranslation('Search');

    const mapRef = useRef(null);
    const { floorList } = useSelector(State => State.FloorInfo);

    const { playTime, displayStartTime, displayEndTime } = usePlayerStateContext();
    const isDisplayTime = !!(displayStartTime && displayEndTime);

    const [centerItem, setCenterItem] = useState(true);
    const handleCenterItem = () => {
        setCenterItem(prevState => !prevState);
    };

    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [currentFloor, setCurrentFloor] = useState({});

    const currentMarker = targetTimeMarkerLog.find(([_, __, regDate]) => regDate === moment(playTime).unix());

    const spaghettiLogInfoBeforePlayTime = targetTimeMarkerLog.filter(
        ([_, __, regDate, floorId]) => regDate <= moment(playTime).unix() && floorId === currentMarker?.[3],
    );

    const floorId = currentMarker?.[3];

    useEffect(() => {
        if (!floorId) return;

        if (floorList.length) {
            const floor = floorList.find(floor => floor.floorId === floorId);

            if (!floor) {
                setCurrentFloor({});
                setGeofenceInfo([]);
            }

            setCurrentFloor(floor);
            // getGeofenceList({ floor: floorId });
        }
    }, [floorId, floorList]);

    /* Memo: 지오펜스가 안나오게 해달라는 요청 */

    // const { promise: getGeofenceList } = useAsync({
    //     promise: fetchGeofenceList,
    //     resolve: res => {
    //         const { rows } = res;
    //         if (!!rows.length) {
    //             const geofence = rows.map(geofence => {
    //                 return { ...geofence, bounds: geofence.latLngList.map(latLng => [latLng.lat, latLng.lng]) };
    //             });
    //             setGeofenceInfo(geofence);
    //             return;
    //         }
    //         setGeofenceInfo([]);
    //     },
    //     reject: err => {
    //         console.error(err);
    //         setGeofenceInfo([]);
    //     },
    // });

    useEffect(() => {
        if (centerItem) {
            const map = mapRef.current.leafletElement;
            const currentTargetNum = currentMarker?.[4] || null;
            if (!currentTargetNum) return;
            map.eachLayer(layer => {
                if (layer instanceof L.Marker) {
                    const targetNum = layer.options?.targetNum;
                    if (targetNum === currentTargetNum) {
                        map.setView([layer._latlng.lat, layer._latlng.lng]);
                    }
                    return false;
                }
            });
        }
    }, [centerItem, currentMarker]);

    const [markerLat, markerLng, markerRegDate, currentFloorId, markerTargetNum, targetName] =
        currentMarker || DEFAULT_TIME_MARKER;

    return (
        <div className="h-100 px-3">
            <div className="bg-depth-3" style={{ height: 'calc(100% - 7rem)' }}>
                <Map tile={false} rotation={currentFloor.rotation || 0} ref={mapRef}>
                    {isDisplayTime ? (
                        <>
                            <Control position="topright">
                                <label className="bg-depth-3 flex-center px-2 rounded">
                                    <Checkbox checked={centerItem} handleChecked={handleCenterItem} />
                                    <span>{t("View Centered On Who You've Searched")}</span>
                                </label>
                            </Control>
                            {/* 도면 */}
                            {!!currentFloor?.imgURL && !!currentFloor.bounds?.length && (
                                <RotatedImageOverlay
                                    key={currentFloor.floorId}
                                    url={currentFloor.imgURL}
                                    deg={currentFloor.deg}
                                    bounds={currentFloor.bounds}
                                    onLoad={e => {
                                        const { target: layer } = e;
                                        layer._map.fitBounds(layer.getBounds());
                                    }}
                                />
                            )}

                            {/* 층 정보 */}
                            <Control key="floor" position="bottomleft">
                                <div
                                    className="bg-current_location py-1 px-2 flex-center gap-1"
                                    style={{ borderRadius: '2px' }}
                                >
                                    <span className="material-icons-round md-18 flex-center icon-filter-light-gray">
                                        info
                                    </span>
                                    <span className="color-white">{`${t(
                                        'Current Location',
                                        'Entry Exit Record Status',
                                    )} : `}</span>
                                    <span className="color-white">{falsyToDash(currentFloor.floorName)}</span>
                                </div>
                            </Control>
                            {!!geofenceInfo.length && <GeofenceLayer geofenceList={geofenceInfo} />}

                            {isDisplayTime && currentMarker && (
                                <Marker
                                    targetNum={markerTargetNum}
                                    position={[markerLat, markerLng]}
                                    icon={L.divIcon({
                                        className: 'labeled-marker',
                                        html: `<div class="marker-label">${targetName}</div>`,
                                    })}
                                />
                            )}
                            <Polyline positions={spaghettiLogInfoBeforePlayTime.map(([lat, lng]) => [lat, lng])} />
                        </>
                    ) : (
                        <WaitingScreen className={'w-100 h-100 flex-center flx-col pnt-txt s-6'}>
                            <img src={LOGO_URL} alt="logo" />
                            <span>{t("This is where you'll see the workers you searched for.")}</span>
                        </WaitingScreen>
                    )}
                </Map>
            </div>
            <PlayController />
        </div>
    );
};

export default Player;

const WaitingScreen = styled.div`
    background-color: #313138;
    > span {
        color: #ffffff99 !important;
    }
`;
