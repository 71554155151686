import React, { useEffect, useRef, useState, memo, useCallback, useMemo } from 'react';
import cx from 'classnames';
import styled, { keyframes } from 'styled-components';

interface Props<T> {
    keyword: string;
    onKeywordChange: (keyword: string) => void;
    options: T[];
    onItemClick: (item: T | null) => void;
    placeholder?: string;
    emptyResult?: string;
    optionsHeight?: number;
}

const ITEM_PER_PAGE = 10; // 페이지당 아이템 수

const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // 특수문자를 이스케이프 처리
};

// https://stackoverflow.com/a/43235785
const getHighlightedText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

    return (
        <span>
            {parts.map((part, i) => (
                <span key={i} className={cx(part.toLowerCase() === highlight.toLowerCase() && 'font-weight-bold')}>
                    {part}
                </span>
            ))}
        </span>
    );
};

const OptionItem = memo(
    ({ focused, onClick, children }: { focused?: boolean; onClick?: () => void; children: React.ReactNode }) => (
        <StyledOptionItem
            onMouseDown={e => e.preventDefault()} // Prevent default to avoid input blur
            onClick={onClick}
            disabled={typeof onClick !== 'function' && true}
            $focused={focused}
        >
            {children}
        </StyledOptionItem>
    ),
);

// 표출되는 label 속성은 필수
const AutoCompleteInput = <T extends { label: string }>({
    keyword,
    onKeywordChange,
    options = [],
    onItemClick,
    placeholder,
    emptyResult,
    optionsHeight = 300,
}: Props<T>) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [currentPage, setCurrentPage] = useState<number>(0); // 현재 페이지 상태
    const [displayedOptions, setDisplayedOptions] = useState<T[]>([]); // 표시할 옵션들
    const [focusedIndex, setFocusedIndex] = useState<number>(-1); // focus item 없으면 -1

    // 필터링된 옵션
    const filteredOptions = useMemo(() => {
        const regExp = new RegExp(escapeRegExp(keyword), 'gi');
        return options.filter(({ label }) => label.match(regExp));
    }, [options, keyword]);

    // 키워드 변경 시 페이지를 리셋
    useEffect(() => {
        setCurrentPage(0);
        setDisplayedOptions(filteredOptions.slice(0, ITEM_PER_PAGE));
    }, [keyword, filteredOptions]);

    // 페이징된 옵션
    useEffect(() => {
        const start = currentPage * ITEM_PER_PAGE;
        const end = start + ITEM_PER_PAGE;
        const newOptions = filteredOptions.slice(0, end);
        setDisplayedOptions(newOptions);
    }, [filteredOptions, currentPage, ITEM_PER_PAGE]);

    // 키보드 이벤트 핸들러
    const keyDownListener = useCallback(
        (e: KeyboardEvent) => {
            if (!inputRef.current) return;

            if (e.key === 'Escape') {
                setFocusedIndex(-1);
                inputRef.current.blur();
                return;
            }

            if (e.key === 'ArrowDown') {
                if (focusedIndex === -1 || focusedIndex === displayedOptions.length - 1) {
                    if (currentPage < Math.ceil(filteredOptions.length / ITEM_PER_PAGE) - 1) {
                        setCurrentPage(prev => prev + 1);
                    } else {
                        setFocusedIndex(0);
                    }
                } else {
                    setFocusedIndex(prev => prev + 1);
                }
                return;
            }

            if (e.key === 'ArrowUp') {
                if (focusedIndex === 0) {
                    setFocusedIndex(-1);
                } else {
                    setFocusedIndex(prev => prev - 1);
                }
                return;
            }

            if (e.key === 'Enter') {
                if (!displayedOptions[focusedIndex]) return;

                onItemClick(displayedOptions[focusedIndex]);
                onKeywordChange(displayedOptions[focusedIndex].label);
                setFocusedIndex(-1);

                inputRef.current.blur();
            }
        },
        [
            displayedOptions,
            focusedIndex,
            onItemClick,
            onKeywordChange,
            currentPage,
            filteredOptions.length,
            ITEM_PER_PAGE,
        ],
    );

    useEffect(() => {
        document.addEventListener('keydown', keyDownListener);

        return () => {
            document.removeEventListener('keydown', keyDownListener);
        };
    }, [keyDownListener]);

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onItemClick(null); // 목록에서 클릭했을 때만 아이템 선택
        onKeywordChange(e.target.value);
    };

    const handleItemClick = (item: T) => () => {
        onItemClick(item); // 목록에서 클릭했을 때만 아이템 선택
        onKeywordChange(item.label);
        inputRef.current?.blur(); // 직접 focus out 처리
    };

    // 스크롤 시 페이징 구현
    const handleScroll = useCallback(
        (e: React.UIEvent<HTMLUListElement>) => {
            const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
            if (bottom && currentPage < Math.ceil(filteredOptions.length / ITEM_PER_PAGE) - 1) {
                setCurrentPage(prev => prev + 1);
            }
        },
        [currentPage, filteredOptions.length, ITEM_PER_PAGE],
    );

    return (
        <Container>
            <Input
                type="text"
                onChange={handleKeywordChange}
                value={keyword}
                ref={inputRef}
                placeholder={placeholder ?? ''}
            />
            <OptionsList $height={optionsHeight} onScroll={handleScroll}>
                {displayedOptions.length === 0 ? (
                    <OptionItem>{emptyResult ?? 'No results.'}</OptionItem>
                ) : (
                    displayedOptions.map((option, index) => (
                        <li key={option.label + index.toString()}>
                            <OptionItem onClick={handleItemClick(option)} focused={focusedIndex === index}>
                                {getHighlightedText(option.label, keyword)}
                            </OptionItem>
                        </li>
                    ))
                )}
            </OptionsList>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    font-size: 0.8rem;
`;

const Input = styled.input`
    width: 100%;
    padding: 0.375rem 0.75rem;
    height: 1.75rem;
    background-color: #f5f5f5;
    border: 1px solid #e8e6ef;
    color: #223654;
    transition: all 0.2s;

    &:focus {
        outline: none;
        border: 1px solid #39476b;
        box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
    }
`;

const fadeIn2 = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;

const OptionsList = styled.ul<{ $height?: number }>`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
    border: 1px solid lightgray;
    border-radius: 4px;
    max-height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
    overflow: auto;
    color: inherit;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);

    ${Input}:focus + & {
        animation: ${fadeIn2} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
`;

const StyledOptionItem = styled.button<{ $focused?: boolean }>`
    width: 100%;
    background-color: white;
    text-align: left;
    padding: 0.4rem 1.5rem;
    color: rgba(0, 0, 0, 0.75);
    font-size: 0.75rem;
    background-color: ${({ $focused }) => ($focused ? '#d0d2e6' : '')};
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #d0d2e6;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: all;
    }

    &:disabled:hover {
        background-color: inherit;
    }
`;

export default AutoCompleteInput;
