import React, { useState } from 'react';
import { useSettings } from '../util/useSettings';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import { DataInteger } from '../Components/WidgetSettingOptions';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import Label from '../../Common/Label';
import SingleSelect from '../../Common/Select/SingleSelect';

const RealTimeTagMonitoringSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const dispatch = useDispatch();

    const t = useTranslation('RealTimeTagMonitoring');
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    // maxTime은 최대 50초 까지만 지정할것(데이터가 1분만 저장함)
    const makeOptions = maxTime => {
        const options = [];
        for (let time = 10; time <= maxTime; time += 5) {
            options.push({ value: time, label: `${time} ${t('seconds')}` });
        }
        return options;
    };

    const handleDataIntegerChange = checkedDataInteger => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, DataInteger: checkedDataInteger };
        });
    };

    const handleTagOptionChange = selectedTagOption => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, tagOption: selectedTagOption };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Real Time Tag Monitoring Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Tag Monitoring Duration')}
                value={
                    <SingleSelect
                        className={'w-100'}
                        options={makeOptions(30)}
                        value={updatedWidgetInfo.tagOption ? updatedWidgetInfo.tagOption : settings.tagOption}
                        onChange={handleTagOptionChange}
                    />
                }
            />

            <DataInteger
                checked={
                    typeof updatedWidgetInfo.DataInteger !== 'undefined'
                        ? updatedWidgetInfo.DataInteger
                        : customSettings && typeof customSettings.DataInteger !== 'undefined'
                        ? customSettings.DataInteger
                        : false
                }
                onChange={handleDataIntegerChange}
            />
        </WidgetSettingModal>
    );
};

export default RealTimeTagMonitoringSetting;
