import Territory from './Territory';

import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormSelectDuration from './FormSelectDuration';
import TaskComboBox from './TaskComboBox';
import FormBadge from './FormBadge';

import Title from './Components/Title';
import Topic from './Components/Topic';

export default Object.assign(Territory, {
    Input: FormInput,
    Select: FormSelect,
    Duration: FormSelectDuration,
    Badge: FormBadge,
    TaskComboBox: TaskComboBox,

    Title,
    Topic,
});
