import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import envKeys from '../environment';
import { getLsUserInfo, setLsUserInfo } from '@util/common/util';

export const DEFAULT_LANGUAGE = 'ko';
const USER_INFO_REDUCER = 'userInfo';
export const ACTION_TYPE_LOGOUT = `${USER_INFO_REDUCER}/logOut`;

const keepLanguage = () => {
    if (localStorage.getItem(envKeys.userInfoKey)) {
        const { userInfo } = getLsUserInfo();
        return userInfo && userInfo.lang ? userInfo.lang : DEFAULT_LANGUAGE;
    }
    return DEFAULT_LANGUAGE;
};
const initialState = {
    modal: {
        modalOauthStatusOpen: false,
    },
    userInfo: {},
    oAuthInfo: {},
    lang: keepLanguage(),
    smartSafeManagerInfo: {},
};

const { actions, reducer } = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        // 초기 로그인시 유저 정보 저장
        setUserInfo: (state, { payload }) => {
            const { responseData, requestData } = payload;
            let userInfo = {};
            if (responseData && responseData.access_token) {
                userInfo = { userId: requestData.userID, ...responseData };
            }
            state.userInfo = userInfo;
            setLsUserInfo(userInfo);
        },
        // refresh token 받아온 후 유저 정보 저장
        setOauthInfo: (state, action) => {
            const oAuthInfo = action.payload;
            if (oAuthInfo && oAuthInfo.access_token) {
                const userInfo = getLsUserInfo();
                state.oAuthInfo = oAuthInfo;
                setLsUserInfo({ ...userInfo, oAuthInfo });
            }
        },
        setOauthStatusModal: (state, action) => {
            state.modal.modalOauthStatusOpen = action.payload;
        },
        setLanguage: (state, action) => {
            const lang = action.payload;
            state.lang = lang;

            const userInfo = getLsUserInfo();
            if (userInfo.userInfo) {
                userInfo.userInfo.lang = lang;
            } else {
                userInfo.userInfo = { lang };
            }
            setLsUserInfo(userInfo);
        },
        setHome: (state, action) => {
            const home = action.payload;
            state.userInfo.home = home;

            const userInfo = getLsUserInfo();

            if (userInfo.userInfo) {
                userInfo.userInfo.home = home;
            } else {
                userInfo.userInfo = { home };
            }

            setLsUserInfo(userInfo);
        },
        setAdditionalInfo: (state, action) => {
            const additionalInfo = action.payload;

            if (additionalInfo.companyInfo && additionalInfo.companyInfo.timeZone) {
                moment.tz.setDefault(additionalInfo.companyInfo.timeZone);
            }
            let lsUserInfo = getLsUserInfo();
            state.userInfo = { ...state.userInfo, ...additionalInfo };
            lsUserInfo = { ...lsUserInfo, userInfo: state.userInfo };
            setLsUserInfo(lsUserInfo);
        },
        logOut: (state, action) => {
            const { userInfoKey } = envKeys;
            state.userInfo = {};
            state.oAuthInfo = {};
            state.smartSafeManagerInfo = {};
            state.modal.modalOauthStatusOpen = false;
            localStorage.removeItem(userInfoKey);
        },
        setSmartSafeManagerInfo: (state, action) => {
            const smartSafeManagerInfo = action.payload;

            let lsUserInfo = getLsUserInfo();

            state.smartSafeManagerInfo = smartSafeManagerInfo;
            setLsUserInfo({ ...lsUserInfo, smartSafeManagerInfo: state.smartSafeManagerInfo });
        },
        setMenuAuthorization: (state, action) => {
            const menuAuthorization = action.payload;

            let lsUserInfo = getLsUserInfo();

            state.smartSafeManagerInfo = { ...state.smartSafeManagerInfo, menuAuthorization: menuAuthorization };
            setLsUserInfo({ ...lsUserInfo, smartSafeManagerInfo: state.smartSafeManagerInfo });
        },
    },
});

export const {
    setUserInfo,
    setOauthInfo,
    setOauthStatusModal,
    setLanguage,
    setHome,
    setAdditionalInfo,
    logOut,
    setSmartSafeManagerInfo,
    setMenuAuthorization,
} = actions;
export default reducer;
