import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';

import { MatchProps } from '@util/type/util';

import EntryExitRecordStatus from './EntryExitRecordStatus';
import CheckMovementOfVisitor from './CheckMovementOfVisitor';
import ListOfVisitors from './ListOfVisitors';

import { ACCESS_INFO_SUBMENU } from '../../Layout/AppNav/NavItems';

const AccessInformation = ({ match }: MatchProps) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/${ACCESS_INFO_SUBMENU.RECORD}`} component={EntryExitRecordStatus} />
            <Route exact path={`${match.path}/${ACCESS_INFO_SUBMENU.LIST}`} component={ListOfVisitors} />
            <Route exact path={`${match.path}/${ACCESS_INFO_SUBMENU.MOVEMENT}`} component={CheckMovementOfVisitor} />
        </ErrorHandleSwitch>
    );
};

export default AccessInformation;
