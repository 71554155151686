import type { Skeleton } from './types';
import type { RentalResponse } from './index';

export const MIN_LAYOUT_COUNT = 81;

export const SKELETON = 'SKELETON_UI';

export const makeSkeleton = (): Skeleton => ({
    type: SKELETON,
    onBoard: false,
});

export const isSkeleton = (item: RentalResponse | Skeleton): item is Skeleton => {
    return (item as Skeleton).type !== undefined;
};

export const PASS = {
    REGISTER: 'Register Pass',
    VIEW: 'View',
    CHANGE: 'Change',
};

export const ACTIONS = {
    OPEN_PERSONAL_PASS: 'Open Personal Pass',
    CLOSE_PERSONAL_PASS: 'Close Personal Pass',
    OPEN_VIEW_PASS: 'Open View Pass',
    CLOSE_VIEW_PASS: 'Close View Pass',
    OPEN_CHANGE_PASS: 'Open Change Pass',
    CLOSE_CHANGE_PASS: 'Close Change Pass',
    CLOSE_ALL: 'Close All',
};

type ActionTypes = typeof ACTIONS[keyof typeof ACTIONS];

export type Action = { type: ActionTypes };

export const INITIAL_PERSONAL_PASS_STATE = {
    [PASS.REGISTER]: false,
    [PASS.VIEW]: false,
    [PASS.CHANGE]: false,
};

export const makeType = (type: typeof ACTIONS[keyof typeof ACTIONS]) => {
    return { type };
};

const closeAll = (state: Object) => {
    return Object.fromEntries(Object.keys(state).map(key => [key, false]));
};

export const personalPassReducer = (state: typeof INITIAL_PERSONAL_PASS_STATE, action: Action) => {
    switch (action.type) {
        case ACTIONS.OPEN_PERSONAL_PASS: {
            return {
                ...closeAll(state),
                [PASS.REGISTER]: true,
            };
        }
        case ACTIONS.CLOSE_PERSONAL_PASS: {
            return {
                ...state,
                [PASS.REGISTER]: false,
            };
        }
        case ACTIONS.OPEN_VIEW_PASS: {
            return {
                ...closeAll(state),
                [PASS.VIEW]: true,
            };
        }
        case ACTIONS.CLOSE_VIEW_PASS: {
            return {
                ...state,
                [PASS.VIEW]: false,
            };
        }
        case ACTIONS.OPEN_CHANGE_PASS: {
            return {
                ...closeAll(state),
                [PASS.CHANGE]: true,
            };
        }
        case ACTIONS.CLOSE_CHANGE_PASS: {
            return {
                ...state,
                [PASS.CHANGE]: false,
            };
        }
        case ACTIONS.CLOSE_ALL: {
            return closeAll(state);
        }
        default: {
            return state;
        }
    }
};

export const createActionHandlers = (dispatch: any) => ({
    openPersonalPass: () => dispatch(makeType(ACTIONS.OPEN_PERSONAL_PASS)),
    closePersonalPass: () => dispatch(makeType(ACTIONS.CLOSE_PERSONAL_PASS)),
    openViewPass: () => dispatch(makeType(ACTIONS.OPEN_VIEW_PASS)),
    closeViewPass: () => dispatch(makeType(ACTIONS.CLOSE_VIEW_PASS)),
    openChangePass: () => dispatch(makeType(ACTIONS.OPEN_CHANGE_PASS)),
    closeAll: () => dispatch(makeType(ACTIONS.CLOSE_ALL)),
});

export const makeAutoCompleteRow = (data: RentalResponse) => {
    return {
        ...data,
        label: `${data.entrant.entrantName} / ${data.properties.cpName ?? '-'} / ${
            data.properties.identifier ?? '-'
        } / ${data.properties.department ?? '-'} / ${data.rental.beaconNum ?? '-'}`,
    };
};
