import React, { ChangeEvent, useCallback, useState } from 'react';

import type { OptionType } from '@components/type';

interface Argument {
    key: string;
    title: string;
}

export const createInitialValue = (args: Argument[]) => {
    return args.reduce<OptionType>((initialValue, { key }) => {
        initialValue[key] = '';
        return initialValue;
    }, {});
};

export const useInputValues = (args: Argument[]) => {
    const [inputValues, setInputValues] = useState(createInitialValue(args));

    const handleInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setInputValues(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    return { inputValues, handleInput, setInputValues };
};
