import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import { ChartType, WidgetName } from '../Components/WidgetSettingOptions';
import { useSettings } from '../util/useSettings';

const options = [
    { value: 'scanner', label: '스캐너' },
    { value: 'asset', label: '자산' },
    { value: 'geofence', label: '지오펜스' },
];
const AssetChartSetting = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetInfo,
    ...restProps
}) => {
    const { config, layout } = widgetInfo;
    const settings = useSettings(config);
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();
    const onSubmit = data => {
        dispatch(
            editWidgetSettings({
                id: widgetInfo.id,
                settings: data,
                // settings: { ...data, intervalTime: Number(data.intervalTime) },
            }),
        );
        toggleModal();
    };

    return (
        <Modal isOpen={modal} toggle={toggleModal} {...restProps}>
            <ModalHeader toggle={toggleModal}>카테고리 차트 설정</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <Input type="hidden" name="id" innerRef={register()} defaultValue={layout.i} />
                    <Col>
                        <WidgetName innerRef={register({ required: true })} defaultValue={settings.name} />
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="dataType">데이터 유형</Label>
                            <Input
                                type="select"
                                name="dataType"
                                id="dataType"
                                innerRef={register({ required: true })}
                                defaultValue={settings.dataType}
                            >
                                {options.map(({ label, value }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <ChartType innerRef={register({ required: true })} defaultValue={settings.chartType} />
                    </Col>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="submit"
                    color="primary"
                    onClick={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    {t('Button;Ok')}
                </Button>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        toggleModal();
                    }}
                >
                    {t('Button;Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AssetChartSetting;
