import EVT_TYPE from '@util/staticData/eventType';

export const CHANGED_EVENT_TYPE: Partial<{ [key in keyof typeof EVT_TYPE]: string }> = {
    SOS_ON: 'Rescue Request (SOS)',
    SOS_OFF: 'Rescue Request (SOS) Release',
    LEAVEALONE_ON: 'No Movement',
    LEAVEALONE_OFF: 'No Movement Release',
};

export const changeEventType = (eventType: keyof typeof EVT_TYPE) => {
    return CHANGED_EVENT_TYPE[eventType] || eventType;
};
