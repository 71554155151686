import React, { Dispatch, FC, useCallback, useState } from 'react';

import { useTranslation } from '@hooks';
import { Button } from '@components';

import FilterSearchGroup from '../../../Components/FilterSearchGroup';
import SearchGroup from '../../../Components/FilterSearchGroup/Components/SearchGroup';
import { InputWrap, SearchWrap } from '../../../Components/FilterSearchGroup/Components/Part';
import AutoCompleteInput from '../../../RealtimeStatus/OccupantLocation/Components/AutoCompleteInput';
import { ACTIONS } from '../utils';

import type { Nullable } from '@util/type/util';
import type { Action } from '../utils';
import type { AutoCompleteRow } from '../index';

interface Props {
    list: AutoCompleteRow[];
    getEntrantsAggregated: () => void;
    handleSelectedVisitor: (visitor: Nullable<AutoCompleteRow>) => void;
    dispatch: Dispatch<Action>;
}

const Search: FC<Props> = ({ list, getEntrantsAggregated, handleSelectedVisitor, dispatch }) => {
    const t = useTranslation('Search');

    const [keyword, setKeyword] = useState('');
    const [selectedTarget, setSelectedTarget] = useState<Nullable<AutoCompleteRow>>(null);

    const handleSearchClick = () => {
        handleSelectedVisitor(selectedTarget);
        setSelectedTarget(null);
        setKeyword('');
    };

    const handleRefresh = () => {
        getEntrantsAggregated();
        setSelectedTarget(null);
        handleSelectedVisitor(null);
        setKeyword('');

        dispatch({ type: ACTIONS.CLOSE_ALL });
    };

    const onItemClick = useCallback(selected => {
        setSelectedTarget(selected);
    }, []);

    return (
        <FilterSearchGroup className="p-0">
            <SearchGroup label={t('Search Word')} className="gap-2">
                <SearchWrap>
                    <InputWrap className="d-block">
                        <AutoCompleteInput
                            keyword={keyword}
                            onKeywordChange={keyword => setKeyword(keyword)}
                            options={list}
                            onItemClick={onItemClick}
                            placeholder={t(
                                'Search name, company name, identifier, department name, beacon number',
                                'Occupant Location',
                            )}
                            emptyResult={t('No matches found.', 'Occupant Location')}
                        />
                    </InputWrap>
                </SearchWrap>
                <div className="d-flex gap-2">
                    <Button className="btn-secondary" onClick={handleSearchClick}>
                        {t('View Information')}
                    </Button>
                    <Button className="btn-lightgray btn-icon-only" iconName="refresh" onClick={handleRefresh} />
                </div>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
