import React, { FC } from 'react';
import styled from 'styled-components';

import type { TitleProps } from '../../types';

const Topic: FC<TitleProps> = ({ title, children }) => {
    return (
        <TopicContainer>
            <div className="title" title={title}>
                {title}
            </div>
            <div className="value">{children}</div>
        </TopicContainer>
    );
};

const TopicContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    .title {
        flex: 3;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .value {
        flex: 7;

        height: 100%;

        .pnt-input--group {
            width: unset;
        }
    }
`;

export default Topic;
