import React, { FC } from 'react';
import cx from 'classnames';

import { Select } from '@components';

import Topic from './Components/Topic';

import type { HandleSelect } from '../types';
import type { OptionType } from '@components/type';

interface Props {
    name: string;
    value: OptionType;
    options: OptionType[];
    title: string;
    handleSelect: HandleSelect;
    formMust?: boolean;
    [key: string]: any;
}

const FormSelect: FC<Props> = ({ name, value, title, options, handleSelect, formMust, ...restProps }) => {
    return (
        <Topic title={title}>
            <Select
                className={cx('w-100', formMust && 'form-must')}
                name={name}
                value={value}
                options={options}
                onChange={handleSelect}
                {...restProps}
            />
        </Topic>
    );
};

export default FormSelect;
