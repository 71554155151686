import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@components';

import { usePlayerDispatchContext, usePlayerStateContext } from '../../index';

import { setBackward, setForward, setPause, setPlay, setSpeed } from '../../playerSlice';

export const SPEED_LIST = [
    { value: '0.25', label: '0.25x' },
    { value: '0.5', label: '0.5x' },
    { value: '1', label: '1x' },
    { value: '2', label: '2x' },
    { value: '4', label: '4x' },
];

const PlayButtons = () => {
    const { play, speed, playTime, startTime, endTime, displayStartTime, displayEndTime } = usePlayerStateContext();
    const dispatch = usePlayerDispatchContext();

    const isDisplayTime = !!(displayStartTime && displayEndTime);

    const handleRewindButton = () => {
        dispatch(setBackward());
    };

    const handleForwardButton = () => {
        dispatch(setForward());
    };

    const handleSpeedButton = (speed: string) => () => {
        dispatch(setSpeed(Number(speed)));
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-1 align-items-center">
                <StyledPlayButton
                    className="btn-success btn-icon-only rounded-circle me-3"
                    iconName={!play ? 'play_arrow' : 'pause'}
                    onClick={() => {
                        if (play) {
                            dispatch(setPause());
                            return;
                        }
                        dispatch(setPlay({ playTime, startTime, endTime }));
                    }}
                    disabled={!isDisplayTime}
                />
                <StyledFastButton
                    className="btn-gray btn-icon-only rounded-circle"
                    iconName="fast_rewind"
                    onClick={handleRewindButton}
                    disabled={!isDisplayTime}
                />
                <StyledFastButton
                    className="btn-gray btn-icon-only rounded-circle"
                    iconName="fast_forward"
                    onClick={handleForwardButton}
                    disabled={!isDisplayTime}
                />
            </div>
            <div className="d-flex gap-1">
                {SPEED_LIST.map(({ value, label }) => {
                    return (
                        <StyledButton
                            key={value}
                            $selected={Number(value) === speed}
                            onClick={handleSpeedButton(value)}
                            disabled={!isDisplayTime}
                        >
                            {label}
                        </StyledButton>
                    );
                })}
            </div>
        </div>
    );
};

const StyledPlayButton = styled(Button)`
    width: 3rem !important;
    height: 3rem !important;
    > span {
        font-size: 36px !important;
        color: white !important;
    }
    background-color: #80d217 !important;
`;

const StyledFastButton = styled(Button)`
    width: 2.5rem !important;
    height: 2.5rem !important;
    > span {
        font-size: 24px !important;
    }
`;

const StyledButton = styled(Button)<{ $selected: boolean; disabled: boolean }>`
    font-weight: 600 !important;
    background-color: ${({ $selected }) => ($selected ? '#6A55C0' : 'transparent')} !important;
    color: ${({ $selected }) => ($selected ? 'white' : '#6A55C0')} !important;
    border: ${({ disabled }) => (disabled ? 'none' : '1px solid #6A55C0')} !important;

    ${({ $selected }) =>
        !$selected &&
        css`
            :hover {
                color: #6a55c066 !important;
                border-color: #6a55c066 !important;
            }
        `}
`;

export default PlayButtons;
