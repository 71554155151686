import React from 'react';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';

import type { ComponentPropsWithLabel } from '../types';

const SearchGroup = ({ label, children, className, labelIconName, labelClassName }: ComponentPropsWithLabel) => {
    return (
        <ResizeDetector
            handleWidth
            render={() => {
                return (
                    <div className={cx('search-box', className)}>
                        {label && (
                            <div className={cx('filter-label', labelClassName)}>
                                {labelIconName && <span className="material-icons-round md-18">{labelIconName}</span>}
                                {label}
                            </div>
                        )}
                        {children}
                    </div>
                );
            }}
        />
    );
};
export default SearchGroup;
