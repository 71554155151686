import React from 'react';
import cx from 'classnames';

import type { ComponentProps } from '../types';

const SearchButtonArea = ({ children, className }: ComponentProps) => {
    return <div className={cx('filter-function', className)}>{children}</div>;
};

export default SearchButtonArea;
